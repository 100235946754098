import { useEffect } from "react";
// import { db } from "../firebase";

export default function useFirestoreCollection(
  path,
  { orderBy, sorting, whereField, whereValue, limit, label } = {}
) {
  // const [data, setData] = useState([]);

  const data = [];

  useEffect(() => {
    // let query = db.collection(path);
    // if (whereField && whereValue) {
    //   query = query.where(whereField, "==", whereValue);
    // }
    // if (orderBy) {
    //   query = query.orderBy(orderBy, sorting);
    // }
    // if (limit) {
    //   query = query.limit(limit);
    // }
    // const unsubscribe = query.onSnapshot(snap => {
    //   const docs = snap.docs.map(doc => ({
    //     ...doc.data(),
    //     id: doc.id,
    //     label: doc.data()[label] || doc.id,
    //     value: doc.data(),
    //   }));
    //   setData(docs);
    // });
    // return unsubscribe;
  }, [orderBy, sorting, whereField, whereValue, limit, label, path]);

  return data;
}

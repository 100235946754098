import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
  CardActions,
  TextField,
  Chip,
} from "@material-ui/core";
import { CloudUpload, Warning } from "@material-ui/icons";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import FileUploader from "../../shared/FileUploader";
import {
  textFieldProps,
  countryCodes,
  selectFieldProps,
  provinces,
} from "../../shared/constants";
import { useDb } from "../../../contexts/DatabaseContext";
import BackLink from "../../shared/BackLink";
import useFirestoreCollection from "../../../hooks/useFirestoreCollection";

export default function DealerApprovalDetails() {
  const { id } = useParams();
  const {
    getSingleDealerRequest,
    checkIdNumberIsUsed,
    checkPhoneNumberIsUsed,
    updateDealerRequest,
  } = useDb();
  const levels = useFirestoreCollection("dealer_levels", {
    orderBy: "level",
  });
  const [req, setReq] = useState();
  const [dataChanged, setDataChanged] = useState(false);
  const history = useHistory();

  const [state, setState] = useState({
    loading: false,
    error: null,
    data: {},
  });

  const getRequest = async () => {
    const r = await getSingleDealerRequest(id);
    setState({
      ...state,
      data: {
        ...state.data,
        ...r.dealer,
      },
    });
    setReq(r);
  };

  useEffect(() => {
    const unsubscribe = getRequest();
    return unsubscribe;
  }, [id]);

  const handleChange = e => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
    setDataChanged(true);
  };

  const handleContactIdNumberBlur = async idNumber => {
    if (idNumber.length > 0) {
      try {
        const numberIsUsed = await checkIdNumberIsUsed(idNumber);
        if (numberIsUsed) {
          setState({
            ...state,
            error: "Error: ID/Passport number has already been used",
          });
        } else {
          setState({
            ...state,
            error: null,
          });
        }
      } catch (e) {
        // add logFrontendError here
        setState({
          ...state,
          error: e.toString(),
        });
      }
    }
  };

  const getPhoto = (f, name) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [name]: f.url,
      },
    });
    setDataChanged(true);
  };

  const checkPhone = async (number, field) => {
    if (number) {
      setState({
        ...state,
        error: null,
      });
      const formattedNumber = `+27${number.slice(1, 10)}`;
      const phoneIsUsed = await checkPhoneNumberIsUsed(formattedNumber);
      if (phoneIsUsed) {
        setState({
          ...state,
          error: `${field} number has already been used.`,
        });
      } else {
        setState({
          ...state,
          data: {
            ...state.data,
            [field]: formattedNumber,
          },
        });
      }
    }
  };

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true,
      error: null,
    });
    try {
      await updateDealerRequest(id, {
        ...state.data,
        status: 1,
        resubmit: new Date(),
      });
      history.push("/approvals");
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message,
      });
    }
  };

  return (
    <div>
      <h1>Agent Creation Request</h1>
      <BackLink />
      {req && req.dealer ? (
        <div>
          <h2>Status: {req.dealer.status === 1 && <Chip label="Pending" />}</h2>
          {req.dealer.status === 5 && (
            <Card style={{ marginBottom: 10 }}>
              <CardHeader
                title="Decline Reasons:"
                avatar={<Warning style={{ color: "red" }} />}
              />
              <CardContent>
                <Typography color="secondary">
                  {req.dealer.declineReason}
                </Typography>
              </CardContent>
            </Card>
          )}
          <Card>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  state.loading ||
                  state.error ||
                  !dataChanged ||
                  req.dealer.status === 1
                }
                startIcon={<CloudUpload />}
                onClick={handleSubmit}
              >
                Re-Submit
              </Button>
              {state.error && (
                <Typography color="secondary">{state.error}</Typography>
              )}
              {req.dealer.status === 1 && (
                <Typography>
                  Your request is still pending, resubmission not available. No
                  edits will be saved.
                </Typography>
              )}
            </CardActions>
          </Card>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  title="Person Details"
                  avatar={<Avatar src={req.dealer.personPhoto} />}
                  subheader={`Submitted: ${moment(
                    req.dealer.created.toDate()
                  ).fromNow()}`}
                />
                <CardContent>
                  <Typography>
                    Type (Level): {levels && levels[req.dealer.rank - 2].label}{" "}
                    ({req.dealer.rank}){" "}
                  </Typography>
                  <br />
                  <TextField
                    {...textFieldProps}
                    name="contactName"
                    onChange={handleChange}
                    value={state.data.contactName}
                    label="Name"
                  />
                  <TextField
                    {...textFieldProps}
                    name="contactSurname"
                    onChange={handleChange}
                    value={state.data.contactSurname}
                    label="Surname"
                  />
                  <TextField
                    {...textFieldProps}
                    name="contactIdNo"
                    onChange={handleChange}
                    value={state.data.contactIdNo}
                    label="ID/Passport Number"
                    onBlur={e => handleContactIdNumberBlur(e.target.value)}
                  />

                  <Typography>Nationality: {req.dealer.nationality}</Typography>
                  <Select
                    {...selectFieldProps}
                    options={countryCodes}
                    placeholder="Change Nationality"
                    onChange={v => {
                      setState({
                        ...state,
                        data: {
                          ...state.data,
                          nationality: v.value,
                        },
                      });
                    }}
                  />
                  <br />
                  <TextField
                    {...textFieldProps}
                    name="phone"
                    onChange={handleChange}
                    placeholder={`0${state.data.phone.slice(3)}`}
                    label={`Phone: ${`0${state.data.phone.slice(3)}`}`}
                    onBlur={e => checkPhone(e.target.value, "phone")}
                  />
                  <TextField
                    {...textFieldProps}
                    name="altPhone"
                    onChange={handleChange}
                    placeholder={`0${state.data.altPhone.slice(3)}`}
                    label={`Payments (Alt) Phone: ${`0${state.data.altPhone.slice(
                      3
                    )}`}`}
                    onBlur={e => checkPhone(e.target.value, "altPhone")}
                  />
                  <TextField
                    {...textFieldProps}
                    name="whatsAppNumber"
                    onChange={handleChange}
                    placeholder={`0${state.data.whatsAppNumber.slice(3)}`}
                    label={`Whatsapp Number: ${`0${state.data.whatsAppNumber.slice(
                      3
                    )}`}`}
                    onBlur={e => checkPhone(e.target.value, "whatsAppNumber")}
                  />
                  <TextField
                    {...textFieldProps}
                    name="operatorId"
                    onChange={handleChange}
                    value={state.data.operatorId}
                    label="RICA Online Username"
                  />
                  <br />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      label="Date of birth"
                      value={
                        state.data.dateOfBirth instanceof Date
                          ? state.data.dateOfBirth
                          : state.data.dateOfBirth.toDate()
                      }
                      inputVariant="outlined"
                      onChange={date => {
                        setState({
                          ...state,
                          data: {
                            ...state.data,
                            dateOfBirth: date,
                          },
                        });
                        setDataChanged(true);
                      }}
                      format="dd/MM/yyyy"
                      variant="inline"
                      fullWidth
                    />
                    {state.data.passportExpiryDate && (
                      <KeyboardDatePicker
                        label="Passport Expiry Date"
                        value={
                          state.data.passportExpiryDate instanceof Date
                            ? state.data.passportExpiryDate
                            : state.data.passportExpiryDate.toDate()
                        }
                        inputVariant="outlined"
                        style={{ marginTop: 10, marginBottom: 10 }}
                        onChange={date => {
                          setState({
                            ...state,
                            data: {
                              ...state.data,
                              passportExpiryDate: date,
                            },
                          });
                          setDataChanged(true);
                        }}
                        format="dd/MM/yyyy"
                        variant="inline"
                        fullWidth
                      />
                    )}
                  </MuiPickersUtilsProvider>
                  <br />
                  <TextField
                    {...textFieldProps}
                    name="street"
                    onChange={handleChange}
                    value={state.data.street}
                    label="Street"
                  />
                  <TextField
                    {...textFieldProps}
                    name="suburb"
                    onChange={handleChange}
                    value={state.data.suburb}
                    label="Suburb"
                  />
                  <TextField
                    {...textFieldProps}
                    name="city"
                    onChange={handleChange}
                    value={state.data.city}
                    label="Suburb"
                  />
                  <Typography>Province: {req.dealer.province}</Typography>
                  <Select
                    {...selectFieldProps}
                    options={provinces}
                    placeholder="Change Province"
                    onChange={v => {
                      setState({
                        ...state,
                        data: {
                          ...state.data,
                          province: v.value,
                        },
                      });
                    }}
                  />

                  <Typography>
                    Country of Residence: {req.dealer.country}{" "}
                  </Typography>
                  <Select
                    {...selectFieldProps}
                    options={countryCodes}
                    placeholder="Change Country of Residence"
                    onChange={
                      v =>
                        setState({
                          ...state,
                          data: {
                            ...state.data,
                            country: v.value,
                          },
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  />
                  <br />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader title="Re-Upload ID Photo" avatar={<Avatar />} />
                <CardContent>
                  <img src={req.dealer.idPhoto} height={200} alt="Id" />
                  <FileUploader
                    label="Re-Upload ID Copy/Photo"
                    sendFile={f => getPhoto(f, "idPhoto")}
                  />
                </CardContent>
              </Card>
              <br />
              <Card>
                <CardHeader
                  title="Re-Upload Headshot Photo"
                  avatar={<Avatar />}
                />
                <CardContent>
                  <img src={req.dealer.personPhoto} height={200} alt="person" />
                  <FileUploader
                    label="Re-Upload Headshot Photo"
                    sendFile={f => getPhoto(f, "personPhoto")}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <br />
          <Card>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  state.loading ||
                  state.error ||
                  !dataChanged ||
                  req.dealer.status === 1
                }
                startIcon={<CloudUpload />}
                onClick={handleSubmit}
              >
                Re-Submit
              </Button>
              {state.error && (
                <Typography color="secondary">{state.error}</Typography>
              )}
              {req.dealer.status === 1 && (
                <Typography>
                  Your request is still pending, resubmission not available. No
                  edits will be saved.
                </Typography>
              )}
            </CardActions>
          </Card>
        </div>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}

import React from "react";
import { Grid, Typography } from "@material-ui/core";

import sqlToStandardDate from "../../../shared/functions/sqlToStandardDate";

/**
 * Displays lead details.
 *
 * This component takes a lead object and a supplier object as a prop and
 * displays the details of the lead.
 *
 * @param {Object} props - The properties for the Details component.
 * @param {Object} props.lead - The lead object.
 * @param {Object} props.supplier - The supplier object.
 *
 * @returns {JSX.Element} The Details component.
 */
function Details({ lead, supplier }) {
  return (
    <Grid container item direction="row" xs={12} spacing={1}>
      {lead.date_submitted && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Date Submitted:</Typography>
          <Typography variant="body2">
            {sqlToStandardDate(lead.date_submitted)}
          </Typography>
        </Grid>
      )}
      {lead.dealer_code && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Dealer Code:</Typography>
          <Typography variant="body2">{lead.dealer_code}</Typography>
        </Grid>
      )}
      {lead.ref_number && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Reference Number: </Typography>
          <Typography variant="body2">{lead.ref_number}</Typography>
        </Grid>
      )}
      {lead.customer_name && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Customer: </Typography>
          <Typography variant="body2">{lead.customer_name}</Typography>
        </Grid>
      )}
      {lead.phone_number && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Phone:</Typography>
          <Typography variant="body2">{lead.phone_number}</Typography>
        </Grid>
      )}
      {lead.alternative_number && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Alternative Number:</Typography>
          <Typography variant="body2">{lead.alternative_number}</Typography>
        </Grid>
      )}
      {lead.address && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Address:</Typography>
          <Typography variant="body2">{lead.address}</Typography>
        </Grid>
      )}
      {lead.email_address && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Email:</Typography>
          <Typography variant="body2">{lead.email_address}</Typography>
        </Grid>
      )}
      {lead.id_number && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">ID Number:</Typography>
          <Typography variant="body2">{lead.id_number}</Typography>
        </Grid>
      )}
      {lead.note && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Feedback:</Typography>
          <Typography variant="body2" paragraph>
            {lead.note}
          </Typography>
        </Grid>
      )}
      {lead.category && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Category:</Typography>
          <Typography variant="body2">{lead.category}</Typography>
        </Grid>
      )}
      {lead.status && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Lead Status:</Typography>
          <Typography variant="body2">{lead.status}</Typography>
        </Grid>
      )}
      {lead.payment_type && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Lead Payment Type:</Typography>
          <Typography variant="body2">{lead.payment_type}</Typography>
        </Grid>
      )}
      {lead.supplier && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Supplier:</Typography>
          <Typography variant="body2">{supplier.supplier}</Typography>
        </Grid>
      )}
      {lead.platform && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Platform:</Typography>
          <Typography variant="body2">{lead.platform}</Typography>
        </Grid>
      )}
      {lead.agent_note && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Agent Note:</Typography>
          <Typography variant="body2">{lead.agent_note}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default Details;

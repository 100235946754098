import { supabase } from "../supabase";

async function useSupabaseFileUpload(filePath, file, bucketName) {
  try {
    const { data, error } = await supabase.storage
      .from(bucketName)
      .upload(filePath, file);

    if (error) {
      console.error("Error during upload:", error);
      return { error };
    }
    return { data };
  } catch (error) {
    console.error("Error while uploading:", error);
    return { error };
  }
}

export default useSupabaseFileUpload;

const yup = require("yup");

export default function ProductFormValidation(values) {
  return new Promise((resolve, reject) => {
    const schema = yup.object().shape({
      ogr: yup.number().required("Weighting OGR is required"),
      customer_price: yup.number().required("Customer Price is required"),
      status: yup.string().required("Status is required"),
      product_type: yup.string().required("Product Type is required"),
      product_description: yup.string().nullable(),
      product: yup.string().required("Product name is required"),
    });

    schema
      .validate(values)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err.message);
      });
  });
}

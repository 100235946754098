import axios from "axios";

const url = "https://zawadi-staging-a60377845df5.herokuapp.com/";

// const url = "http://127.0.0.1:4000/";

const apiInstance = axios.create({
  baseURL: url,
  timeout: 60000,
});

const getDownstreamWithParams = (params = {}) => {
  return apiInstance.get("/get-downstream-with-params", {
    params: {
      ...params,
    },
  });
};

const getNewDealerCode = (params = {}) => {
  return apiInstance.get("/get-new-dealer-code", {
    params: {
      ...params,
    },
  });
};

const createUser = (params = {}) => {
  return apiInstance.get("/create-new-user", {
    params: {
      ...params,
    },
  });
};

const updateDealerPassword = (params = {}) => {
  return apiInstance.get("/update-dealer-password", {
    params: {
      ...params,
    },
  });
};

const getAgentReport = (params = {}) => {
  return apiInstance.get("/get-agent-report", {
    params: {
      ...params,
    },
  });
};

const getAllAlerts = (params = {}) => {
  return apiInstance.get("/getAlerts", {
    params: {
      ...params,
    },
  });
};

const sendEmail = (params = {}) => {
  return apiInstance.get("/send-email", {
    params: {
      ...params,
    },
  });
};

const startGenericFibreCsvImport = (params = {}) => {
  return apiInstance.get("/trigger-csv-import", {
    params: {
      ...params,
    },
  });
};

// Sending an sms can be done using the following example:
// import { useAuth } from " /src/contexts/AuthContext";
// const {authSession} = useAuth();
// import * as API from /src/api

// API.sendSMS({
// phone: '0832343508'
// phone: ['0832343508', '0832343509']
// message: 'this is my message'
// token: authSession.access_token
// })

const sendSMS = (params = {}) => {
  return apiInstance.get("/sendSMS", {
    params: {
      ...params,
    },
  });
};

const updateEmailAndPhone = (params = {}) => {
  return apiInstance.get("/update-email-and-phone", {
    params: {
      ...params,
    },
  });
};

// API that handles the commissions import on the worker (server) after the file has been uploaded on supabase
const handleCommissionsImport = (params = {}) => {
  return apiInstance.get("/handleCommissionsImport", {
    params: {
      ...params,
    },
  });
};

// gets an array of contract objects from docuseal and lists it in the frontend.
const getAgentContracts = (params = {}) => {
  return apiInstance.get("/getContractsSubmissions", {
    params: {
      ...params,
    },
  });
};

// Can be used to generate new contracts that will be sent to the agent. This function used docuseal as the supplier.
const generateNewContract = (params = {}) => {
  return apiInstance.get("/generateNewContract", {
    params: {
      ...params,
    },
  });
};

/**
 * Creates a ClickUp ticket.
 *
 * @param {Object} [params={}] - The parameters to be sent with the request.
 * @param {string} [params.list] - The id of the ClickUp list on which the ticket should be generated.
 * @param {string} [params.name] - The name of the ClickUp ticket.
 * @param {number} [params.desc] - The description of the ClickUp ticket.
 * @returns {Promise} - A promise that resolves to the response of the API call.
 */
const createClickupTicket = (params = {}) => {
  return apiInstance.get("/createClickupTicket", {
    params: {
      ...params,
    },
  });
};

export {
  getDownstreamWithParams,
  createUser,
  getNewDealerCode,
  updateDealerPassword,
  getAgentReport,
  getAllAlerts,
  sendEmail,
  sendSMS,
  updateEmailAndPhone,
  startGenericFibreCsvImport,
  handleCommissionsImport,
  getAgentContracts,
  generateNewContract,
  createClickupTicket,
};

/* eslint-disable react/jsx-curly-newline */
// The code imports various components and libraries from different packages.
import { CardMedia, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import nanoId from "nano-id";
import { useHistory } from "react-router-dom";
import {
  agentLevelOptions,
  selectFieldProps,
  textFieldProps,
} from "../shared/constants";
import { useCompany } from "../../contexts/CompanyContext";
import AddAlertImages from "./AddAlertImages";
import { useAuth } from "../../contexts/AuthContext";
import SuccessAlert from "./SuccessAlert";
import { useSB } from "../../contexts/SupabaseContext";
import { useStructure } from "../../contexts/StructureContext";

// The CreateAlert function is defined as the default export of the module.
// Inside the CreateAlert function, several state variables are declared using the useState hook.
export default function CreateAlert() {
  const { GetTableWhere, insertRow, updateRow } = useSB();
  const { currentUser, userLevel } = useAuth();
  const { downstream } = useStructure();
  // const [downstream, setDownstream] = useState([]);
  const [alert, setAlert] = useState({ images: [] });
  const [alertId, setAlertId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [formattedProductTypes, setFormattedProductTypes] = useState();
  const [availableSuppliers, setAvailableSuppliers] = useState();
  const [success, setSuccess] = useState(false);
  const { productTypes, suppliers } = useCompany();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);

  const filteredAgentLevelOptions = agentLevelOptions.filter(
    option => option.value > userLevel
  );
  const history = useHistory();

  // Format product types for the select component whenever productTypes changes
  useEffect(() => {
    const formattedProductTypesArray = [];
    productTypes.forEach(type => {
      formattedProductTypesArray.push({
        label: type.name,
        value: type.id,
      });
    });

    setFormattedProductTypes(formattedProductTypesArray);
  }, [productTypes]);

  // Format suppliers for the select component whenever suppliers changes
  useEffect(() => {
    const supplierArray = [];

    suppliers.forEach(supplierLocal => {
      supplierArray.push({
        label: supplierLocal.supplier,
        value: supplierLocal.id,
      });
    });

    setAvailableSuppliers(supplierArray);
  }, [suppliers]);

  // Check if the current URL includes "edit" and load the alert for editing if true
  useEffect(() => {
    if (window.location.href.includes("edit")) {
      setLoading(true);
      const id = window.location.href.split("/")[4];
      setAlertId(id);
      GetTableWhere("global_alerts", ["new_alert_id", "eq", id])
        .then(res => {
          setAlert(res[0]);
          setEditing(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  // Set the alert as active by default
  useEffect(() => {
    setAlert({ ...alert, active: true });
  }, []);

  const activeOptions = [
    {
      label: "True",
      value: true,
    },
    {
      label: "False",
      value: false,
    },
  ];

  // Handle changes to input fields and update the alert state
  const handleChange = (label, value) => {
    setAlert({
      ...alert,
      [label]: value,
    });
  };

  // Handle multi-select changes and update the alert state
  const handleMultiSelect = (label, e) => {
    const tempArray = [];
    e.map(val => tempArray.push(val));
    handleChange(label, tempArray);
  };

  // Close the image dialog
  const handleClose = () => {
    setImageOpen(false);
  };

  const levelArray = [];

  alert.agent_level?.forEach(level => {
    levelArray.push(level.value);
  });

  // if you want to test the alerts and don't want to switch accounts just uncomment this line
  // levelArray.push(userLevel);

  const suppliersArray = [];

  alert.supplier?.forEach(supplier => {
    suppliersArray.push(supplier.value);
  });

  const productTypeArray = [];

  alert.product_type?.forEach(product_type => {
    productTypeArray.push(product_type.value);
  });
  // GetTableWhere("dealer_downstream", [
  //   "dealer_id",
  //   "eq",
  //   "138cf62e-b2b5-45d7-a752-3ba865fae46f",
  // ]).then(doc => {
  //   setDownstream(doc);
  // });
  // const result = downstream[0]?.downstream_uids;

  // console.log("result", result);
  const result = downstream;

  // Create or update a global alert
  async function createGlobalAlert(uid) {
    const alertTemp = {
      ...alert,
      supplier: alert.supplier.map(item => item.value),
      product_type: alert.product_type.map(item => item.value),
      agent_level: alert.agent_level.map(item => item.value),
    };

    const batchSize = 1000;

    // Helper function to handle batch updates
    async function updateBatch(batch) {
      return new Promise((resolve, reject) => {
        const updatePromises = batch.map(item =>
          updateRow("global_alerts", item.id, {
            active: alert.active,
            images: alert.images,
            title: alert.title,
            description: alert.description,
            read: false,
            updated_timestamp: new Date(),
            updated_by: uid,
          })
        );

        Promise.all(updatePromises)
          .then(() => {
            // console.log(
            //   "Data batch updated successfully",
            //   `${index} batch(es) processed out of ${result.length}`
            // );
            resolve();
          })
          .catch(error => {
            console.error("Error during the update operation:", error);
            reject(error);
          });
      });
    }

    // Helper function to handle batch inserts
    async function insertBatch(batch, newAlertId) {
      return new Promise((resolve, reject) => {
        const insertPromises = batch.map(doc =>
          insertRow("global_alerts", {
            ...alertTemp,
            uid: doc.id,
            read: false,
            created_by: currentUser.id,
            timestamp: new Date(),
            new_alert_id: newAlertId,
          })
        );

        Promise.all(insertPromises)
          .then(() => {
            // console.log(
            //   "Data batch inserted successfully",
            //   `${index} batch(es) processed out of ${result.length}`
            // );
            resolve();
          })
          .catch(error => {
            console.error("Error during the insert operation:", error);
            reject(error);
          });
      });
    }

    return new Promise(async resolve => {
      if (alertId) {
        try {
          const doc = await GetTableWhere("global_alerts", [
            "new_alert_id",
            "eq",
            alertId,
          ]);

          if (doc.empty) {
            resolve({ success: false, message: "No alert found to edit." });
          } else {
            const updates = [];
            doc.forEach(item => updates.push(item));

            for (let i = 0; i < updates.length; i += batchSize) {
              const batch = updates.slice(i, i + batchSize);
              // eslint-disable-next-line no-await-in-loop
              await updateBatch(batch, i);
            }

            resolve({ success: true, message: "Alert Updated" });
          }
        } catch (error) {
          console.error("Error fetching alerts:", error);
          resolve({
            success: false,
            message: "Error fetching alerts. Please contact support.",
          });
        }
      } else {
        try {
          const newAlertId = nanoId(); // Generate the new alert ID once here

          const filteredResult = result.filter(doc => {
            const selectedSupplierArray = doc.selected_suppliers;
            const selectedProductTypeArray = doc.selected_product_types;

            return (
              levelArray.includes(doc.rank) &&
              suppliersArray.some(id => selectedSupplierArray.includes(id)) &&
              productTypeArray.some(id => selectedProductTypeArray.includes(id))
            );
          });

          const DealerCount = filteredResult.length;

          if (DealerCount === 0) {
            resolve({ success: false, message: "No dealers found." });
          } else {
            for (let i = 0; i < filteredResult.length; i += batchSize) {
              const batch = filteredResult.slice(i, i + batchSize);
              // eslint-disable-next-line no-await-in-loop
              await insertBatch(batch, newAlertId, i);
            }

            resolve({ success: true, message: "Alerts added in batches" });
          }
        } catch (error) {
          console.error("Error processing downstream result:", error);
          resolve({
            success: false,
            message: "Unexpected error occurred. Please contact support.",
          });
        }
      }
    });
  }

  // Handle image deletion from the alert
  const handelImageDelete = image => {
    const filter = alert.images.filter(img => img.url !== image.url);
    setAlert({
      ...alert,
      images: filter,
    });
  };

  // Handle form submission and alert creation
  const handleSubmit = async () => {
    setSubmitting(true);
    createGlobalAlert().then(res => {
      if (res.success) {
        setSubmitting(false);
        // eslint-disable-next-line no-alert
        window.alert("Alert created succesfully");
        history.goBack();
      } else {
        setSubmitting(false);
        // eslint-disable-next-line no-alert
        window.alert(res.message);
      }
    });
  };

  // Handle form cancellation
  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <SuccessAlert
            open={success}
            setOpen={setSuccess}
            text="Alert Sucessfuly Created"
          />
          <Card style={{ maxWidth: window.screen.width }}>
            <CardContent>
              <h1>{editing ? "Editing Alert" : "Create Alert"}</h1>
              <form>
                <TextField
                  required
                  name="title"
                  label="Alert Name"
                  value={alert.title}
                  {...textFieldProps}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />
                <p>Active</p>
                <Select
                  {...selectFieldProps}
                  required
                  value={
                    alert.active
                      ? {
                          label: "True",
                          value: true,
                        }
                      : {
                          label: "False",
                          value: false,
                        }
                  }
                  options={activeOptions}
                  onChange={e => handleChange("active", e.value)}
                />
                <p>Description</p>
                <CKEditor
                  required
                  editor={ClassicEditor}
                  data={alert.description || ""}
                  onChange={(e, editor) => {
                    const data = editor.getData();
                    handleChange("description", data);
                  }}
                />
                <br />
                {
                  // eslint-disable-next-line array-callback-return
                  alert.images || alert.images.length > 0
                    ? alert.images.map((image, i) => {
                        return (
                          <Card id={i} elevation={3} style={{ margin: 10 }}>
                            <Grid container>
                              <Grid item md={6} xs={12}>
                                <CardMedia
                                  component="img"
                                  image={
                                    editing ? JSON.parse(image).url : image.url
                                  }
                                  alt={
                                    editing
                                      ? JSON.parse(image).name
                                      : image.name
                                  }
                                  style={{
                                    margin: 5,
                                    maxHeight: 300,
                                    maxWidth: 300,
                                  }}
                                />

                                <CardContent>
                                  <h3>{image.name}</h3>
                                  <Button
                                    style={{ marginLeft: 10 }}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handelImageDelete(image)}
                                  >
                                    Delete
                                  </Button>
                                </CardContent>
                              </Grid>
                            </Grid>
                          </Card>
                        );
                      })
                    : null
                }
                <br />
                <Button
                  onClick={() => setImageOpen(true)}
                  variant="contained"
                  color="primary"
                >
                  Add Image
                </Button>
                <p>Agent Levels</p>
                <Select
                  isDisabled={editing}
                  label="Agent Levels"
                  required
                  isMulti
                  options={filteredAgentLevelOptions}
                  value={
                    editing
                      ? alert.agent_level
                        ? alert.agent_level.map(item =>
                            filteredAgentLevelOptions.find(
                              option => option.value === item
                            )
                          )
                        : []
                      : alert.agent_level || []
                  }
                  onChange={e => handleMultiSelect("agent_level", e)}
                  {...selectFieldProps}
                />

                <p>Product Type</p>
                <Select
                  isDisabled={editing}
                  label="Product Type"
                  required
                  isMulti
                  value={
                    editing
                      ? alert.product_type
                        ? alert.product_type.map(item =>
                            formattedProductTypes.find(
                              option => option.value === item
                            )
                          )
                        : []
                      : alert.product_type || []
                  }
                  options={formattedProductTypes}
                  onChange={e => handleMultiSelect("product_type", e)}
                  {...selectFieldProps}
                />
                <p>Suppliers</p>
                <Select
                  isDisabled={editing}
                  label="Suppliers"
                  required
                  isMulti
                  options={availableSuppliers}
                  value={
                    editing
                      ? alert.supplier
                        ? alert.supplier.map(item =>
                            availableSuppliers.find(
                              option => option.value === item
                            )
                          )
                        : []
                      : alert.supplier || []
                  }
                  onChange={e => handleMultiSelect("supplier", e)}
                  {...selectFieldProps}
                />
              </form>
            </CardContent>
            <CardActions>
              <Button
                disabled={
                  !alert.supplier ||
                  !alert.agent_level ||
                  !alert.title ||
                  alert.active === undefined ||
                  submitting
                }
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                {submitting ? "Loading..." : "Submit"}
              </Button>
              <Button
                disabled={submitting}
                onClick={handleCancel}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
            </CardActions>
          </Card>
          <AddAlertImages
            open={imageOpen}
            close={handleClose}
            alert={alert}
            setAlert={setAlert}
          />
        </div>
      )}
    </div>
  );
}

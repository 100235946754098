// #useAuthUpdated
/* eslint-disable no-use-before-define */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-return-await */
/* eslint-disable array-callback-return */
/* eslint-disable no-throw-literal */
import React, { useContext } from "react";
import Papa from "papaparse";
import { storage } from "../firebase";

const DatabaseContext = React.createContext();

export function useDb() {
  return useContext(DatabaseContext);
}

export default function DatabaseProvider({ children }) {
  async function uploadGenericFibreCsv(records) {
    // eslint-disable-next-line no-async-promise-executor, no-unused-vars
    return new Promise(async (res, rej) => {
      const csv = Papa.unparse(records, { download: true });
      const file = new Blob([csv], { type: "text/csv" });
      const randomString = Math.random().toString(36).slice(-8);
      const fileName = `${randomString}.csv`;
      const storageRef = storage
        .ref("generic_fibre_csv_imports")
        .child(fileName);
      const uploadTask = storageRef.put(file);
      await uploadTask.on(
        "state_changed",
        snapshot => {
          // eslint-disable-next-line no-unused-vars
          const upProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.error(error, "ERROR");
          // eslint-disable-next-line prefer-promise-reject-errors
          rej({
            message: `Error uploading file to cloud storage: ${error}`,
          });
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async url => {
            res(url);
          });
        }
      );
    });
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    uploadGenericFibreCsv,
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}

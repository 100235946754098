import nanoId from "nano-id";
import { supabase } from "../../../supabase";

/**
 * Sends a personal alert to a given user.
 *
 * @param {string} user - the id of the user to send the alert to
 * @param {string} title - the title of the alert
 * @param {string} description - the description of the alert
 *
 * @returns {Promise<{data: any, error: any}>} - a promise resolving with an object containing either the data or error from the supabase insert operation.
 */
export default async function sendPersonalAlert(user, title, description) {
  const alertObject = {
    active: true,
    title,
    description,
    new_alert_id: nanoId(),
    uid: user,
    images: [],
    read: false,
    agent_level: [],
    product_type: [],
    supplier: [],
  };

  const { data, error } = await supabase
    .schema(process.env.REACT_APP_SB_SCHEMA)
    .from("global_alerts")
    .insert(alertObject)
    .select();

  if (error) {
    console.error(error);
  }

  return {
    data,
    error,
  };
}

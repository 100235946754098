import React from "react";
import { Link, useHistory } from "react-router-dom";
import { IconButton, Typography, Button } from "@material-ui/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowBack } from "@material-ui/icons";
import { pageTitleStyles } from "../../../mui/styles";
import AdminSuppliersList from "./AdminSupplierslist";

export default function AvailableSuppliers() {
  const history = useHistory();

  return (
    <div>
      <div className="back-link">
        <Button
          color="primary"
          startIcon={<ArrowBack />}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>{" "}
      <h1 style={pageTitleStyles}>
        <Typography>
          <Typography
            align="left"
            variant="h4"
            style={{ fontWeight: "bold", marginBottom: -50 }}
          >
            Suppliers
          </Typography>
          <Typography align="right">
            <Link to="/addSupplier">
              <IconButton color="primary">
                <FontAwesomeIcon icon={faPlus} />
              </IconButton>
            </Link>
          </Typography>{" "}
        </Typography>
      </h1>
      <AdminSuppliersList />
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import * as Sentry from "@sentry/react";
import App from "./components/App";

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://84c220c5005bacd47fa708fe77836970@o4507779889692672.ingest.de.sentry.io/4507779902799952",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [/^(?!localhost)/, /^https:\/\/app\.zawadi\.africa/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

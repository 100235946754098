import {
  Avatar,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  styled,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Public } from "@material-ui/icons";
import { linearProgressClasses } from "@mui/material";
import React from "react";

const BigAvatar = withStyles({
  root: {
    width: 50,
    height: 50,
    backgroundColor: "#FFFFFF",
  },
})(Avatar);

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D3D3D3",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#3D7155",
  },
}));

export default function MobileUserCard({
  rank,
  name,
  percentage,
  fiber,
  userImg,
  outlined,
  outlineColors,
}) {
  return (
    <Card
      style={{
        outlineStyle: outlined,
        outlineColor: outlineColors,
        outlineWidth: "4px",
      }}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item style={{ width: 60, marginLeft: -10 }}>
            {userImg ? (
              <BigAvatar src={userImg} />
            ) : rank < 100 ? (
              <Typography variant="h3" color="textPrimary" align="center">
                {rank}
              </Typography>
            ) : (
              <Typography
                variant="h4"
                color="textPrimary"
                align="center"
                style={{ marginTop: 10 }}
              >
                {rank}
              </Typography>
            )}
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Typography variant="body1" style={{ fontWeight: "550" }}>
              {name}
            </Typography>
            <BorderLinearProgress variant="determinate" value={percentage} />
            <Grid item style={{ marginBottom: "-16px" }}>
              <Grid container direction="row">
                <Typography align="right" variant="subtitle1">
                  <Public fontSize="inherit" color="primary" />
                </Typography>

                <Typography variant="subtitle2" align="left">
                  :&nbsp;{fiber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom"; // Import useLocation hook from React Router
import { supabase } from "../../supabase";
import Loading from "../Loading";

function Banner() {
  const location = useLocation(); // Get the location object
  const supplierId = location.pathname.includes("Supplier")
    ? location.pathname.split("/").pop()
    : null; // Extract the supplierId from the URL
  let folder = supplierId || "dash"; // Default to "dash" if 'supplierId' is not present
  const publicURL = `https://zmdjhwyplkwfgwmmtwak.supabase.co/storage/v1/object/public/banner_images/${folder}/`;
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    const fetchLatestImage = async () => {
      try {
        const { data, error } = await supabase.storage
          .from("banner_images")
          .list(folder, { limit: 100 });

        if (error) {
          throw error;
        }

        if (data.length > 0) {
          // Sort images by updated_at (descending order)
          const sortedData = data.sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          );
          const newestImage = sortedData[0].name;
          // Encode image name to make it URL-safe
          const encodedImageName = encodeURIComponent(newestImage);
          setImgUrl(publicURL + encodedImageName);
        } else {
          folder = "dash"; // Fallback to default folder
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchLatestImage();
  }, [folder]);

  if (!imgUrl) {
    return null; // Replace this with a custom loading component if needed
  }

  return (
    <>
      <br />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: { xs: "100%" },
          height: { xs: "auto" },
          borderRadius: { xs: "0px", sm: "16px" },
          overflow: "hidden",
          boxShadow: { sm: "0 4px 12px rgba(0, 0, 0, 0.1)" },
          marginX: { xs: 0, sm: 2 },
        }}
      >
        {imgUrl === null || imgUrl === undefined ? (
          <Loading loading />
        ) : (
          <img
            src={imgUrl}
            alt="Banner"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        )}
      </Box>
    </>
  );
}

export default Banner;

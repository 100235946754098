import React, { useState, useEffect } from "react";
import { Button, IconButton } from "@material-ui/core";
import { ArrowBack, People, Search } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import SimDataGrid from "../../shared/data-grid/SimDataGrid";
import { useSB } from "../../../contexts/SupabaseContext";

export default function AllDealers() {
  const history = useHistory();
  const { GetTableWhere } = useSB();
  const [dealers, setDealers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await GetTableWhere("dealers");
        setDealers(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const rows = dealers?.length
    ? dealers.map(d => ({
        ...d,
        id: d.id,
        name: `${d.contact_name} ${d.contact_surname}`,
        phone: `0${d.phone.slice(3)}`,
      }))
    : [];

  const columns = [
    { field: "dealer_code", headerName: "Code", width: 120 },
    { field: "contact_id_no", headerName: "ID/Passport", width: 120 },
    { field: "name", headerName: "Name", width: 220 },
    { field: "phone", headerName: "Phone", width: 120 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "action",
      headerName: "Detail / Downstream",
      width: 200,
      renderCell: params => (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <Link to={`/dealer-detail/${params.row.id}`}>
            <IconButton>
              <Search />
            </IconButton>
          </Link>
          <Link to={`child-dealer-downstream/${params.row.id}`}>
            <IconButton>
              <People />
            </IconButton>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="back-link">
        <Button
          color="primary"
          startIcon={<ArrowBack />}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>
      <SimDataGrid data={rows} columns={columns} />
    </div>
  );
}

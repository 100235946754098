// #useAuthUpdated
import {
  Button,
  Grid,
  Hidden,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { pageTitleStyles } from "../../../../mui/styles";
import MobileLeaderBoard from "./MobileLeaderBoard";
import OverallDesk from "./OverallDesk";
import StructureDesk from "./StructureDesk";
import { useAuth } from "../../../../contexts/AuthContext";
import { useCompany } from "../../../../contexts/CompanyContext";
import { useSB } from "../../../../contexts/SupabaseContext";
import { supabase } from "../../../../supabase";
import Loading from "../../../Loading";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#F0BD44",
  },
  card1: {
    backgroundColor: "#267353",
    color: "#FFFFFF",
  },

  TextColor: {
    color: "#FFFFFF",
  },

  button1: {
    color: "#267353",
  },
  button2: {
    backgroundColor: "#FFFFFF",
  },
  buttonSize: {
    width: 120,
  },
  buttonSize2: {
    width: 120,
  },
  selected: {
    backgroundColor: "#267353",
    color: "white",
    width: 120,
  },
  notSelected: {
    color: "white",
    width: 120,
  },
});

export default function LeaderBoard() {
  // State management for the currently selected button (e.g., "Overall")
  const [selectedButton, setSelectedButton] = useState("Overall");

  // Custom hook to retrieve table data from Supabase
  const { GetTableWhere } = useSB();

  // Destructure suppliers and productTypes from the custom useCompany hook
  const { suppliers, productTypes } = useCompany();

  // State for managing the list of leaders and the structured data for downstream processing
  const [Leaders, setLeaders] = useState([]);
  const [StructureData, setStructureData] = useState([]);

  // Loading state for indicating data fetch in progress
  const [loading, setLoading] = useState(true);

  // Get the current user object from the authentication context
  const { currentUser } = useAuth();

  // Custom styles applied from Material-UI (or another styling solution)
  const classes = useStyles();

  // Get the current year and month in "YYYY-MM" format using the moment library
  const currentYM = moment().format("YYYY-MM");

  // State for storing selected supplier and option for filtering
  const [selectedSuppliers, setSelectedSuppliers] = useState("all");
  const [selectedOption, setSelectedOption] = useState("all");

  // State for holding the downstream data (users under the current dealer)
  const [downstream, setDownstream] = useState([{}]);

  // State for managing the selected leaderboard date (default is the current year-month)
  const [leaderboardDate, setLeaderboardDate] = useState(currentYM);

  // Construct the start and end dates for the leaderboard (start_d is the first of the selected month)
  const start_d = `${leaderboardDate}-01`;
  // end_d is the last day of the selected month
  const end_d = moment(start_d).endOf("month").format("YYYY-MM-DD");

  // Effect hook to fetch downstream data (e.g., users associated with the current dealer)
  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch downstream data based on the current user's dealer ID
        const data = await GetTableWhere("dealer_downstream", [
          "dealer_id",
          "eq",
          currentUser.id,
        ]);
        // Set the downstream UIDs retrieved from the database
        setDownstream(data[0].downstream_uids);
      } catch (error) {
        // Log any errors that occur during data fetch
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [selectedOption, leaderboardDate, selectedSuppliers]); // Re-fetch downstream data when option, date, or suppliers change

  // Effect hook to fetch leaderboard data from Supabase
  useEffect(() => {
    async function fetchLeaders() {
      setLoading(true); // Set loading state to true while fetching data
      // Create a query to select relevant data from the leaderboard table
      const query = supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("leaderboard_v2")
        .select(
          "*, dealers(contact_name, contact_surname, user_type), suppliers(supplier)"
        )
        .gte("start_d", start_d) // Filter by start date
        .lte("start_d", end_d) // Filter by end date
        .order("sales", { ascending: false }); // Order by sales in descending order

      // If a specific supplier is selected, filter the query by that supplier
      if (selectedSuppliers !== "all") {
        query.eq("supplier", selectedSuppliers);
      }

      // Execute the query and handle the result
      const { data, error } = await query;
      if (error) {
        console.error("Error fetching data:", error); // Log error if fetching fails
        setLoading(false);
        return;
      }

      const users = data.filter(item => {
        if (item.dealer_id !== null) {
          return item.dealers.user_type !== 2;
        }
        return false;
      });
      // Process the data: filter, group, and sort leaderboard entries
      const sortedLeaders = users
        .filter(item => item.dealer_id) // Only include entries with a dealer ID
        .reduce((result, item) => {
          const existing = result.find(r => r.dealer_id === item.dealer_id);
          // If dealer ID exists, accumulate sales, otherwise, push a new entry
          if (existing) existing.sales += item.sales || 0;
          else result.push(item);
          return result;
        }, [])
        .sort((a, b) => b.sales - a.sales);
      const leaders = sortedLeaders.map((item, index) => ({
        ...item,
        rank: index, // Assign rank based on position in sorted array
        p: ((item.sales / sortedLeaders[0].sales) * 100).toFixed(2), // Calculate percentage based on top sales
      }));
      setLeaders(leaders); // Update the leaderboard state
      setLoading(false); // Stop loading indicator
    }

    fetchLeaders(); // Fetch leaderboard data when component mounts or dependencies change
  }, [leaderboardDate, selectedSuppliers]); // Re-fetch data when date or supplier selection changes

  // Effect hook to structure leaderboard data based on downstream IDs
  useEffect(() => {
    const filteredLeaders = Leaders.filter(
      leader => downstream.toString().includes(leader.dealer_id) // Only include leaders that are in the downstream UIDs
    );
    setStructureData(filteredLeaders); // Set structured data for display
    setLoading(false); // Stop loading indicator
  }, [downstream, Leaders, selectedSuppliers]); // Run whenever downstream data, leaderboard, or suppliers change

  // Function to handle date navigation buttons for changing leaderboard months
  const handleDateButtons = direction => {
    setLoading(true); // Show loading state while updating date
    const newDate = moment(leaderboardDate)
      .add(direction === ">" ? 1 : -1, "month") // Add or subtract a month based on button clicked
      .format("YYYY-MM"); // Format new date as "YYYY-MM"
    setLeaderboardDate(newDate); // Update leaderboard date state
    setLoading(false); // Hide loading state
  };

  // Handlers for changing supplier and option selections
  const handleChangeSuppliers = e => setSelectedSuppliers(e.target.value); // Set selected supplier
  const handleChange = e => setSelectedOption(e.target.value); // Set selected option
  const handleClick = id => setSelectedButton(id); // Set selected button

  return (
    <div>
      {!loading ? (
        selectedButton === "Overall" ? (
          <>
            <Typography
              variant="h4"
              style={{ ...pageTitleStyles, fontWeight: "550" }}
            >
              <Grid container justifyContent="center" alignItems="center">
                <Box
                  sx={{
                    textAlign: { xs: "center", sm: "left" },
                    paddingBottom: { xs: 2, sm: 0 },
                    flex: 1,
                  }}
                >
                  Leaderboard
                </Box>
                <Grid item>
                  <Grid container direction="row">
                    {" "}
                    <Hidden xsDown>
                      <Grid style={{ paddingRight: 10 }}>
                        <FormControl
                          variant="outlined"
                          style={{ width: 160, marginTop: 5, marginRight: 10 }}
                          size="small"
                        >
                          <InputLabel
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            Select a Supplier
                          </InputLabel>
                          <Select
                            label="Select a Supplier"
                            value={selectedSuppliers}
                            onChange={handleChangeSuppliers}
                            style={{
                              height: 38,
                              marginTop: -2,
                              fontSize: "12px",
                            }}
                          >
                            {" "}
                            <MenuItem value="all">All Suppliers</MenuItem>
                            {suppliers.map(supplier => {
                              return supplier.supplier_status === "Active" ? (
                                <MenuItem value={supplier.id} key={supplier.id}>
                                  {supplier.supplier}
                                </MenuItem>
                              ) : null;
                            })}
                          </Select>
                        </FormControl>
                        <FormControl
                          disabled
                          variant="outlined"
                          size="small"
                          style={{ width: 160, marginTop: 5 }}
                        >
                          <InputLabel style={{ fontSize: "12px" }}>
                            Select a Product Type
                          </InputLabel>
                          <Select
                            label="Select a Product Type"
                            value={selectedOption}
                            onChange={handleChange}
                            style={{
                              height: 38,
                              marginTop: -2,
                              fontSize: "12px",
                            }}
                          >
                            <MenuItem value="">All</MenuItem>
                            {productTypes.map(productType => {
                              return (
                                <MenuItem
                                  value={productType.id}
                                  key={productType.id}
                                >
                                  {productType.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonSize2}
                          onClick={() => handleDateButtons("<")}
                          style={{
                            marginRight: 10,
                            borderRadius: "10px", // Set the card rounding
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          PREV
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonSize2}
                          onClick={() => handleDateButtons(">")}
                          style={{
                            marginRight: 10,
                            borderRadius: "10px", // Set the card rounding
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          }}
                          disabled={leaderboardDate === currentYM}
                        >
                          NEXT
                        </Button>
                      </Grid>
                    </Hidden>{" "}
                    <Hidden smUp>
                      <Grid
                        container
                        // Add spacing between Grid items
                        justifyContent="center" // Center horizontally
                        alignself="center" // Center vertically
                      >
                        <Grid item xs={12} sm={6}>
                          {" "}
                          {/* Adjust width on larger screens */}
                          <FormControl
                            variant="outlined"
                            style={{ marginTop: 5, width: "100%" }}
                          >
                            <InputLabel
                              style={{
                                fontSize: "12px",
                                width: "100%",
                              }}
                            >
                              Select a Supplier
                            </InputLabel>
                            <Select
                              label="Select a Supplier"
                              value={selectedSuppliers}
                              onChange={handleChangeSuppliers}
                              style={{
                                height: 38,
                                marginTop: -2,
                                fontSize: "12px",
                              }}
                            >
                              <MenuItem value="all">All Suppliers</MenuItem>
                              {suppliers.map(supplier => {
                                return supplier.supplier_status === "Active" ? (
                                  <MenuItem
                                    value={supplier.id}
                                    key={supplier.id}
                                  >
                                    {supplier.supplier}
                                  </MenuItem>
                                ) : null;
                              })}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {" "}
                          <FormControl
                            disabled
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: "1rem" }}
                          >
                            <InputLabel style={{ fontSize: "12px" }}>
                              Select a Product Type
                            </InputLabel>
                            <Select
                              label="Select a Product Type"
                              value={selectedOption}
                              onChange={handleChange}
                              style={{
                                height: 38,
                                marginTop: -2,
                                fontSize: "12px",
                              }}
                            >
                              <MenuItem value="">All</MenuItem>
                              {productTypes.map(productType => {
                                return (
                                  <MenuItem
                                    value={productType.id}
                                    key={productType.id}
                                  >
                                    {productType.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                          sx={{
                            "@media (max-width: 600px)": {
                              flexDirection: "column", // Switch to column on mobile
                            },
                          }}
                        >
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleDateButtons("<")}
                              fullWidth
                              style={{ marginTop: "1rem" }}
                            >
                              PREV
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleDateButtons(">")}
                              fullWidth
                              style={{ marginTop: "1rem" }}
                              disabled={leaderboardDate === currentYM}
                            >
                              NEXT
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Hidden>
                    {currentUser.rank !== 8 ? (
                      <Hidden only={["xs"]}>
                        <Grid item style={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonSize2}
                            onClick={() => handleClick("Overall")}
                            style={{
                              marginRight: 10,
                              borderRadius: "10px", // Set the card rounding
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            Overall
                          </Button>

                          {StructureData !== undefined ? (
                            <Button
                              variant="contained"
                              color="default"
                              className={classes.buttonSize2}
                              onClick={() => handleClick("Structure")}
                              style={{
                                marginRight: 10,
                                borderRadius: "10px", // Set the card rounding
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              Structure
                            </Button>
                          ) : (
                            <Button
                              disabled
                              variant="contained"
                              color="default"
                              className={classes.buttonSize2}
                              onClick={() => handleClick("Structure")}
                              style={{
                                marginRight: 10,
                                borderRadius: "10px", // Set the card rounding
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              Structure
                            </Button>
                          )}
                        </Grid>
                      </Hidden>
                    ) : (
                      <Hidden only={["xs"]}>
                        <Grid md={7} lg={9} />
                        <Grid item xs={2} lg={1} />
                        <Grid item xs={1} />
                      </Hidden>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Typography>
            {Leaders.length === 0 ? (
              <Alert
                severity="warning"
                style={{
                  borderRadius: "8px",
                  paddingTop: { xs: "-2", sm: "0" },
                }}
              >
                Please check back later to see your performance or click
                "previous" to see last month's leaderboard.
              </Alert>
            ) : (
              <div>
                {Leaders.length > 0 ? (
                  <>
                    <Hidden only={["xs"]}>
                      <OverallDesk Leaders={Leaders} />
                    </Hidden>
                    <Hidden only={["xl", "lg", "md", "sm"]}>
                      <MobileLeaderBoard
                        Leaders={Leaders}
                        StructureData={StructureData}
                      />
                    </Hidden>
                  </>
                ) : (
                  <h1>
                    The Leaderboard has not been updated for this month yet.
                  </h1>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <Typography
              variant="h4"
              style={{
                ...pageTitleStyles,
                fontWeight: "bold",
                paddingBottom: 10,
              }}
            >
              <Grid container justifyContent="center" alignItems="center">
                <Box
                  sx={{
                    textAlign: { xs: "center", sm: "left" },
                    paddingBottom: { xs: 2, sm: 0 },
                    flex: 1,
                  }}
                >
                  Leaderboard
                </Box>
                <Grid item>
                  <Grid container direction="row">
                    <Grid style={{ paddingRight: 10 }}>
                      <FormControl
                        variant="outlined"
                        style={{
                          width: 191,
                          marginTop: 5,
                          marginRight: 10,
                        }}
                        size="small"
                      >
                        <InputLabel
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Select a Supplier
                        </InputLabel>
                        <Select
                          label="Select a Supplier"
                          value={selectedSuppliers}
                          onChange={handleChangeSuppliers}
                          style={{
                            height: 38,
                            marginTop: -2,
                            fontSize: "12px",
                          }}
                        >
                          {" "}
                          <MenuItem value="all">All Suppliers</MenuItem>
                          {suppliers.map(supplier => {
                            return supplier.supplier_status === "Active" ? (
                              <MenuItem value={supplier.id} key={supplier.id}>
                                {supplier.supplier}
                              </MenuItem>
                            ) : null;
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        disabled
                        variant="outlined"
                        size="small"
                        style={{ width: 192, marginTop: 5 }}
                      >
                        <InputLabel style={{ fontSize: "12px" }}>
                          Select a Product Type
                        </InputLabel>
                        <Select
                          label="Select a Product Type"
                          value={selectedOption}
                          onChange={handleChange}
                          style={{
                            height: 38,
                            marginTop: -2,
                            fontSize: "12px",
                          }}
                        >
                          <MenuItem value="">All</MenuItem>
                          {productTypes.map(productType => {
                            return (
                              <MenuItem
                                value={productType.id}
                                key={productType.id}
                              >
                                {productType.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Hidden smUp>
                      {" "}
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{
                          "@media (max-width: 600px)": {
                            flexDirection: "column", // Switch to column on mobile
                          },
                        }}
                      >
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDateButtons("<")}
                            fullWidth
                            style={{ marginTop: "1rem" }}
                          >
                            PREV
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDateButtons(">")}
                            fullWidth
                            style={{ marginTop: "1rem" }}
                            disabled={leaderboardDate === currentYM}
                          >
                            NEXT
                          </Button>
                        </Grid>
                      </Grid>
                    </Hidden>
                    <Hidden only={["xs"]}>
                      <Grid>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonSize2}
                          onClick={() => handleDateButtons("<")}
                          style={{ marginRight: 10 }}
                        >
                          PREV
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonSize2}
                          onClick={() => handleDateButtons(">")}
                          style={{
                            marginRight: 10,
                          }}
                          disabled={leaderboardDate === currentYM}
                        >
                          NEXT
                        </Button>
                      </Grid>
                    </Hidden>

                    <Hidden only={["xs"]}>
                      <Grid itme style={{ textAlign: "right" }}>
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.buttonSize2}
                          onClick={() => handleClick("Overall")}
                          style={{
                            marginRight: 10,
                            borderRadius: "10px", // Set the card rounding
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          Overall
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            backgroundColor: "#F0BD10",
                            color: "white",
                            marginRight: 10,
                            borderRadius: "10px", // Set the card rounding
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          }}
                          className={classes.buttonSize2}
                          onClick={() => handleClick("Structure")}
                        >
                          Structure
                        </Button>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </Typography>

            {Leaders.length === 0 ? (
              <Alert
                severity="warning"
                style={{
                  borderRadius: "8px",
                  paddingTop: { xs: "-2", sm: "0" },
                }}
              >
                Please check back later to see your performance or click
                "previous" to see last month's leaderboard.
              </Alert>
            ) : (
              <div>
                <Hidden only={["xs"]}>
                  <StructureDesk Leaders={StructureData} />
                </Hidden>
                <Hidden only={["xl", "lg", "md", "sm"]}>
                  <MobileLeaderBoard
                    Leaders={Leaders}
                    StructureData={StructureData}
                  />
                </Hidden>
              </div>
            )}
          </>
        )
      ) : (
        <div>
          <Loading loading={loading} />
        </div>
      )}
    </div>
  );
}

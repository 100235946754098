import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ArrowBack } from "@material-ui/icons";
import { Button, IconButton, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { pageTitleStyles } from "../../../mui/styles";

import { useCompany } from "../../../contexts/CompanyContext";

import ProductsList from "./ProductsList";

export default function AdminSupplier({ match }) {
  const history = useHistory();
  const { getSupplier } = useCompany();

  const [loading, setLoading] = useState(true);
  const [supplier, setSupplier] = useState(true);

  useEffect(() => {
    getSupplier(match.params.id).then(result => {
      setSupplier(result);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {" "}
      <Button
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => history.push("/settings/Suppliers")}
        style={{
          marginBottom: 10,
        }}
      >
        Back
      </Button>
      <h1 style={pageTitleStyles}>
        <Typography>
          <Typography
            align="left"
            variant="h4"
            style={{ fontWeight: "bold", marginBottom: -50 }}
          >
            {supplier ? supplier.supplier : "Loading..."}
          </Typography>
          <Typography align="right">
            <Link to={`/Edit/${match.params.id}`}>
              <IconButton color="primary">
                <FontAwesomeIcon icon={faPencilAlt} />
              </IconButton>
            </Link>
          </Typography>
        </Typography>
      </h1>
      {!loading && <ProductsList supplier={match.params.id} />}
    </>
  );
}

/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/order */
import React, { useEffect, useState } from "react";
import { supabase } from "../../supabase";
import BackLink from "../shared/BackLink";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import sqlToStandardDate from "../shared/functions/sqlToStandardDate";

// Define custom styles for the component
const useStyles = makeStyles(theme => ({
  grid: {
    border: 0,
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  textField: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: theme.spacing(1, 0.5, 1.5),
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(0.5),
    },
    "& .MuiInput-underline:before": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  overlay: {
    flexDirection: "column",
    "& .ant-empty-img-1": {
      fill: theme.palette.type === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.type === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.type === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.type === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.type === "light" ? "0.8" : "0.08",
      fill: theme.palette.type === "light" ? "#f5f5f5" : "#fff",
    },
  },
  label: {
    marginTop: theme.spacing(1),
  },
}));

// Define a toolbar component for quick search functionality
function QuickSearchToolbar(props) {
  const classes = useStyles();
  const toolbarButtonStyle = {
    fontSize: ".875rem",
    padding: ".5rem .65rem",
    textTransform: "none",
  };

  return (
    <div className={classes.root}>
      <TextField
        variant="standard"
        value={props.value}
        style={{
          marginLeft: "1rem",
        }}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={toolbarButtonStyle} />
        <GridToolbarFilterButton style={toolbarButtonStyle} />
        <GridToolbarDensitySelector style={toolbarButtonStyle} />
        <GridToolbarExport style={toolbarButtonStyle} />
      </GridToolbarContainer>
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

// Main component to display training marks
function TrainingMarks() {
  // Extract module id and name from the URL
  const id = window.location.pathname.split("/")[2];
  const moduleName = window.location.pathname.split("/")[3];

  // State variables to manage data and loading state
  const [loadingPage, setLoadingPage] = useState(true);
  const [attemptList, setAttemptList] = useState([]);
  const [attemptListStatic, setAttemptListStatic] = useState([]);
  const [searchText, setSearchText] = useState("");

  // Fetch results from the database
  const fetchResults = async () => {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("training_attempts")
      .select("*, dealer_id (*)")
      .eq("module_id", id);

    if (error) {
      console.error(error.message);
    } else {
      setAttemptList(data);
      setAttemptListStatic(data);
    }

    setLoadingPage(false);
  };

  useEffect(() => {
    setLoadingPage(true);

    fetchResults();
  }, []);

  // Define the columns for the data grid
  const attemptsColumns = () => [
    {
      field: "dealer_code",
      headerName: "Dealer Code",
      width: 200,
      renderCell: params => <p>{params.row.dealer_id?.dealer_code}</p>,
    },
    {
      field: "dealer_name",
      headerName: "Dealer Name",
      width: 200,
      renderCell: params => (
        <p>{`${params.row.dealer_id?.contact_name} ${params.row.dealer_id?.contact_surname}`}</p>
      ),
    },
    {
      field: "pass",
      headerName: "Result",
      width: 200,
      renderCell: params => <p>{params.row.pass === true ? "Pass" : "Fail"}</p>,
    },
    {
      field: "percentage",
      headerName: "Percentage",
      width: 200,
      renderCell: params => <p>{params.row.percentage.toFixed(2)}</p>,
    },
    {
      field: "created_at",
      headerName: "Timestamp",
      minWidth: 200,
      flex: 1,
      renderCell: params => (
        <p>{sqlToStandardDate(params.row.created_at, true)}</p>
      ),
    },
  ];

  // Function to escape special characters in a search string
  function escapeRegExp(string) {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  // Function to handle search requests
  const requestSearch = searchValue => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = attemptListStatic.filter(row => {
      return Object.keys(row).some(field => {
        return searchRegex.test(row[field] && row[field].toString());
      });
    });
    setAttemptList(filteredRows);
  };

  return (
    <div>
      <BackLink />
      <h1>Training Marks For: {decodeURI(moduleName)}</h1>
      {loadingPage ? (
        <h1>Loading...</h1>
      ) : (
        <div style={{ marginTop: 20 }}>
          <div style={{ height: 600, width: "100%" }}>
            <DataGrid
              getRowId={row => row.id}
              pageSize={10}
              loading={loadingPage}
              rows={attemptList}
              columns={attemptsColumns()}
              components={{
                Toolbar: QuickSearchToolbar,
              }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: event => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TrainingMarks;

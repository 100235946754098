import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import DownstreamTable from "../../tables/DownstreamTable";
import { useStructure } from "../../../contexts/StructureContext";

export default function DealersChildDownstream() {
  const history = useHistory();
  const { dealerId } = useParams();
  const { fetchOtherDealerDownstream } = useStructure();
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const agentsRequest = await fetchOtherDealerDownstream(dealerId);
    setAgents(agentsRequest);
    setLoading(false);
  }, []);

  return (
    <>
      <Button
        color="primary"
        style={{ marginBottom: "1rem" }}
        startIcon={<ArrowBack />}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <DownstreamTable dealers={agents} loading={loading} />
    </>
  );
}

import React from "react";
import { Switch, Route } from "react-router-dom";
import Authenticate from "../authenticate/Authenticate";
import PhoneLogin from "../authenticate/PhoneLogin";

export default function PublicRoutes({ logo }) {
  return (
    <Switch>
      <Route exact path="/">
        <Authenticate logo={logo} />
      </Route>
      <Route path="/login" component={Authenticate}>
        <Authenticate logo={logo} />
      </Route>
      <Route path="/phone-login" component={PhoneLogin} />
      <Route path="*" component={Authenticate} />
    </Switch>
  );
}

import React, { useEffect, useState } from "react";
import DealerDownstreamTable from "./DealerDownstreamTable";
import { useStructure } from "../../../contexts/StructureContext";

export default function DealersDownstream({ rank }) {
  // const { GetDealerDownstreamOfLevel } = useDb();
  // const dealers = GetDealerDownstreamOfLevel(dealerId, rank);

  const { downstream } = useStructure();
  const [dealerList, setDealerList] = useState([]);

  useEffect(() => {
    const dealers = [];
    setDealerList([]);

    downstream
      .filter(agent => agent.rank === rank.level)
      .forEach(agent => {
        dealers.push(agent);
      });

    setDealerList(dealers);
  }, [rank]);

  return (
    <div>
      <DealerDownstreamTable dealers={dealerList} />
    </div>
  );
}

import { supabase } from "../../../../supabase";

/**
 * Updates the contracts table with the provided agent and data.
 *
 * @async
 * @function updateContractsTable
 * @param {string} agent - The unique identifier for the agent.
 * @param {Array<string>} data - An array of strings representing the contract templates.
 * @throws {Error} Throws an error if the insertion into the database fails.
 */
const updateContractsTable = async (agent, data) => {
  const obj = {
    agent_uid: agent,
    agency_agreement: true,
    a_sadv_resellers: data.includes("306180"),
    a_sadv_super_dealer: data.includes("305527"),
    a_sadv_standard_leader_comm: data.includes("306181"),
    a_sadv_multiple_teams: data.includes("306183"),
    b_herotel_resellers: data.includes("302783"),
    b_herotel_super_dealer: data.includes("306177"),
    b_herotel_standard_leader_comm: data.includes("306185"),
    b_herotel_multiple_teams: data.includes("306174"),
  };

  const { error } = await supabase
    .schema("finance")
    .from("new_dealer_contracts")
    .insert(obj);

  if (error) {
    throw new Error(error.message);
  }
};

export default updateContractsTable;

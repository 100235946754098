/* eslint-disable no-throw-literal */
import React, { useContext, useState, useEffect, createContext } from "react";

import { supabase } from "../supabase";

// create context

const AuthContext = createContext({
  currentUser: {},
  login: () => {},
  logout: () => {},
  userLevel: "",
  forgotPassword: () => {},
  loginWithPhone: () => {},
  verifyOtp: () => {},
  resetPasswordForEmail: () => {},
});

// export

export function useAuth() {
  return useContext(AuthContext);
}

// auth provider

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userLevel, setUserLevel] = useState();
  const [loading, setLoading] = useState(true);
  const [authSession, setAuthSession] = useState(null);

  // Check session

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session }, error }) => {
      if (error) console.error(error);

      setAuthSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setAuthSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  async function login(email, password) {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      console.error(error);
      // eslint-disable-next-line no-alert
      alert(error.message);
      return error;
    }
  }

  async function logout() {
    return await supabase.auth.signOut().then(() => {
      setCurrentUser(null);
    });
  }

  async function forgotPassword(email) {
    return await supabase.auth.resetPasswordForEmail(email);
  }

  async function createUser(email, phone, password) {
    return await supabase.auth.signUp({
      email,
      password,
      phone,
    });
  }

  async function resetPasswordForEmail(password) {
    return await supabase.auth.updateUser({
      email: currentUser.email,
      password,
    });
  }

  async function verifyOtp(phone, token) {
    return await supabase.auth.verifyOtp({
      phone,
      token,
      type: "sms",
    });
  }

  async function loginWithPhone(phone) {
    const { error } = await supabase.auth.signInWithOtp({
      phone,
      type: "sms",
      options: { shouldCreateUser: false },
    });

    if (error) {
      return false;
    }
    return true;
  }

  async function getUserDoc(uid) {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealers")
      .select("*")
      .eq("auth_id", uid);

    if (error) {
      console.error("Error fetching user document:", error);
      throw new Error("Failed to retrieve user document.");
    }

    if (!data || data.length === 0) {
      console.error("No user document found for the given UID");
      return null;
    }

    return data[0];
  }

  // email should be switched for auth.users foreign key once transfer has been done

  const updateCurrentUser = async id => {
    const userDoc = await getUserDoc(id);

    if (!userDoc) {
      setCurrentUser(null);
    } else {
      setUserLevel(userDoc?.rank);
      setCurrentUser({ ...userDoc });

      // Listen for real-time changes to the user document
      supabase
        .channel("dealers")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: process.env.REACT_APP_SB_SCHEMA,
            table: "dealers",
            filter: `id=eq.${userDoc.id}`,
          },
          payload => {
            const updatedUser = payload.new;
            setUserLevel(updatedUser?.rank);
            setCurrentUser({ ...updatedUser });
          }
        )
        .subscribe();
    }
  };

  useEffect(() => {
    if (authSession) {
      updateCurrentUser(authSession.user.id);
    } else {
      setCurrentUser(null);
    }

    setLoading(false);
  }, [authSession]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    currentUser,
    login,
    logout,
    userLevel,
    forgotPassword,
    loginWithPhone,
    verifyOtp,
    resetPasswordForEmail,
    createUser,
    authSession,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

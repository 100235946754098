import React, { useState } from "react";
import ForgotPassword from "./ForgotPassword";
import SignIn from "./Login";

export default function Authenticate({ logo }) {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const toggleForgotPasswordView = () => {
    setShowForgotPassword(!showForgotPassword);
  };

  return showForgotPassword ? (
    <ForgotPassword toggleForgotPasswordView={toggleForgotPasswordView} />
  ) : (
    <SignIn toggleForgotPasswordView={toggleForgotPasswordView} logo={logo} />
  );
}

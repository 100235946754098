/* eslint-disable no-unreachable */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/order */
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import "firebase/database";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Select from "react-select";
import YouTube from "react-youtube";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import BackLink from "../shared/BackLink";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import SBFileDownloader from "../shared/SBFileDownloader";
import { useSB } from "../../contexts/SupabaseContext";

function TrainingAttempt() {
  const history = useHistory();
  const { GetTableWhere, insertRow } = useSB();
  const { currentUser } = useAuth();
  // States
  const [loadingPage, setLoadingPage] = useState(true);
  const [trainingModuleContent, setTrainingModuleContent] = useState([]);
  const [language, setLanguage] = useState("");
  const [languageOptions, setLanguageOptions] = useState([]);
  const [passed, setPassed] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [openResults, setOpenResults] = useState(false);
  const [additionalContent, setAdditionalContent] = useState([]);

  const id = window.location.pathname.split("/")[2];

  const downloadBucket = "zawadi_university";
  // Function to fetch external content from a specified download bucket and ID, then update the state with the content
  const fetchExternalContent = () =>
    SBFileDownloader(downloadBucket, id).then(arr => {
      setAdditionalContent(arr);
    });

  useEffect(() => {
    // Destructure current user properties
    const { rank, selected_product_types, selected_suppliers } = currentUser;

    // Fetch additional content when the component mounts
    fetchExternalContent();

    // Fetch the training module details from the database
    GetTableWhere("training_modules", ["id", "eq", id])
      .then(async doc => {
        const trainingModuleContentLocal = doc[0];

        // Extract relevant fields from the training module
        const productTypes =
          trainingModuleContentLocal.product_types || undefined;
        const suppliers = trainingModuleContentLocal.suppliers || undefined;
        const selectedLevels =
          trainingModuleContentLocal.selected_levels || undefined;

        // Parse user-selected product types and suppliers from JSON
        const parsedProductTypes = selected_product_types;
        const parsedSuppliers = selected_suppliers;

        // Check if the user is eligible to access this module based on their rank, product types, and suppliers
        if (
          selectedLevels === undefined ||
          selectedLevels.length === 0 ||
          (selectedLevels.includes(rank) &&
            (productTypes === undefined ||
              productTypes.length === 0 ||
              productTypes.some(type => parsedProductTypes?.includes(type))) &&
            (suppliers === undefined ||
              suppliers.length === 0 ||
              suppliers.some(supplier =>
                parsedSuppliers?.includes(supplier)
              ))) ||
          rank === 1
        ) {
          // Get the languages available for the module's videos
          const uniqueLanguages = new Set();

          // If no videos are available, alert the user and redirect to the training page
          if (
            !trainingModuleContentLocal.videos ||
            trainingModuleContentLocal.videos.length === 0
          ) {
            window.alert("No content available for this module");
            window.location.href = "/training";
            return;
          }

          // Add video languages to the uniqueLanguages set
          if (trainingModuleContentLocal.videos) {
            trainingModuleContentLocal.videos.forEach(entry => {
              if (entry.video_language) {
                uniqueLanguages.add(entry.video_language);
              }
            });
          }

          // Convert the set of languages to an array and map to options format
          const uniqueLanguagesArray = Array.from(uniqueLanguages);

          const languageOptionsArray = uniqueLanguagesArray.map(
            languageLocal => {
              return { value: languageLocal, label: languageLocal };
            }
          );

          // Set the language options and default language in the state
          setLanguageOptions(languageOptionsArray);
          setLanguage(languageOptionsArray[0].value);

          const questionsArray = [];

          // Shuffle the questions randomly
          const shuffledQuestions = [...trainingModuleContentLocal.questions];

          for (let i = shuffledQuestions.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledQuestions[i], shuffledQuestions[j]] = [
              shuffledQuestions[j],
              shuffledQuestions[i],
            ];
          }

          // Filter questions based on language
          const filteredQuestions = await getQuestionsByLanguage(
            trainingModuleContentLocal.marks,
            shuffledQuestions
          );

          // Modify the question bank to include the shuffled and filtered questions
          filteredQuestions.forEach(question => {
            questionsArray.push({
              question: question.question,
              question_language: question.question_language,
              options: generateOptions(
                question.options,
                parseInt(question.max_options)
              ),
            });
          });

          // Update the training module content with the modified questions
          setTrainingModuleContent({
            ...trainingModuleContentLocal,
            questions: questionsArray,
          });

          setLoadingPage(false);
        } else {
          setLoadingPage(false);
          alert("Not Registered For Content");
          history.goBack();
        }
      })
      .catch(errorLocal => {
        console.error("Error getting documents: ", errorLocal);
      });
  }, []);

  // Function to group questions by language and select a specified number of questions for each language
  const getQuestionsByLanguage = (marks, questions) => {
    return new Promise(resolve => {
      const questionsByLanguage = {};

      // Group questions by language
      questions.forEach(q => {
        if (!questionsByLanguage[q.question_language]) {
          questionsByLanguage[q.question_language] = [];
        }
        questionsByLanguage[q.question_language].push(q);
      });

      // Select questions based on marks for each language
      const selectedQuestions = [];

      Object.keys(questionsByLanguage).forEach(lang => {
        const langQuestions = questionsByLanguage[lang];
        const selectedLangQuestions = langQuestions.slice(0, marks);
        selectedQuestions.push(...selectedLangQuestions);
      });

      resolve(selectedQuestions);
    });
  };

  // Function to generate options for a question, including the correct option and a specified number of additional options
  const generateOptions = (options, maxOptions) => {
    if (options) {
      const correctOption = options.find(option => option.is_correct);

      // Remove the correct option from the original list
      const remainingOptions = options.filter(
        option => option !== correctOption
      );

      // Reorder the remaining options randomly
      const shuffledList = [...remainingOptions];

      for (let i = shuffledList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledList[i], shuffledList[j]] = [shuffledList[j], shuffledList[i]];
      }

      // Calculate how many additional options are needed
      const additionalOptionsCount = Math.max(0, maxOptions - 1);

      // Take additional options from the remaining options (if available)
      const additionalOptions = shuffledList.slice(0, additionalOptionsCount);

      // Combine the correct option and additional options to form the final list
      const finalOptions = [correctOption, ...additionalOptions];

      // Shuffle the final list of options
      const shuffledList2 = [...finalOptions];

      for (let i = shuffledList2.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledList2[i], shuffledList2[j]] = [
          shuffledList2[j],
          shuffledList2[i],
        ];
      }

      return shuffledList2;
    }
    return [];
  };

  // Function to extract the YouTube video ID from a URL
  const getYouTubeId = url => {
    if (!url) return "";

    let videoId = url.split("v=")[1];
    const ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
    return videoId;
  };

  // Function to reset the selected options for all questions in the training module
  const resetQuestions = () => {
    setTrainingModuleContent(prevState => {
      const newTrainingModuleContent = {
        ...prevState,
      };
      newTrainingModuleContent.questions.forEach(question => {
        question.options.forEach(option => {
          option.checked = false;
        });
      });
      return newTrainingModuleContent;
    });
  };

  // Function to submit the user's answers, calculate the percentage score, and save the results to the database
  const submitAnswers = () => {
    setError("");
    setOpen(false);
    setPercentage(0);
    // setLoadingPage(true);

    const questionsInLanguage = trainingModuleContent.questions.filter(
      question => question.question_language === language
    );

    const maxMarks = questionsInLanguage.length;

    let marks = 0;
    let answered = 0;

    // Check if all questions have been answered
    questionsInLanguage.forEach(question => {
      question.options.forEach(option => {
        if (option.checked === true) {
          answered += 1;
        }
      });
    });

    if (answered !== maxMarks) {
      setError("You have not answered all the questions.");
      return;
    }

    // Calculate the marks based on correct answers
    questionsInLanguage.forEach(question => {
      question.options.forEach(option => {
        if (option.checked === true && option.is_correct === true) {
          marks += 1;
        }
      });
    });

    // Calculate the percentage score
    const percentageLocal = (marks / maxMarks) * 100;
    setPercentage(percentageLocal);
    setPassed(percentageLocal >= trainingModuleContent.pass_threshold);

    // Save results to Supabase
    insertRow("training_attempts", {
      percentage: percentageLocal,
      module_id: id,
      dealer_code: currentUser.dealer_code,
      dealer_id: currentUser.id,
      dealer_name: `${currentUser.contact_name} ${currentUser.contact_surname}`,
      marks,
      max_marks: maxMarks,
      module_content_snapshot: trainingModuleContent,
      pass: percentageLocal >= trainingModuleContent.pass_threshold,
    })
      .then(() => {
        setOpenResults(true);
      })
      .catch(errorLocal => {
        console.error("Error saving training attempt: ", errorLocal);
      });
  };
  return (
    <div>
      {loadingPage ? (
        <h1>Loading....</h1>
      ) : (
        <div style={{ marginTop: 20 }}>
          <BackLink />
          <h1>{decodeURI(trainingModuleContent.module_name)}</h1>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Typography style={{ marginBottom: 10 }}>
                Select Language:
              </Typography>
              <Select
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: base => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                options={languageOptions}
                defaultValue={{ value: language, label: language }}
                onChange={e => {
                  setLanguage(e.value);
                  resetQuestions();
                }}
                placeholder="Select Language"
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              {/* Videos */}
              <h2 style={{ marginBottom: 10 }}>Videos:</h2>
            </Grid>
            {trainingModuleContent.videos?.length > 0 &&
              trainingModuleContent.videos
                .filter(entry => entry.video_language === language)
                .map(video => {
                  return (
                    <Grid item xs={12} sm={7}>
                      {video.link ? (
                        <YouTube
                          videoId={getYouTubeId(video.link)}
                          opts={{
                            height: window.innerWidth < 650 ? "280" : "390",
                            width: window.innerWidth < 650 ? "100%" : "640",
                            backgroundColor: "#d2d2d2",
                            playerVars: {
                              autoplay: 0,
                            },
                          }}
                        />
                      ) : (
                        <Typography>
                          No video added. Read additional content below.
                        </Typography>
                      )}
                    </Grid>
                  );
                })}

            {/* Additional Content */}

            <Grid item xs={12} sm={7}>
              <h2 style={{ marginBottom: 10 }}>Additional Content:</h2>
            </Grid>
            {additionalContent.length === 0 && (
              <Grid item xs={12} sm={7}>
                <Typography>
                  There is no external content to display.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={7}>
              {additionalContent.length > 0 &&
                additionalContent.map(file => {
                  return (
                    <Grid item xs={12} sm={7}>
                      <ListItem key={file.id}>
                        <ListItemText
                          primary={file.fileName}
                          secondary={<a href={file.downloadUrl}>Download</a>}
                        />
                      </ListItem>
                    </Grid>
                  );
                })}
            </Grid>

            <Grid item xs={12} sm={7}>
              <h2 style={{ marginBottom: 10 }}>Questions:</h2>
            </Grid>
            {trainingModuleContent.questions?.length > 0 &&
              trainingModuleContent.questions
                .filter(entry => entry.question_language === language)
                .map(question => {
                  return (
                    <Grid item xs={12} sm={7}>
                      <FormControl component="fieldset">
                        <FormLabel
                          style={{
                            marginBottom: 10,
                          }}
                          component="legend"
                        >
                          {question.question}
                        </FormLabel>
                        <RadioGroup
                          value={
                            question.options.filter(
                              option => option.checked === true
                            ).text
                          }
                          onChange={e => {
                            const indexOnOptions =
                              trainingModuleContent.questions.indexOf(question);

                            setTrainingModuleContent(prevState => {
                              const newTrainingModuleContent = {
                                ...prevState,
                              };
                              newTrainingModuleContent.questions[
                                indexOnOptions
                              ].options.forEach(option1 => {
                                if (option1.text === e.target.value) {
                                  option1.checked = true;
                                } else {
                                  option1.checked = false;
                                }
                              });
                              return newTrainingModuleContent;
                            });
                          }}
                        >
                          {question.options.map(answerOption => (
                            <FormControlLabel
                              value={answerOption.text}
                              control={<Radio />}
                              label={answerOption.text}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  );
                })}
            <Grid item xs={12} sm={7}>
              <Button
                style={{ marginBottom: 50 }}
                onClick={() => {
                  setOpen(true);
                }}
                variant="contained"
                color="primary"
              >
                Submit Answers
              </Button>
            </Grid>
            <Dialog
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <DialogTitle>Confirmation</DialogTitle>
              <DialogContent>
                Are you sure you want to submit your answers?
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    submitAnswers();
                  }}
                  color="primary"
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openResults}
              onClose={() => {
                history.goBack();
              }}
            >
              <DialogTitle>Your Results</DialogTitle>
              <DialogContent>
                You scored {parseFloat(percentage).toFixed(2)}% for this module.
              </DialogContent>
              <DialogContent>
                The pass rate for this module is{" "}
                {trainingModuleContent.pass_threshold}%.
              </DialogContent>
              <DialogContent>
                {passed
                  ? "Congratulations, you have passed this module."
                  : "Unfortunately you have not passed this module and will need to try again."}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    history.goBack();
                  }}
                  color="primary"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            {error && (
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                }}
              >
                <Alert
                  style={{ width: "100%", marginTop: "0.5rem" }}
                  severity="error"
                >
                  {error}
                </Alert>
              </div>
            )}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default TrainingAttempt;

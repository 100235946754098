import React, { useState } from "react";

import {
  Card,
  CardActionArea,
  CardActions,
  Button,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";

import { ConfirmationModal } from "../../../../shared/ConfirmationModal";

import { ModalCard } from "../../../../shared/ModalCard";
import { AddSupplierLinkModal } from "./AddSupplierLinkModal";

export function SupplierLinkTile({
  supLink,
  userAdmin,
  restVar,
  removeSupplierLink,
  addSupplierLink,
}) {
  // Default state
  const defModals = {
    deleteModal: false,
    addLinkModal: false,
  };

  // State

  const [modals, setModals] = useState(defModals);

  // Set state handlers

  const toggleDeleteModal = () =>
    setModals(prev => ({ ...prev, deleteModal: !prev.deleteModal }));

  const toggleAddLinkModal = () =>
    setModals(prev => ({ ...prev, addLinkModal: !prev.addLinkModal }));

  // Link building logic
  const linkAddress = [supLink.link_address];

  if (supLink.rest_var[0]) linkAddress.push("?");

  for (let i = 0; i < supLink.rest_var.length; i++) {
    linkAddress.push(
      `${supLink.rest_var[i].q}=${
        supLink.rest_var[i].is_static
          ? supLink.rest_var[i].var
          : restVar[supLink.rest_var[i].var]
      }${i === supLink.rest_var.length - 1 ? "" : "&"}`
    );
  }

  // submission handler

  // On Click event handler
  const handleClick = () => {
    window.open(linkAddress.join(""), "_blank");
  };

  // Handler to update link in Supabase
  const addSupplierLinkHandler = newLink => {
    addSupplierLink(newLink);
    toggleAddLinkModal();
  };

  // Handler to change link to inactive
  const handleDelete = () => {
    removeSupplierLink(supLink.id);
    toggleDeleteModal();
  };

  return (
    <Grid item xs={12} md={4}>
      <Card key={supLink.id}>
        <CardActionArea onClick={handleClick}>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: 100,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                item
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    {supLink.link_name}
                  </Typography>
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "15rem",
                    }}
                  >
                    <Typography variant="subtitle" color="primary" noWrap>
                      {supLink.link_address}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        {userAdmin && (
          <CardActions>
            <Button color="primary" size="small" onClick={toggleAddLinkModal}>
              Edit
            </Button>
            <Button color="secondary" size="small" onClick={toggleDeleteModal}>
              Delete
            </Button>
          </CardActions>
        )}
      </Card>
      <ConfirmationModal
        open={modals.deleteModal}
        onClose={toggleDeleteModal}
        onConfirm={handleDelete}
      />
      <ModalCard open={modals.addLinkModal} onClose={toggleAddLinkModal}>
        <AddSupplierLinkModal
          restVar={restVar}
          addNewLink={addSupplierLinkHandler}
          onClose={toggleAddLinkModal}
          supLink={supLink}
          edit
        />
      </ModalCard>
    </Grid>
  );
}

export default SupplierLinkTile;

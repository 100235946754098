const { object, string, number, addMethod } = require("yup");
/* eslint-disable func-names */

addMethod(string, "requiredIf", function (bool, message) {
  if (!bool) {
    return this.nullable();
  }

  return this.required(message || "Required");
});

export default function SupplierFormValidation(values) {
  const { logSales, Reports, CustomPage } = values;

  return new Promise((resolve, reject) => {
    const schema = object().shape({
      supplier: string().required("Supplier name is required"),
      supplier_description: string(),
      internal_identifier: string().required("Internal Identifier is required"),
      logo: string(),
      logo_size: number(),
      supplier_status: string().required("Supplier Status is required"),
      admin_contact_person: string().required(
        "Admin Contact Person is required"
      ),
      admin_contact_number: number().required(
        "Admin Contact Number is required"
      ),
      admin_contact_email: string()
        .email()
        .required("Admin Contact Email is required"),
      finance_contact_person: string().required(
        "Finance Contact Person is required"
      ),
      finance_contact_number: number().required(
        "Finance Contact Number is required"
      ),
      finance_contact_email: string()
        .email()
        .required("Finance Contact Email is required"),
      data_contact_person: string().required("Data Contact Person is required"),
      data_contact_number: number().required("Data Contact Number is required"),
      data_contact_email: string()
        .email()
        .required("Data Contact Email is required"),
      it_contact_person: string().required("IT Contact Person is required"),
      it_contact_number: string().required("IT Contact Number is required"),
      it_contact_email: string()
        .email()
        .required("IT Contact Email is required"),
      regex: string().requiredIf(
        logSales,
        "Please insert order number Regex value"
      ),
      log_sales_name: string().requiredIf(
        logSales,
        "Please enter a Log Sales name value"
      ),
      reports_name: string().requiredIf(Reports, "Reports Name is required"),
      reports_display_name: string().requiredIf(
        Reports,
        "Reports Display Name is required"
      ),
      custom_page_url: string().requiredIf(
        CustomPage,
        "Custom Page URL is required"
      ),
    });

    schema
      .validate(values)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err.message);
      });
  });
}

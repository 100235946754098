// This function returns the LeaderImporter component which is used to import leader Commissions data.
import { useState, React } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { handleLeaderImport } from "./importFunctions";

function LeaderImporter() {
  const [importArray, setImportArray] = useState([]);
  const tempArray = [];

  return (
    <div>
      <h3>Leader Import</h3>
      <div>
        <Importer
          restartable
          processChunk={async rows => {
            rows.forEach(row => {
              tempArray.push({
                order_number: row.order_number,
                leader_activation_comm_paid_date: row.leader_payment_date,
                leader_activation_comm_tier_1: row.leader_act_comm_paid_tier_1,
                leader_activation_comm_paid_to_1: row.leader_act_comm_paid_to_1,
                leader_activation_comm_amount_paid_1:
                  row.leader_act_comm_paid_amount_1,
                leader_activation_comm_tier_2: row.leader_act_comm_paid_tier_2,
                leader_activation_comm_paid_to_2: row.leader_act_comm_paid_to_2,
                leader_activation_comm_amount_paid_2:
                  row.leader_act_comm_paid_amount_2,
                leader_activation_comm_tier_3: row.leader_act_comm_paid_tier_3,
                leader_activation_comm_paid_to_3: row.leader_act_comm_paid_to_3,
                leader_activation_comm_amount_paid_3:
                  row.leader_act_comm_paid_amount_3,
                leader_activation_comm_tier_4: row.leader_act_comm_paid_tier_4,
                leader_activation_comm_paid_to_4: row.leader_act_comm_paid_to_4,
                leader_activation_comm_amount_paid_4:
                  row.leader_act_comm_paid_amount_4,
              });
            });
            setImportArray(tempArray);
          }}
          onComplete={async () => {
            handleLeaderImport(importArray);
          }}
        >
          {/* Required fields */}
          <ImporterField name="order_number" label="Order Number" />
          <ImporterField
            name="leader_act_comm_paid_date"
            label="Leader Payment Date"
          />
          <ImporterField
            name="leader_act_comm_paid_tier_1"
            label="Leader Activation Commission Paid Tier 1"
          />
          <ImporterField
            name="leader_act_comm_paid_to_1"
            label="Leader Activation Commission Paid To 1"
          />
          <ImporterField
            name="leader_act_comm_paid_amount_1"
            label="Leader Activation Commission Paid Amount 1"
          />
          <ImporterField
            name="leader_act_comm_paid_tier_2"
            label="Leader Activation Commission Paid Tier 2"
          />
          <ImporterField
            name="leader_act_comm_paid_to_2"
            label="Leader Activation Commission Paid To 2"
          />
          <ImporterField
            name="leader_act_comm_paid_amount_2"
            label="Leader Activation Commission Paid Amount 2"
          />
          <ImporterField
            name="leader_act_comm_paid_tier_3"
            label="Leader Activation Commission Paid Tier 3"
          />
          <ImporterField
            name="leader_act_comm_paid_to_3"
            label="Leader Activation Commission Paid To 3"
          />
          <ImporterField
            name="leader_act_comm_paid_amount_3"
            label="Leader Activation Commission Paid Amount 3"
          />
          <ImporterField
            name="leader_act_comm_paid_tier_4"
            label="Leader Activation Commission Paid Tier 4"
          />
          <ImporterField
            name="leader_act_comm_paid_to_4"
            label="Leader Activation Commission Paid To 4"
          />
          <ImporterField
            name="leader_act_comm_paid_amount_4"
            label="Leader Activation Commission Paid Amount 4"
          />
        </Importer>
      </div>
    </div>
  );
}

export { LeaderImporter };

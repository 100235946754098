import React from "react";

import {
  Grid,
  Backdrop,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

/**
 * A modal dialog component that displays a given child component. The modal
 * is enabled by default, and can be disabled by setting the `open` prop to
 * `false`. The modal will close when the `onClose` prop is called, and the
 * `closeAfterTransition` prop ensures that the modal's backdrop animation
 * completes before the modal is closed. The `BackdropComponent` prop is
 * used to display a backdrop component, and the `BackdropProps` prop is used to
 * pass additional props to the backdrop component. The `title` prop can be used
 * to set the title of the modal, and the `closeButtonText` prop can be used to
 * set the label of the close button. The child component is rendered inside a
 * `DialogContent` component, and the close button is rendered inside a
 * `DialogActions` component.
 * @param {boolean} open - Set to `false` to disable the modal. Defaults to `true`.
 * @param {function} onClose - Called when the modal is closed.
 * @param {ReactNode} children - The component to render inside the modal.
 * @param {string} closeButtonText - The label of the close button. Defaults to `undefined`.
 * @param {string} title - The title of the modal. Defaults to `undefined`.
 */
export function ModalCard({
  open = true,
  onClose = () => {},
  children,
  closeButtonText = undefined,
  title = undefined,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
      <DialogContent>
        <Grid item>{children}</Grid>
      </DialogContent>
      <DialogActions>
        {closeButtonText && (
          <Button
            required
            color="secondary"
            variant="contained"
            onClick={onClose}
          >
            {closeButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ModalCard;

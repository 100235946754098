import React, { useState } from "react";
import Select from "react-select";
import { Paper, Tab, Tabs, Grid, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

function DealerLevelTabs({ levels, setListLevel }) {
  const [selectedTab, setSelectedTab] = useState(0);

  const StyledTab = withStyles(() => ({
    root: {
      minWidth: "0",
      padding: "0 1.25rem",
      textTransform: "uppercase",
      fontWeight: 500,
    },
  }))(props => <Tab disableRipple {...props} />);

  return (
    <Grid container style={{ marginBottom: "12px", width: "100%" }}>
      <Hidden mdDown>
        <Grid item xs="auto" display={{ xs: "none", sm: "none", md: "block" }}>
          <Paper elevation={1} style={{ marginBottom: "16px", padding: " 0" }}>
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => {
                setSelectedTab(newValue);
              }}
              indicatorColor="primary"
            >
              {levels &&
                levels.map(item => (
                  <StyledTab
                    wrapped
                    label={item.level_name}
                    aria-label="User level tabs"
                    onClick={() => {
                      setListLevel(item.level);
                    }}
                    key={item.level}
                  />
                ))}
            </Tabs>
          </Paper>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Grid item display={{ xs: "block", md: "none" }} xs={12}>
          <Select
            value={levels[selectedTab]?.value}
            options={
              levels &&
              levels.map(level => ({
                label: level.level_name,
                value: level.level,
              }))
            }
            className="sim-select"
            onChange={item => {
              setListLevel(item.value);
              setSelectedTab(item.value - 2);
            }}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default DealerLevelTabs;

import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";

import { ModalCard } from "../../../../shared/ModalCard";
import { AddSupplierLinkModal } from "./AddSupplierLinkModal";

export function AddSupplierLinkButton({ restVar, addSupplierLink }) {
  // Default states
  const defModals = {
    addLinkModal: false,
  };

  // States

  const [modals, setModals] = useState(defModals);

  // Set State handlers

  const toggleAddLinkModal = () =>
    setModals(prev => ({ ...prev, addLinkModal: !prev.addLinkModal }));

  // Submission handler

  const addSupplierLinkHandler = newLink => {
    addSupplierLink(newLink);
    toggleAddLinkModal();
  };

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="flex-end">
        <Button onClick={toggleAddLinkModal} color="secondary">
          Add Link
        </Button>
      </Grid>
      <ModalCard open={modals.addLinkModal} onClose={toggleAddLinkModal}>
        <AddSupplierLinkModal
          restVar={restVar}
          addNewLink={addSupplierLinkHandler}
          onClose={toggleAddLinkModal}
        />
      </ModalCard>
    </Grid>
  );
}

export default AddSupplierLinkButton;

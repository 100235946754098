/* eslint-disable react/no-array-index-key */
// #useAuthUpdated
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useSB } from "../../contexts/SupabaseContext";
import { useCompany } from "../../contexts/CompanyContext";
import { useAuth } from "../../contexts/AuthContext";
import { subtitleStyles } from "../../mui/styles";
import sqlToStandardDate from "../shared/functions/sqlToStandardDate";
import EditProfile from "./EditProfile";
import UpdateFreezeWrapper from "../admin-section/UpdateFreezeWrapper";
import SnapshotTiles from "./SnapshotTiles";

export default function ProfilePage() {
  const { GetTableWhere } = useSB();
  const [dealer, setDealer] = useState();
  const [editDealerOpen, setEditDealerOpen] = useState(false);
  const { productTypes, suppliers, updateFreeze } = useCompany();
  const [parent, setParent] = useState(null);
  const [upStream, setUpstream] = useState([]);
  const { currentUser } = useAuth();
  const dealerId = currentUser?.id;

  async function getSubDealerDetails(subId) {
    // Get the dealer document by its ID
    const dealerData = await GetTableWhere("dealers", ["id", "eq", subId]);

    // If the dealer document does not exist, throw an error
    if (!dealerData) {
      throw new Error(`Dealer with ID ${subId} not found`);
    }

    // Get the parent dealer document by its ID
    const parentData = await GetTableWhere("dealers", [
      "dealer_code",
      "eq",
      dealerData[0].parent_dealer,
    ]);

    // If the parent dealer document does not exist, throw an error
    if (!parentData) {
      throw new Error(
        `Parent dealer with ID ${dealerData[0].parent_dealer} not found`
      );
    }

    // Get the parent dealer document by its ID
    const ParentDealers = await GetTableWhere("commission_org_structure", [
      "dealer_code",
      "eq",
      dealerData[0].dealer_code,
    ]);
    setUpstream(ParentDealers);

    // Return the dealer and parent dealer documents
    return {
      dealer: dealerData[0],
      parent: parentData[0],
    };
  }

  // Function to refresh the displayed details of the dealer
  const refreshDisplayedDetails = async () => {
    setDealer(null);
    const d = await getSubDealerDetails(dealerId);
    setParent(d.parent);
    setDealer(d.dealer);

    // setGrowthLeader(d.dealer.growth_leader);
  };

  useEffect(async () => {
    const d = await getSubDealerDetails(dealerId);
    setParent(d.parent);
    setDealer(d.dealer);
    // setGrowthLeader(d.dealer.growth_leader);
  }, []);

  // Function to parse the selected products from the user
  const parseSelectedProducts = productsFromUser => {
    if (!productsFromUser) {
      return "No Products Selected";
    }

    const selectedProducts = productTypes.filter(product =>
      productsFromUser.includes(product.id)
    );

    return selectedProducts?.map(product => product.name).join(", ") || [];
  };

  // Function to parse the selected suppliers from the user
  const parseSelectedSuppliers = suppliersFromUser => {
    if (!suppliersFromUser) {
      return "No Suppliers Selected";
    }

    const selectedSuppliers = suppliers.filter(supplier =>
      suppliersFromUser.includes(supplier.id)
    );

    return (
      selectedSuppliers?.map(supplier => supplier.supplier).join(", ") || []
    );
  };

  const buttonStyles = {
    margin: ".5rem .5rem 0 0",
    textTransform: "none",
  };

  return !dealer ? (
    <CircularProgress />
  ) : (
    <>
      <UpdateFreezeWrapper />
      <SnapshotTiles currentUser={currentUser} />
      {dealer.phone ? (
        <div style={{ width: "100%" }}>
          <h1 style={subtitleStyles}>
            {dealer.contact_name} {dealer.contact_surname}
          </h1>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Personal info
                  </Typography>{" "}
                  <Typography>
                    Your Z-Code:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {dealer.dealer_code}
                    </span>
                  </Typography>
                  <Typography>
                    ID Number:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {dealer.contact_id_no}
                    </span>
                  </Typography>
                  <Typography>
                    Phone Number:{" "}
                    <span style={{ fontWeight: "bold" }}>{dealer.phone}</span>
                  </Typography>
                  <Typography>
                    Email:{" "}
                    <span style={{ fontWeight: "bold" }}>{dealer.email}</span>
                  </Typography>
                  <Typography>
                    Date Joined:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {sqlToStandardDate(dealer.created_at)}
                    </span>
                  </Typography>
                  <br />
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Address
                  </Typography>
                  <Typography>
                    Street Address:{" "}
                    <span style={{ fontWeight: "bold" }}>{dealer.street}</span>
                  </Typography>
                  <Typography>
                    Suburb:{" "}
                    <span style={{ fontWeight: "bold" }}>{dealer.suburb}</span>
                  </Typography>
                  <Typography>
                    City:{" "}
                    <span style={{ fontWeight: "bold" }}>{dealer.city}</span>
                  </Typography>
                  <Typography>
                    Province:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {dealer.province}
                    </span>
                  </Typography>
                  <Typography>
                    Postal Code:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {dealer.postal_code}
                    </span>
                  </Typography>
                  <br />
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Services
                  </Typography>
                  <Typography style={{ textTransform: "capitalize" }}>
                    Selected Product Type:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {parseSelectedProducts(dealer.selected_product_types)}
                    </span>
                  </Typography>
                  <Typography style={{ textTransform: "capitalize" }}>
                    Selected Suppliers:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {parseSelectedSuppliers(dealer.selected_suppliers)}
                    </span>
                  </Typography>
                  <br />
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Banking info
                  </Typography>
                  <Typography>
                    Payment Type:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {dealer.payment_type}
                    </span>
                  </Typography>
                  {dealer.payment_type === "eWallet" ? (
                    <Typography>
                      eWallet Number:{" "}
                      <span style={{ fontWeight: "bold" }}>{dealer.phone}</span>
                    </Typography>
                  ) : (
                    <>
                      <Typography>
                        Bank:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {dealer.bank}
                        </span>
                      </Typography>
                      <Typography>
                        Account Type:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {dealer.bank_account_type}
                        </span>
                      </Typography>
                      <Typography>
                        Branch Code:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {dealer.branch_code}
                        </span>
                      </Typography>
                      <Typography>
                        Account Number:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {dealer.bank_account_number}
                        </span>
                      </Typography>
                    </>
                  )}
                  <br />
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Other Details
                  </Typography>
                  <Typography>
                    T-Shirt Size:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {dealer.t_shirt_size}
                    </span>
                  </Typography>
                  <br />
                  <Button
                    disableElevation
                    startIcon={<Edit />}
                    variant="contained"
                    color="primary"
                    style={buttonStyles}
                    onClick={() => setEditDealerOpen(true)}
                    disabled={updateFreeze}
                  >
                    Edit Your Details
                  </Button>
                  <Link href="/update-password">
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      style={buttonStyles}
                    >
                      Change Your Password
                    </Button>
                  </Link>
                </CardContent>
              </Card>
              <br />
              <Card>
                <CardContent>
                  {parent ? (
                    <>
                      <Typography>Report to:</Typography>

                      {upStream && upStream[0]?.level_number >= 8 ? (
                        <Typography>
                          Assistant Team Leader:{" "}
                          {upStream[0]?.assistant_team_leader}
                        </Typography>
                      ) : null}

                      {upStream && upStream[0]?.level_number >= 7 ? (
                        <Typography>
                          {" "}
                          Tier 1 Team Leader: {upStream[0]?.tier_1_team_leader}
                        </Typography>
                      ) : null}

                      {upStream && upStream[0]?.level_number >= 6 ? (
                        <Typography>
                          Tier 2 Team Leader: {upStream[0]?.tier_2_team_leader}
                        </Typography>
                      ) : null}

                      {upStream && upStream[0]?.level_number >= 5 ? (
                        <Typography>
                          Sales manager: {upStream[0]?.sales_manager}
                        </Typography>
                      ) : null}
                      {upStream && upStream[0]?.level_number >= 4 ? (
                        <Typography>Dealer: {upStream[0]?.dealer}</Typography>
                      ) : null}
                      {upStream && upStream[0]?.level_number >= 3 ? (
                        <Typography>
                          Super Dealer: {upStream[0]?.super_dealer}
                        </Typography>
                      ) : null}
                      {dealer.rank <= 2 ? (
                        <Typography>Zawadi management</Typography>
                      ) : null}
                    </>
                  ) : (
                    <Typography>No Parent Found</Typography>
                  )}
                  <br />
                </CardContent>
              </Card>
              <br />
            </Grid>
          </Grid>
          <EditProfile
            dialogIsOpen={editDealerOpen}
            closeDialog={() => {
              setEditDealerOpen(false);
              refreshDisplayedDetails();
            }}
            dealerProp={dealer}
            editing
          />
        </div>
      ) : (
        <Typography>User does not exist.</Typography>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Grid,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Select from "react-select";
import { pageTitleStyles } from "../../../../mui/styles";
import { useCompany } from "../../../../contexts/CompanyContext";
import { useStructure } from "../../../../contexts/StructureContext";
import BackLink from "../../../shared/BackLink";
import LogASale from "./LogASale";
import FibrePeriodicOrderReport from "./FibrePeriodicOrderReport";
import UnpaidOrderReport from "./UnpaidOrderReport";
import UninstalledOrderReport from "./UninstalledOrderReport";
import StructureReport from "./StructureReport";
import SalesAssist from "./salesAssist/SalesAssist";
import AgentAssist from "./agent-agent-assist/AgentAssist";
import SupplierLinks from "./supplier-links/SupplierLinks";
import Banner from "../../../global/Banner";

export default function Supplier({ match }) {
  // State to hold the selected supplier's details
  const [supplier, setSupplier] = useState({
    id: "",
    supplier: "",
    supplier_description: "",
    supplier_status: "",
    contract_start_date: "01/01/2001",
    contract_end_date: "01/01/2001",
    admin_contact_person: "",
    admin_contact_number: "",
    admin_contact_email: "",
    finance_contact_person: "",
    finance_contact_number: "",
    finance_contact_email: "",
    data_contact_person: "",
    data_contact_number: "",
    data_contact_email: "",
    it_contact_person: "",
    it_contact_number: "",
    it_contact_email: "",
    log_sales: false,
    log_sales_name: "",
    custom_page: false,
    custom_page_url: "",
    logo: "",
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State to hold impersonation details
  const [impersonation, setImpersonation] = useState([]);

  // State to hold the selected report type
  const [selectedReport, setSelectedReport] = useState({
    label: "Unpaid Order Report",
    value: "unpaid_order_report",
  });

  // State to track the selected tab in the UI
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedReportTab, setSelectedReportTab] = useState(0);

  // Destructuring the activeSuppliers from the useCompany context
  const { activeSuppliers } = useCompany();
  const { downstream } = useStructure();

  // Extracting the supplier ID from the match props
  const selectedSupplierId = match.params.supplierId;

  // Filtering the active suppliers to find the selected supplier by ID
  const supplierData = activeSuppliers.filter(
    filteredSupplier => filteredSupplier.id === selectedSupplierId
  )[0];

  // Helper function to capitalize the first letter of a string
  const transformName = item => {
    return item[0].toUpperCase() + item.slice(1);
  };

  // Mapping the downstream agents for the selected supplier
  const downstreamMap = downstream
    .filter(agent => agent.selected_suppliers.includes(selectedSupplierId))
    .map(agent => ({
      value: agent.dealer_code,
      label: `${agent.dealer_code}: ${transformName(
        agent.contact_name
      )} ${transformName(agent.contact_surname)}`,
    }));

  // Effect to set the initial tab based on whether the supplier has reports
  useEffect(() => {
    if (!supplier) {
      return;
    }
    if (supplier && !supplier.reports) {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
  }, [supplier]);

  // Effect to update the supplier state when supplierData changes
  useEffect(() => {
    setSupplier(supplierData);
  }, [supplierData]);

  // Handler for changing the selected tab in the UI
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Handler for changing the selected report tab
  const handleReportTabChange = (event, newValue) => {
    setSelectedReportTab(newValue);
  };
  const [maxWidth, setMaxWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setMaxWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ maxWidth }}>
      <BackLink />
      <Hidden smDown>
        <Grid container justifyContent="center" alignItems="center">
          <Banner />
        </Grid>
      </Hidden>
      {supplier ? (
        <div>
          <h1 style={pageTitleStyles}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ maxWidth }}
            >
              <Grid item>
                <Hidden smDown>{supplier.supplier}</Hidden>{" "}
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: isMobile ? "center" : "flex-end",
                    justifyContent: isMobile ? "center" : "flex-end",
                    padding: isMobile ? "0 16px" : "0 32px", // Adjust padding based on screen size
                    marginTop: isMobile ? 0 : -45, // Adjust padding based on screen size
                  }}
                >
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant={isMobile ? "scrollable" : "standard"} // Scrollable on mobile for better usability
                    scrollButtons={isMobile ? "auto" : "on"} // Show scroll buttons on mobile
                    allowScrollButtonsMobile
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                      flexGrow: 1, // Allow Tabs to grow and fill available space
                      "& .MuiTab-root": {
                        minWidth: isMobile ? 80 : 120, // Adjust minWidth for tabs
                        padding: isMobile ? "6px 8px" : "12px 16px", // Adjust padding for tabs
                        fontSize: isMobile ? "0.875rem" : "1rem", // Adjust font size for tabs
                      },
                      "& .Mui-selected": {
                        fontWeight: "bold", // Highlight selected tab
                      },
                    }}
                  >
                    <Tab disabled={!supplier.reports} label="Reports" />
                    <Tab label="Links" />
                    <Tab
                      disabled={!supplier.sales_assist}
                      label="Sales Assist"
                    />
                    <Tab
                      disabled={!supplier.agent_assist}
                      label="Agent Assist"
                    />
                    <Tab disabled={!supplier.log_sales} label="Log a Sale" />
                  </Tabs>
                </div>
              </Grid>
            </Grid>
          </h1>

          {selectedTab === 0 && supplier.reports && (
            <div>
              <div style={{ marginBottom: 40 }}>
                <p>Select Downstream Agent:</p>
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  placeholder="Select Agent"
                  options={downstreamMap}
                  onChange={v => {
                    setImpersonation(v);
                  }}
                />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Tabs
                  value={selectedReportTab}
                  onChange={handleReportTabChange}
                >
                  <Tab disabled={!supplier.reports} label="Orders" />
                  <Tab disabled={!supplier.reports} label="Actions" />
                  <Tab disabled={!supplier.reports} label="Structure" />
                </Tabs>
              </div>
              <div style={{ marginTop: 50 }}>
                {selectedReportTab === 0 && (
                  <FibrePeriodicOrderReport
                    impersonation={impersonation.value}
                    impersonationName={impersonation.label}
                    supplier={supplier.reports_name}
                    supplierPrettyName={supplier.reports_display_name}
                  />
                )}
                {selectedReportTab === 1 && (
                  <>
                    <div style={{ marginBottom: 40 }}>
                      <Select
                        placeholder="Select Report"
                        options={[
                          {
                            label: "Unpaid Order Report",
                            value: "unpaid_order_report",
                          },
                          {
                            label: "Uninstalled Order Report",
                            value: "uninstalled_order_report",
                          },
                        ]}
                        defaultValue={{
                          label: "Unpaid Order Report",
                          value: "unpaid_order_report",
                        }}
                        onChange={v => {
                          setSelectedReport(v);
                        }}
                      />
                    </div>
                    {selectedReport.value === "unpaid_order_report" && (
                      <UnpaidOrderReport
                        impersonation={impersonation.value}
                        impersonationName={impersonation.label}
                        supplier={supplier.reports_name}
                        supplierPrettyName={supplier.reports_display_name}
                      />
                    )}
                    {selectedReport.value === "uninstalled_order_report" && (
                      <UninstalledOrderReport
                        impersonation={impersonation.value}
                        impersonationName={impersonation.label}
                        supplier={supplier.reports_name}
                        supplierPrettyName={supplier.reports_display_name}
                      />
                    )}
                  </>
                )}
                {selectedReportTab === 2 && (
                  <StructureReport
                    impersonation={impersonation.value}
                    impersonationName={impersonation.label}
                    supplier={supplier.reports_name}
                    supplierPrettyName={supplier.reports_display_name}
                  />
                )}
              </div>
            </div>
          )}
          {selectedTab === 1 && supplier.id && (
            <SupplierLinks supplierId={supplier.id} />
          )}
          {selectedTab === 2 && (
            <div>
              <SalesAssist Supplier={supplier.id} />
            </div>
          )}
          {selectedTab === 3 && (
            <div>
              <AgentAssist Supplier={supplier.id} />
            </div>
          )}
          {selectedTab === 4 && supplier.log_sales && (
            <LogASale supplier={supplier} />
          )}
        </div>
      ) : (
        <h2>Loading...</h2>
      )}
    </div>
  );
}

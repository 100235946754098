// #useAuthUpdated

import {
  Box,
  Paper,
  Avatar,
  Button,
  Container,
  CssBaseline,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { LockOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import logo from "../../img/ZawadiHighRes.gif";
import Copyright from "./Copyright";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    padding: "30px",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#f6fffd",
  },
  avatar: {
    margin: theme.spacing(1),
    marginBottom: "1rem",
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  success: {
    color: "green",
  },
}));

export default function UpdatePassword() {
  const classes = useStyles();
  const [password, setPassword] = useState(null);
  const { resetPasswordForEmail } = useAuth();
  const history = useHistory();

  // Action State
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (password) {
      try {
        setError("");
        setLoading(true);
        resetPasswordForEmail(password);
      } catch (err) {
        setError(err.message);
      }
    } else {
      setError("Please enter a valid password.");
    }
    setLoading(false);
    history.push("/profile");
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        style={{
          flexDirection: "column",
          minHeight: "100vh",
          display: "flex",
        }}
        justifyContent="space-between"
      >
        <Paper className={classes.paper}>
          <img src={logo} width={200} alt="Logo" draggable="false" />
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            style={{ marginBottom: "1rem" }}
          >
            Change your password
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="New Password"
              name="password"
              type="password"
              autoFocus
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
            <br />
            <br />

            {error ? (
              <Alert style={{ width: "100%" }} severity="error">
                {error}
              </Alert>
            ) : (
              <Alert style={{ width: "100%" }} severity="info">
                Your password will be updated with the entered password.
              </Alert>
            )}
            <Button
              type="submit"
              disabled={loading}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Update Password
            </Button>
          </form>
        </Paper>
        <Copyright />
      </Box>
    </Container>
  );
}

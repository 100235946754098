import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";

export default function BackLink() {
  const history = useHistory();
  return (
    <Button
      color="primary"
      startIcon={<ArrowBack />}
      onClick={() => history.goBack()}
      style={{
        marginBottom: 10,
      }}
    >
      Back
    </Button>
  );
}

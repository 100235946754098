const { createClickupTicket } = require("../../../../api");

const description =
  "Please note that a new dealer has been onboarded, please ensure that all necessary steps are taken to complete the onboarding process.";
/**
 * Creates a ClickUp ticket with the given name.
 *
 * @param {string} name - The name of the ClickUp ticket.
 * @returns {Promise<Object>} The response from the ClickUp API.
 * @throws Will throw an error if the ClickUp ticket creation fails.
 */
const clickupTicket = async name => {
  const response = await createClickupTicket({
    list: "901507056343", // clickup list ID for new dealers onboarding
    name,
    desc: description,
  });
  if (response.status !== 200) {
    // eslint-disable-next-line
    alert("Failed to create ClickUp ticket");
    throw new Error("Failed to create ClickUp ticket");
  }
  return response;
};

module.exports = clickupTicket;

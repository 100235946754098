/* eslint-disable no-confusing-arrow */
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { People, Person } from "@material-ui/icons";
import DealersDataGrid from "./data-grid/DealersDataGrid";

export default function DealerDownstreamTable({ dealers, loading }) {
  const rows =
    dealers &&
    dealers.map(dealer => ({
      id: dealer.id,
      name: `${dealer.contact_name} ${dealer.contact_surname}`,
      phone: `0${dealer.phone.slice(3)}`,
      parentDealer: dealer.parent_dealer,
      rank: dealer.rank,
    }));

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "phone", headerName: "Phone", width: 120 },
    { field: "rank", headerName: "Level", width: 160 },
    { field: "parent_dealer", headerName: "Parent", width: 180 },
    {
      field: "details",
      headerName: "Details",
      sortable: false,
      width: 120,
      renderCell: params => (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <Link to={`/dealer-detail/${params.row.id}`}>
            <Button startIcon={<Person style={{ opacity: 0.5 }} />}>
              Details
            </Button>
          </Link>
        </div>
      ),
    },
    {
      field: "downstream",
      headerName: "Downstream",
      sortable: false,
      width: 140,
      renderCell: params =>
        params.row.rank > 7 ? null : (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Link to={`/child-dealer-downstream/${params.row.id}`}>
              <Button startIcon={<People style={{ opacity: 0.5 }} />}>
                Downstream
              </Button>
            </Link>
          </div>
        ),
    },
  ];

  return (
    <DealersDataGrid rowsData={rows} columns={columns} loading={loading} />
  );
}

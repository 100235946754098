// #useAuthUpdated

import React, { useState, useEffect } from "react";
import { Hidden, Typography } from "@material-ui/core";
import SupplierList from "./dash-components/SupplierList";
import { pageTitleStyles } from "../../../mui/styles";
import LeaderBoard from "./LeaderBoard/LeaderBoard";
import { useCompany } from "../../../contexts/CompanyContext";
import Banner from "../../global/Banner";

export default function SelectDash() {
  const [loading, setLoading] = useState(true);
  const { suppliers } = useCompany();

  useEffect(() => {
    if (suppliers) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [suppliers]);

  return (
    <div>
      <Hidden smUp>
        <Typography
          variant="h4"
          style={{
            fontWeight: "550",
            userSelect: "none",
            marginBottom: "1rem",
            marginTop: "-1rem",
            textAlign: "center", // Responsive breakpoints with MUI's sx prop
          }}
        >
          Choose a dashboard
        </Typography>
      </Hidden>
      <Hidden smDown>
        <Typography
          variant="h4"
          style={{ ...pageTitleStyles, fontWeight: "550" }}
        >
          Choose a dashboard
        </Typography>
      </Hidden>

      {/* Render SupplierList only when loading is complete */}
      <div>{loading ? null : <SupplierList url="/Supplier/" />}</div>

      {/* Ensure the Banner is centered and takes up full width */}
      <div
        style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}
      >
        <Hidden smDown>
          <Banner imgUrl="https://images.squarespace-cdn.com/content/v1/602d1a7212b33a4973f1ba6a/cd243ba0-b0bb-469d-adef-753e186c6281/Web+Banner+-+655x165px+%281200+x+300+px%29+%281%29.png" />
        </Hidden>
      </div>

      {/* Render the LeaderBoard component */}
      <LeaderBoard />
    </div>
  );
}

/* eslint-disable object-shorthand */
import { CircularProgress, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
// import nanoId from "nano-id";
import { useAuth } from "../../contexts/AuthContext";
import { useSB } from "../../contexts/SupabaseContext";
// import * as API from "../../api";

export default function EmailVerification() {
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const { GetTableWhere, updateRow } = useSB();
  const { verificationId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (currentUser.email_verified) {
      setEmailVerified(true);
      setLoading(false);
    } else {
      verifyEmail(currentUser);
    }
  }, []);

  const verifyEmail = async user => {
    GetTableWhere(
      "email_verification",
      ["auth_id", "eq", user.auth_id],
      ["verification_id", "eq", verificationId]
    ).then(data => {
      if (data.length > 0) {
        // Update DB Table
        updateRow("dealers", user.id, {
          email_verified: true,
        }).then(() => {
          setEmailVerified(true);
          setLoading(false);
          setTimeout(() => {
            history.push("/profile");
          }, 1000);
        });
      } else {
        setLoading(false);
        setEmailVerified(false);
      }
    });
  };

  return (
    <div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={{ marginBottom: 20 }}>
              Verifying Email, please wait...
            </Typography>
            <CircularProgress />
          </div>
        ) : (
          <div>
            {emailVerified ? (
              <div>
                <Typography style={{ marginBottom: 20 }}>
                  Thank you, your email address has been verified.
                </Typography>
              </div>
            ) : (
              <div>
                <Typography style={{ marginBottom: 20 }}>
                  There was an issue verifying your email address, please try
                  again or contact support.
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

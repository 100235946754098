/* eslint-disable object-shorthand */
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useSB } from "../../contexts/SupabaseContext";

export default function PhoneVerification() {
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { currentUser } = useAuth();
  const { GetTableWhere, updateRow } = useSB();
  const [oneTimePin, setOneTimePin] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (currentUser.phone_verified) {
      setPhoneVerified(true);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  const verifyOTP = async () => {
    setLoading(true);
    setError(false);
    GetTableWhere(
      "phone_verification",
      ["auth_id", "eq", currentUser.auth_id],
      ["one_time_pin", "eq", oneTimePin]
    ).then(data => {
      if (data.length > 0) {
        // Update DB Table
        updateRow("dealers", currentUser.id, {
          phone_verified: true,
        }).then(() => {
          setPhoneVerified(true);
          setLoading(false);
          setTimeout(() => {
            history.push("/profile");
          }, 1000);
        });
      } else {
        setError(true);
        setLoading(false);
        setPhoneVerified(false);
      }
    });
  };

  return (
    <div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={{ marginBottom: 20 }}>
              Verifying phone number, please wait...
            </Typography>
            <CircularProgress />
          </div>
        ) : (
          <div>
            {phoneVerified ? (
              <div>
                <Typography style={{ marginBottom: 20 }}>
                  Thank you, your phone number has been verified.
                </Typography>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography style={{ marginBottom: 20 }}>
                  {error
                    ? "Your One Time Pin is not correct. Please try again"
                    : "Please enter your One Time Pin that was sent to you by SMS."}
                </Typography>
                <div style={{ marin: 20, width: 300 }}>
                  <TextField
                    value={oneTimePin}
                    onChange={e => {
                      setOneTimePin(e.target.value);
                    }}
                    id="outlined-required"
                    label="Enter Your One Time Pin"
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <Button
                  style={{ marginTop: 24 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    verifyOTP();
                  }}
                >
                  Verify OTP
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

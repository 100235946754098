import { supabase } from "../../supabase";

async function SBFileDownloader(bucket, folder = "") {
  // Get file list
  const { data: files, error } = await supabase.storage
    .from(bucket)
    .list(folder);

  if (error) {
    throw error;
  }

  // If there are no files in the folder, throw an error
  if (!files || !files.length) {
    // throw new Error("No files to download");
    return [];
  }

  const retArr = files.map(file => {
    const { data } = supabase.storage
      .from(bucket)
      .getPublicUrl(`${folder}/${file.name}`);

    const downloadUrl = `${data.publicUrl}?download`;

    return {
      id: file.id,
      fileName: file.name,
      downloadUrl,
    };
  });

  return retArr;
}

export default SBFileDownloader;

import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useCompany } from "../../contexts/CompanyContext";

/**
 * UpdateFreezeWrapper is a React component that wraps its children and displays
 * an alert if there is an active commission freeze.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} [props.children=<div />] - The children elements to render when there is no commission freeze. Defaults to an empty `div` if not provided.
 * @returns {React.ReactNode} - Returns the children if no freeze is active, or an error alert if the commission freeze is active.
 *
 * @example
 * // Wrap content to conditionally display a freeze alert
 * <UpdateFreezeWrapper>
 *   <MyComponent />
 * </UpdateFreezeWrapper>
 */
function UpdateFreezeWrapper({ children = <div /> }) {
  const { updateFreeze } = useCompany();
  return updateFreeze ? (
    <div>
      <br />
      <Alert severity="error">
        <AlertTitle>Commission Freeze Active</AlertTitle>Please note that during
        commission periods, some updates are temporarily frozen. No changes or
        adjustments can be made until the freeze is lifted. This ensures
        accurate processing and reporting. Thank you for your understanding.
      </Alert>
      <br />
    </div>
  ) : (
    children
  );
}

export default UpdateFreezeWrapper;

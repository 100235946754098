import { sendEmail } from "../../../api";

/**
 * Sends an approval email to the dealer and parent dealer with the dealer's unique
 * code and other relevant information.
 * @param {Object} param0 - An object with the following properties:
 * @param {Object} param0.parent - The parent dealer.
 * @param {Object} param0.dealer - The dealer being approved.
 * @param {string} param0.dealer_code - The dealer's unique code.
 * @returns {Promise<string>} - A promise that resolves with a success message or
 * rejects with an error message.
 */
export default function sendApprovalEmail({ parent, dealer, dealer_code }) {
  return new Promise((resolve, reject) => {
    // Optional: You can add validation logic here if needed
    const email = [dealer?.email, parent?.email];
    const subject = "ZIMS Agent Approval";
    const message = `
    Dear ${dealer?.contact_name},

    Congratulations! 

    Your application to become a Zawadi agent has been approved. 

    Your unique agent code: ${dealer_code}

    Please log into your Zawadi web app account (ZIMS):
    https://app.zawadi.africa (Zawadi web app (ZIMS) link).
    
    Below are your login details for the ZIMS platform:
    
    Username: ${dealer?.email} 
    Password: ${dealer?.temp_pw}  
    Please keep this information confidential.

    Please login as soon as possible and click on the Zawadi University option to complete all your required training modules. 

    Please note: If you're registered for SADV sales, please keep an eye on your email inbox!
    You can expect an email with the log in details for the SADV portal within the next 24 working hours.
    Make sure to check your spam folder just in case.

    Best regards
    ZIMS Team
    `;
    // Send the email using the sendEmail function
    sendEmail({
      email, // Email to send to
      subject, // Email subject
      message, // Email message
    })
      .then(response => {
        resolve("Email sent successfully", response.data);
      })
      .catch(error => {
        console.error("Error sending email:", error); // Handle error
        reject("Error sending email: ", error);
      });
  });
}

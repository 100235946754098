import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfigs = {
  development: {
    apiKey: "AIzaSyAwIQQVteiwNs5V5XGOsLnJ_K8Mn7rXRac",
    authDomain: "zawadi-dev.firebaseapp.com",
    projectId: "zawadi-dev",
    storageBucket: "zawadi-dev.appspot.com",
    messagingSenderId: "662521322075",
    appId: "1:662521322075:web:f3d017541549cd07ee7d7e",
  },
  production: {
    apiKey: "AIzaSyBShinD9EgXinSJ2WdS7Lyd2xtAFjn-_2M",
    authDomain: "zawadi-e2cad.firebaseapp.com",
    projectId: "zawadi-e2cad",
    storageBucket: "zawadi-e2cad.appspot.com",
    messagingSenderId: "18072782301",
    appId: "1:18072782301:web:0d6390c0bc87dcc01f6234",
  },
};

const currentConfig = firebaseConfigs[process.env.REACT_APP_PROJECT_ENV];
const app = firebase.initializeApp(currentConfig);

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const fieldValue = firebase.firestore.FieldValue;
export const authFunc = firebase.auth;
export const functions = firebase.functions();

export default app;

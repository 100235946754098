const moment = require("moment");
const { generateNewContract } = require("../../../../api");

/**
 * Generates a new dealer contract.
 *
 * @param {string} agentCode - The code of the agent.
 * @param {string} email - The email address of the dealer/agent.
 * @param {Array} arrApendixes - An array of appendixes to include in the contract.
 * @returns {Promise<Object>} The response from the contract generation service.
 */
const generateNewDealerContract = async (agentCode, email, arrApendixes) => {
  const arrApendixesString = JSON.stringify(arrApendixes);
  const response = await generateNewContract({
    agentCode,
    email,
    arrIds: arrApendixesString,
    documentName: `New Dealer Contract - ${moment().format(
      "YYYY-MM-DD HH:mm"
    )}`,
  });
  if (response.status !== 200) {
    // eslint-disable-next-line
    alert("Failed to generate new dealer contract");
    throw new Error("Failed to generate new dealer contract");
  }
  return response;
};

module.exports = generateNewDealerContract;

import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import {
  faTachometerAlt,
  faIdCard,
  faChartBar,
  faToolbox,
  faSignOutAlt,
  faEnvelope,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { makeStyles, darken } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sideBg from "../../img/menuBg-min.png";
import { useAuth } from "../../contexts/AuthContext";

function AdminDrawer({
  drawerOpen,
  drawerWidth = 240,
  handleDrawerClose,
  handleLogOut,
}) {
  const { currentUser } = useAuth();
  const [currentUserTitle, setCurrentUserTitle] = useState("");
  const [currentUserRank, setCurrentUserRank] = useState("");

  useEffect(() => {
    const rankNames = [
      "Admin",
      "Super Dealer",
      "Dealer",
      "Sales Manager",
      "Tier 2 Team Leader",
      "Tier 1 Team Leader",
      "Assistant Team Leader",
      "Agent",
    ];
    if (currentUser) {
      const userRank = currentUser.rank;
      setCurrentUserTitle(
        `${currentUser.contact_name} ${currentUser.contact_surname}`
      );
      setCurrentUserRank(
        userRank && rankNames[userRank - 1] ? rankNames[userRank - 1] : ""
      );
    }
  }, [currentUser]);

  const drawerItems = [
    { title: "Dashboard", path: "/", icon: faTachometerAlt },
    { title: "Manage People", path: "/manage-dealers", icon: faIdCard },
    { title: "Zawadi University", path: "/training", icon: faGraduationCap },
    { title: "Reports", path: "/reports", icon: faChartBar },
    { title: "Alerts", path: "/alerts", icon: faEnvelope },
    { title: "Admin", path: "/admin-section", icon: faToolbox },
    { title: "Profile", path: "/profile", icon: faIdCard },
  ];

  const useStyles = makeStyles(theme => ({
    userDetails: {
      display: "flex",
      color: "#fff",
      alignItems: "center",
      padding: "1rem 1.25rem",
      backgroundColor: theme.palette.primary.main,
    },
    detailsAvatar: {
      display: "flex",
      width: "2rem",
      height: "2rem",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
    icons: {
      color: "#fff",
      marginLeft: theme.spacing(2),
    },
    menuText: {
      color: "#fff",
      textDecoration: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      "& .MuiDrawer-paper": { borderWidth: 0 },
    },
    drawerPaper: {
      width: useMediaQuery(theme.breakpoints.down("xs")) ? "100%" : drawerWidth,
      background: `url(${sideBg})`,
      backgroundSize: "cover",
    },
    drawerHeader: {
      ...theme.mixins.toolbar,
      marginTop: -3,
    },
    list: {
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
    hoverColor: {
      "&:hover": {
        background: "rgba(255, 255, 255, .05)",
      },
    },
  }));

  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={drawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader} />
      <Box className={classes.userDetails}>
        <Box className={classes.detailsAvatar}>
          {currentUserTitle && currentUserTitle[0]}
        </Box>
        <Box
          onClick={() => {
            const text = `${
              currentUser.dealer_code ? currentUser.dealer_code : null
            }: ${currentUserTitle}`;

            const input = document.createElement("textarea");
            input.innerHTML = text;
            document.body.appendChild(input);
            input.select();
            const result = document.execCommand("copy");
            document.body.removeChild(input);

            // eslint-disable-next-line no-alert
            alert("Name copied.");
            return result;
          }}
          style={{ paddingLeft: "1.25rem", cursor: "pointer" }}
        >
          <Typography variant="subtitle2">
            {currentUser.dealer_code ? `${currentUser.dealer_code}: ` : null}
            {currentUserTitle}
          </Typography>
          <Typography variant="subtitle2" style={{ opacity: 0.5 }}>
            {currentUserRank}
          </Typography>
          <Typography variant="subtitle2" style={{ opacity: 0.5 }}>
            Click to Copy
          </Typography>
        </Box>
      </Box>
      {/* ===================================================================== */}
      <List className={classes.list}>
        {drawerItems &&
          drawerItems.map((item, index) => (
            <Link
              // eslint-disable-next-line react/no-array-index-key
              key={`${item.title}${index}`}
              to={item.path}
              className="drawer-item"
              onClick={handleDrawerClose}
            >
              <ListItem className={classes.hoverColor}>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.icons} icon={item.icon} />
                </ListItemIcon>
                <ListItemText className={classes.menuText}>
                  {item.title}
                </ListItemText>
              </ListItem>
            </Link>
          ))}
        <ListItem
          onClick={handleLogOut}
          style={{ cursor: "pointer" }}
          className={classes.hoverColor}
        >
          <ListItemIcon>
            <FontAwesomeIcon className={classes.icons} icon={faSignOutAlt} />
          </ListItemIcon>
          <ListItemText className={classes.menuText}>Logout</ListItemText>
        </ListItem>
        {/* Version Number */}
        <div
          style={{
            width: "100%",
            textAlign: "center",
            color: "#fff",
            opacity: 0.5,
          }}
        >
          <p>V 1.0.3</p>
        </div>
      </List>
    </Drawer>
  );
}

export default AdminDrawer;

/**
 * Converts a date string from PostgreSQL format to the user's local date and time format, including their timezone.
 * The output is a string in the format "YYYY-MM-DD hh:mm:ss" or "YYYY-MM-DD" depending on the showTime parameter.
 * If the input is null or undefined, the function returns null.
 * @param {string} sqldate - The date string in PostgreSQL format.
 * @param {boolean} [showTime=false] - Whether to include the time in the output.
 * @returns {string|null} - The formatted date string.
 */
export default function sqlToStandardDate(sqldate, showTime = false) {
  if (sqldate) {
    const originalDate = new Date(sqldate);
    // Get the user's local date and time
    const options = showTime
      ? {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      : { year: "numeric", month: "2-digit", day: "2-digit" };

    // Format the date based on user's locale and timezone
    const formattedDate = originalDate.toLocaleString(undefined, options);

    return formattedDate;
  }
  return null; // Handle null or undefined date
}

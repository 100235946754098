// #useAuthUpdated

import React, { useEffect, useState } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { supabase } from "../../../../../supabase";
import { useAuth } from "../../../../../contexts/AuthContext";
import { SupplierLinkTile } from "./SupplierLinkTile";
import { AddSupplierLinkButton } from "./AddSupplierLinkButton";

function SupplierLinks({ supplierId }) {
  // States
  const [linksArr, setLinksArr] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  // Check if user is an admin
  const userAdmin = currentUser.rank === 1;

  // Rest options for link
  const restVar = {
    DEALER_CODE: currentUser.dealer_code,
    DEALER_NAME: currentUser.dealer_name,
    ZAWADI_DEALER_CODE: `zawadi_${currentUser.dealer_code}`,
  };

  // Function to fetch supplier links from Supabase
  const fetchSupplierLinks = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("supplier_links")
        .select("*")
        .eq("sb_supplier_id", supplierId)
        .eq("is_active", true);

      if (error) throw error;
      setLinksArr(data);
    } catch (error) {
      console.error("Error fetching supplier links:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSupplierLinks();
  }, [supplierId]);

  // Function to add a supplier link
  const addSupplierLink = async newLink => {
    try {
      setLoading(true);
      const { error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("supplier_links")
        .upsert({
          ...newLink,
          supplier_id: supplierId,
          created_by: currentUser.dealer_code,
          sb_supplier_id: supplierId,
        });

      if (error) throw error;
      fetchSupplierLinks();
    } catch (error) {
      console.error("Error adding supplier link:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to remove a supplier link
  const removeSupplierLink = async linkId => {
    try {
      setLoading(true);
      const { error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("supplier_links")
        .update({ is_active: false })
        .eq("id", linkId);

      if (error) throw error;
      fetchSupplierLinks();
    } catch (error) {
      console.error("Error removing supplier link:", error);
    } finally {
      setLoading(false);
    }
  };

  // Mapping over the links received from Supabase
  const linkMap = linksArr.map(supLink => (
    <SupplierLinkTile
      key={supLink.id}
      supLink={supLink}
      userAdmin={userAdmin}
      restVar={restVar}
      removeSupplierLink={removeSupplierLink}
      addSupplierLink={addSupplierLink}
    />
  ));

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        {loading ? (
          <Typography variant="h5">Loading...</Typography>
        ) : (
          <>
            {" "}
            {userAdmin && (
              <AddSupplierLinkButton
                restVar={restVar}
                addSupplierLink={addSupplierLink}
              />
            )}
            <Grid item xs={12}>
              {!linkMap.length ? (
                <Typography>No links for supplier</Typography>
              ) : (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {linkMap}
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}

export default SupplierLinks;

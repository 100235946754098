import React from "react";

import {
  Grid,
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";

export function ConfirmationModal({
  open = true,
  onClose = () => {},
  children = <Typography align="center">Are you sure?</Typography>,
  onConfirm = () => {},
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Grid
          item
          xs={12}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            minWidth: "300px",
          }}
        >
          <Paper style={{ padding: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" align="center">
                  Confirmation
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {children}
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={onConfirm}
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="secondary"
                  size="small"
                  variant="contained"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Fade>
    </Modal>
  );
}

export default ConfirmationModal;

import { createMuiTheme, createTheme } from "@material-ui/core/styles";
import zawadiLogo from "../img/ZawadiHighRes.gif";

const chadezTheme = createTheme({
  palette: {
    primary: {
      main: "#126289",
      contrastText: "#fff",
    },
  },
});

const jurassicTheme = createTheme({
  palette: {
    primary: {
      main: "#a53922",
      contrastText: "#fff",
    },
  },
});

const afrahTheme = createTheme({
  palette: {
    primary: {
      main: "#102b56",
      contrastText: "#fff",
    },
  },
});

// const zawadiTheme = createTheme({
//   palette: {
//     primary: {
//       main: "#267353",
//       contrastText: "#fff",
//     },
//   },
//   typography: {
//     button: {
//       textTransform: "none",
//     },
//   },
// });

// const zawadiTheme2 = createTheme({
//   palette: {
//     primary: {
//       main: "#31a79b", // 60% color
//       contrastText: "#fff",
//     },
//     secondary: {
//       main: "#e68625", // 30% color
//     },
//     background: {
//       default: "#101628", // 10% color
//     },
//   },
//   typography: {
//     fontFamily: "Poppins",
//     button: {
//       textTransform: "none",
//     },
//   },
// });
// const zawadiTheme3 = createTheme({
//   palette: {
//     primary: {
//       main: "#267353", // 60% color
//       contrastText: "#fff",
//     },
//     secondary: {
//       main: "#31a79b", // 30% color
//     },
//     background: {
//       default: "#e68625", // 10% color
//     },
//   },
//   typography: {
//     fontFamily: "Poppins",
//     button: {
//       textTransform: "none",
//     },
//   },
// });
// const zawadiTheme4 = createTheme({
//   palette: {
//     primary: {
//       main: "#e68625", // 60% color
//       contrastText: "#fff",
//     },
//     secondary: {
//       main: "#31a79b", // 30% color
//     },
//     background: {
//       default: "#e68625", // 10% color
//     },
//   },
//   typography: {
//     fontFamily: "Poppins",
//     button: {
//       textTransform: "none",
//     },
//   },
// });

// const zawadiTheme5 = createTheme({
//   palette: {
//     primary: {
//       main: "#31a79b", // 60% color
//       contrastText: "#fff",
//     },
//     secondary: {
//       main: "#e68625", // 30% color
//     },
//     background: {
//       default: "#101628", // 10% color
//     },
//   },
//   typography: {
//     fontFamily: "Poppins",
//     button: {
//       textTransform: "none",
//     },
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: "8px", // Adjust button border-radius
//           padding: "10px 20px", // Customize padding
//           textTransform: "none", // Disable uppercase transformation
//           boxShadow: "none", // Remove default shadow
//           "&:hover": {
//             backgroundColor: "#2d948c", // Custom hover color
//             boxShadow: "none", // Remove hover shadow
//           },
//         },
//         contained: {
//           backgroundColor: "#31a79b",
//           "&:hover": {
//             backgroundColor: "#2d948c",
//           },
//         },
//         outlined: {
//           borderColor: "#31a79b",
//           color: "#31a79b",
//           "&:hover": {
//             borderColor: "#2d948c",
//             backgroundColor: "#f5f5f5",
//           },
//         },
//       },
//     },
//   },
// });

// const zawadiTheme6 = createTheme({
//   palette: {
//     primary: {
//       main: "#101628", // 60% color
//       contrastText: "#fff",
//     },
//     secondary: {
//       main: "#e68625", // 30% color
//     },
//     background: {
//       default: "#101628", // 10% color
//     },
//   },
//   typography: {
//     fontFamily: "Poppins",
//     button: {
//       textTransform: "none",
//     },
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Default shadow
//           "&:hover": {
//             boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)", // Shadow on hover
//           },
//         },
//       },
//     },
//   },
// });

const zawadiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#1b2b4a", // Main color
      contrastText: "#fff",
      dark: "#0c0e1e", // Darker shade for primary
    },
    secondary: {
      main: "#e68625", // Main color
      dark: "#b55e19", // Darker shade for secondary
    },
    background: {
      default: "#1b2b4a",
    },
  },
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "none",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        borderRadius: "10px", // Set the button rounding
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Custom box shadow for contained buttons
        "&:hover": {
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)", // Shadow on hover
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: "16px", // Set the card rounding
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Initial shadow for cards
      },
    },
  },
});

// const zawadiTheme8 = createMuiTheme({
//   palette: {
//     primary: {
//       main: "#e68625", // Main color
//       contrastText: "#fff",
//       dark: "#0c0e1e", // Darker shade for primary
//     },
//     secondary: {
//       main: "#31a79b", // Main color
//       dark: "#b55e19", // Darker shade for secondary
//     },
//     background: {
//       default: "#101628",
//     },
//   },
//   typography: {
//     fontFamily: "Poppins",
//     button: {
//       textTransform: "none",
//     },
//   },
//   overrides: {
//     MuiButton: {
//       root: {
//         borderRadius: "16px", // Set the button rounding
//         boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Custom box shadow for buttons
//         "&:hover": {
//           boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)", // Shadow on hover
//         },
//       },
//     },
//     MuiCard: {
//       root: {
//         borderRadius: "16px", // Set the card rounding
//         boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Initial shadow for cards
//       },
//     },
//     // MuiPaper: {
//     //   root: {
//     //     borderRadius: "16px", // Set the card rounding
//     //     boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Initial shadow for cards
//     //   },
//     // },
//   },
// });
// const zawadiTheme9 = createMuiTheme({
//   palette: {
//     primary: {
//       main: "#e68625", // Main color
//       contrastText: "#fff",
//       dark: "#0c0e1e", // Darker shade for primary
//     },
//     secondary: {
//       main: "#31a79b", // Main color
//       dark: "#b55e19", // Darker shade for secondary
//     },
//     background: {
//       default: "#101628",
//     },
//   },
//   typography: {
//     fontFamily: "Poppins",
//     button: {
//       textTransform: "none",
//     },
//   },
//   overrides: {
//     MuiButton: {
//       root: {
//         borderRadius: "16px", // Set the button rounding
//         boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Custom box shadow for buttons
//         "&:hover": {
//           boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)", // Shadow on hover
//         },
//       },
//     },
//     MuiCard: {
//       root: {
//         borderRadius: "16px", // Set the card rounding
//         boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Initial shadow for cards
//       },
//     },
//     // MuiPaper: {
//     //   root: {
//     //     borderRadius: "16px", // Set the card rounding
//     //     boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Initial shadow for cards
//     //   },
//     // },
//   },
// });
// Companies. Jurassic is our dev environment
const companies = {
  zawadi: {
    theme: zawadiTheme,
    logo: zawadiLogo,
  },
};
export { companies, afrahTheme, chadezTheme, jurassicTheme, zawadiTheme };

// 60 -30 -10 color scheme
// 60 #31a79b
// 30 #e68625
// 10 #101628

// This file contains the AgentImporter component which is used to import agent commissions data from a CSV file.
import { useState, React } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { handleAgentImport } from "./importFunctions";

function AgentImporter() {
  const [importArray, setImportArray] = useState([]);
  const tempArray = [];

  return (
    <div>
      <h3>Agent Import</h3>
      <div>
        <Importer
          restartable
          processChunk={async rows => {
            rows.forEach(row => {
              tempArray.push({
                order_number: row.order_number,
                agent_activation_comm_paid_date_1: row.agent_payment_date,
                agent_activation_comm_paid_to_1: row.agent_act_comm_paid_to_1,
                agent_activation_comm_amount_paid_1:
                  row.agent_act_comm_amount_1,
                agent_activation_comm_tier_1: row.agent_act_comm_tier_1,
              });
            });
            setImportArray(tempArray);
          }}
          onComplete={async () => {
            handleAgentImport(importArray);
          }}
        >
          {/* required Fields */}
          <ImporterField name="order_number" label="Order Number" />
          <ImporterField name="agent_payment_date" label="Agent Payment Date" />
          <ImporterField
            name="agent_act_comm_paid_to_1"
            label="Agent Activation Commission Paid To 1"
          />
          <ImporterField
            name="agent_act_comm_amount_1"
            label="Agent Activation Commission Amount 1"
          />
          <ImporterField
            name="agent_act_comm_tier_1"
            label="Agent Activation Commission Tier 1"
          />
        </Importer>
      </div>
    </div>
  );
}

export { AgentImporter };

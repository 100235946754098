import React from "react";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import { useDb } from "../../../contexts/DatabaseContext";

export default function AllUsers() {
  const { GetAllUsers } = useDb();
  const users = GetAllUsers();

  return (
    <div>
      <CSVLink
        data={users}
        style={{ marginTop: 5, textDecoration: "none" }}
        filename="Users"
      >
        <Button
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faFileExcel} />}
          style={{ backgroundColor: "green", color: "#fff" }}
        >
          {" "}
          Export to CSV
        </Button>
      </CSVLink>
    </div>
  );
}

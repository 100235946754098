/* eslint-disable no-prototype-builtins */
function snakeToCamel(snakeObj) {
  // Helper function to convert a single snake_case string to camelCase
  function toCamel(snakeStr) {
    return snakeStr.replace(/(_\w)/g, match => match[1].toUpperCase());
  }

  // Initialize a new object to hold the converted keys and values
  const camelObj = {};

  // Iterate through each key in the original object
  for (const key in snakeObj) {
    if (snakeObj.hasOwnProperty(key)) {
      // Convert the key to camelCase
      const camelKey = toCamel(key);
      // Assign the value to the new key in the camelCase object
      camelObj[camelKey] = snakeObj[key];
    }
  }

  return camelObj;
}

module.exports = snakeToCamel;

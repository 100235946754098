/* eslint-disable no-alert */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { TextField } from "@mui/material";
import React, { useState } from "react";
import { textFieldProps } from "../../shared/constants";
import { updateDealerPassword } from "../../../api";

export default function ChangeDealerPassword({ open, close, dealerId }) {
  const [state, setState] = useState({
    loading: false,
    error: null,
    data: {},
  });

  const handleClose = () => {
    setState({
      loading: false,
      error: null,
      data: {},
    });
    close();
  };

  const handleChange = e => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true,
      error: null,
    });
    try {
      updateDealerPassword({
        auth_id: dealerId,
        password: state.data.password,
      })
        .then(result => {
          if (result.data.code === 200) {
            alert("Password updated successfully");
          } else {
            alert("Error updating password");
          }
        })
        .catch(err => {
          alert("Error updating password");
          console.error(err.message);
        })
        .finally(() => {
          handleClose();
        });
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message,
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...textFieldProps}
          label="Password"
          onChange={handleChange}
          name="password"
        />
      </DialogContent>
      <DialogActions>
        {state.error && (
          <Typography color="secondary">{state.error}</Typography>
        )}
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Save />}
          onClick={handleSubmit}
          disabled={
            state.loading ||
            !state.data.password ||
            state.data.password.length < 6
          }
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

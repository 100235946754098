import React, { useEffect } from "react";
import { TextField, IconButton } from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

function DataGridSearchBar({
  requestSearch = () => {},
  searchText = "",
  clearSearch = () => {},
}) {
  const handleSearch = e => {
    requestSearch(e.target.value);
  };

  useEffect(() => {
    requestSearch(searchText);
  }, [searchText]);

  return (
    <TextField
      fullWidth
      variant="standard"
      value={searchText}
      onChange={handleSearch}
      placeholder="Search…"
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" />,
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: searchText ? "visible" : "hidden" }}
            onClick={clearSearch}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
}

export default DataGridSearchBar;

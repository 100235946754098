const { banks } = require("../constants");

const NULL_BANK = {
  label: null,
  value: null,
  branchCode: null,
};

function getBankDetails(key) {
  if (!key || (typeof key !== "string" && typeof key !== "number")) {
    return NULL_BANK;
  }

  const keyString = String(key).toLowerCase();

  let bank = banks.find(
    b =>
      b.label.toLowerCase() === keyString ||
      b.value.toLowerCase() === keyString ||
      b.branchCode === keyString
  );

  if (!bank || !bank.value || !bank.branchCode) {
    bank = banks.find(
      b =>
        b.label.toLowerCase().includes(keyString) ||
        b.value.toLowerCase().includes(keyString) ||
        b.branchCode.includes(keyString)
    );
  }

  if (!bank || !bank.value || !bank.branchCode) {
    return NULL_BANK;
  }

  return bank;
}

module.exports = getBankDetails;

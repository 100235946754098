import { Button } from "@material-ui/core";
import React from "react";
import { ArrowBack } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { pageTitleStyles } from "../../mui/styles";

// The GlobalAlerts function renders the Global Alerts page with options based on user level.
export default function GlobalAlerts() {
  const history = useHistory();
  const { userLevel } = useAuth();

  return (
    <div>
      {/* Button to go back to the previous page */}
      <Button
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      {/* Display different titles based on user level */}
      {userLevel === 1 ? (
        <h1 style={pageTitleStyles}>Admin Alerts</h1>
      ) : (
        <h1 style={pageTitleStyles}>Structure Alerts</h1>
      )}
      {/* Link to create a new alert */}
      <Link to="/alerts/create">
        <Button style={{ marginRight: 30 }} variant="contained" color="primary">
          Create New Alert
        </Button>
      </Link>
      {/* Link to view or edit existing alerts */}
      <Link to="/alerts/view">
        <Button variant="contained" color="primary">
          View/Edit Alerts
        </Button>
      </Link>
    </div>
  );
}

import { supabase } from "../../../supabase";

export default function checkPhoneNumberIsUsed(number) {
  return new Promise(resolve => {
    supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealers")
      .select()
      .eq("phone", number)
      .then(result => {
        resolve(result.data.length > 0);
      })
      .catch(err => {
        console.error(err);
        // Return true if error for safety.
        resolve(true);
      });
  });
}

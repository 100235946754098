/**
 * Formats a ticket number by padding it with leading zeros and adding a prefix.
 *
 * @param {number|string} ticketNumber - The number to be formatted.
 * @param {string} prefix - The prefix to be added before the padded number.
 * @returns {string} - The formatted ticket number in the form of 'prefix-XXXXX' where XXXXX is the zero-padded number.
 *
 * @example
 * formatTicketNumber(1, 'ZSA'); // Returns 'ZSA-00001'
 * formatTicketNumber(42, 'ABC'); // Returns 'ABC-00042'
 */
export default function formatTicketNumber(ticketNumber, prefix) {
  const paddedNumber = String(ticketNumber).padStart(6, "0");

  return `${prefix}-${paddedNumber}`;
}

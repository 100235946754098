/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable object-shorthand */
import React, { useState, useEffect } from "react";
import { Container, Paper, IconButton, TextField } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Select from "react-select";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
// Import custom hooks and utilities
import { useCompany } from "../../contexts/CompanyContext";
import { supabase } from "../../supabase";
import sqlToStandardDate from "../shared/functions/sqlToStandardDate";
import UpdateFreezeWrapper from "./UpdateFreezeWrapper";

// Define custom styles using Material-UI's makeStyles
const useStyles = makeStyles(theme => ({
  grid: {
    border: 0,
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  textField: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: theme.spacing(1, 0.5, 1.5),
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(0.5),
    },
    "& .MuiInput-underline:before": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  overlay: {
    flexDirection: "column",
    "& .ant-empty-img-1": {
      fill: theme.palette.type === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.type === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.type === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.type === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.type === "light" ? "0.8" : "0.08",
      fill: theme.palette.type === "light" ? "#f5f5f5" : "#fff",
    },
  },
  label: {
    marginTop: theme.spacing(1),
  },
}));

// Component for search toolbar with clear functionality
function QuickSearchToolbar({ value, onChange, clearSearch }) {
  const classes = useStyles();
  const toolbarButtonStyle = {
    fontSize: ".875rem",
    padding: ".5rem .65rem",
    textTransform: "none",
  };

  return (
    <div className={classes.root}>
      <TextField
        variant="standard"
        value={value}
        style={{
          marginLeft: "1rem",
        }}
        onChange={onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={toolbarButtonStyle} />
        <GridToolbarFilterButton style={toolbarButtonStyle} />
        <GridToolbarDensitySelector style={toolbarButtonStyle} />
        <GridToolbarExport style={toolbarButtonStyle} />
      </GridToolbarContainer>
    </div>
  );
}

// PropTypes for QuickSearchToolbar component
QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

// Component for selecting new agents
function NewAgentSelector(props) {
  const { newly_assigned_agent: newlyAssignedAgent } = props.row;

  const [currentValue, setCurrentValue] = useState("");

  // Fetch agent name if exists
  const getAgentName = async () => {
    if (newlyAssignedAgent) {
      const currentValueArr = props.agents.filter(
        agent => newlyAssignedAgent === agent.value
      );
      if (currentValueArr[0]) {
        setCurrentValue(currentValueArr[0]);
      }
    }
  };

  // Fetch agent name on component update
  useEffect(() => {
    if (newlyAssignedAgent) getAgentName();
  }, [props.agents]);

  // Handle agent selection
  const handleSelect = async e => {
    props.setLoading(true);
    const { id, agent_identification_1: agentId } = props.row;

    setCurrentValue(e);

    const { error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("fibre_order_override")
      .upsert(
        [
          {
            ref_num: id,
            original_agent_value: agentId,
            newly_assigned_agent: e.value,
          },
        ],
        { onConflict: "ref_num" }
      )
      .select();

    if (error) {
      console.error(error.message);
    } else {
      props.refreshData();
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Select
        xs={12}
        required
        menuPortalTarget={document.body}
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        options={props.agents}
        defaultValue={currentValue}
        value={currentValue}
        onChange={e => {
          handleSelect(e);
        }}
        placeholder="Select New Agent"
      />
    </div>
  );
}

// Component for handling order override functionality
function OrderOverride() {
  // State variables initialization
  const [loading, setLoading] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [unmatchedOrders, setUnmatchedOrders] = useState([]);
  const [unmatchedOrdersStatic, setUnmatchedOrdersStatic] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [allOrdersStatic, setAllOrdersStatic] = useState([]);
  const [agents, setAgents] = useState([]);
  const [searchAllOrdersText, setSearchAllOrdersText] = useState("");
  const [searchUnmatchedOrdersText, setSearchUnmatchedOrdersText] =
    useState("");
  const { activeSuppliers } = useCompany();
  const [suppliersStatic, setSuppliersStatic] = useState([]);

  // Update active suppliers
  useEffect(() => {
    const supplierArray = [];
    activeSuppliers.forEach((supplier, index) => {
      supplierArray.push({
        value: supplier.internal_identifier,
        label: supplier.supplier,
        internalId: supplier.id,
      });
      if (index + 1 === activeSuppliers.length) {
        setSuppliersStatic(supplierArray);
      }
    });
  }, [activeSuppliers]);

  // Fetch data on supplier selection change
  const unmatchedColumns = () => [
    {
      field: "order_created_date_1",
      headerName: "Order Date",
      width: 200,
      renderCell: params => (
        <p>{sqlToStandardDate(params.row.order_created_date_1)}</p>
      ),
    },
    {
      field: "unique_premises_id_0",
      headerName: "Premises ID",
      width: 200,
    },
    { field: "order_number_1", headerName: "Ref Num", width: 200 },
    {
      field: "zawadi_agent_code_2",
      headerName: "Original Agent Code",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "agent_identification_1",
      headerName: "Original Agent Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "newly_assigned_agent",
      headerName: "Assigned Agent",
      minWidth: 200,
      flex: 1,
      renderCell: params => (
        <NewAgentSelector
          row={params.row}
          agents={agents}
          setLoading={setLoading}
          refreshData={() => {
            refreshData(false);
          }}
        />
      ),
    },
  ];

  const reAssignColumns = () => [
    {
      field: "order_created_date_1",
      headerName: "Order Date",
      width: 200,
      renderCell: params => (
        <p>{sqlToStandardDate(params.row.order_created_date_1)}</p>
      ),
    },
    {
      field: "unique_premises_id_0",
      headerName: "Premises ID",
      width: 200,
    },
    { field: "order_number_1", headerName: "Ref Num", width: 200 },
    {
      field: "zawadi_agent_code_2",
      headerName: "Original Agent Code",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "agent_identification_1",
      headerName: "Original Agent Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "newly_assigned_agent",
      headerName: "Assigned Agent",
      minWidth: 200,
      flex: 1,
      renderCell: params => (
        <NewAgentSelector
          row={params.row}
          agents={agents}
          setLoading={setLoading}
          refreshData={() => {
            refreshData(false);
          }}
        />
      ),
    },
  ];

  // Fetch data on supplier selection change
  useEffect(() => {
    refreshData(true);
  }, [selectedSupplier, suppliersStatic]);

  // Refresh data from backend
  const refreshData = async getAgents => {
    if (selectedSupplier) {
      setLoading(true);

      const { data: orderOverrideData, error: overrideError } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("order_override_view")
        .select(
          "id, order_created_date_1, unique_premises_id_0, order_number_1, zawadi_agent_code_2, agent_identification_1, newly_assigned_agent"
        )
        .eq("supplier_1", selectedSupplier.internalId);

      if (overrideError) {
        console.error(overrideError.message);
      } else {
        setAllOrders(orderOverrideData);
        setAllOrdersStatic(orderOverrideData);

        const unmatchedOrdersFiltered = orderOverrideData.filter(
          item =>
            !item.agent_identification_1.includes("Z-") ||
            !item.zawadi_agent_code_2 ||
            item.zawadi_agent_code_2 === "Not Found"
        );

        setUnmatchedOrders(unmatchedOrdersFiltered);
        setUnmatchedOrdersStatic(unmatchedOrdersFiltered);

        if (getAgents && selectedSupplier) {
          const { data: agentsByService, error: agentsError } = await supabase
            .schema(process.env.REACT_APP_SB_SCHEMA)
            .from("dealers")
            .select("id, dealer_code, contact_name, contact_surname")
            .contains("selected_suppliers", [selectedSupplier.internalId])
            .eq("status", 2);

          if (agentsError) {
            console.error(agentsError.message);
          } else {
            const agentsByServiceMap = agentsByService.map(agent => ({
              value: agent.id,
              label: `${agent.dealer_code}: ${agent.contact_name} ${agent.contact_surname}`,
            }));

            setAgents(agentsByServiceMap);
          }
        }
      }
      setLoading(false);
    }
  };

  // Function to escape regular expression special characters
  function escapeRegExp(string) {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  // Search all orders
  const requestAllDealersSearch = searchValue => {
    setSearchAllOrdersText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = allOrdersStatic.filter(row => {
      return Object.keys(row).some(field => {
        return searchRegex.test(row[field] && row[field].toString());
      });
    });
    setAllOrders(filteredRows);
  };

  // Search unmatched orders
  const requestUnmatchedDealersSearch = searchValue => {
    setSearchUnmatchedOrdersText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = unmatchedOrdersStatic.filter(row => {
      return Object.keys(row).some(field => {
        return searchRegex.test(row[field] && row[field].toString());
      });
    });
    setUnmatchedOrders(filteredRows);
  };

  return (
    <Container>
      <UpdateFreezeWrapper>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Re-Assign Orders:
          </Typography>
          <hr />
          <div style={styles.inputContainer}>
            <Typography style={{ marginBottom: 15 }}>
              Please select a supplier
            </Typography>
            <Select
              xs={12}
              required
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              options={suppliersStatic}
              defaultValue={undefined}
              onChange={e => {
                setSelectedSupplier(e);
              }}
              placeholder="Supplier"
            />
            <Typography variant="caption">
              * Please note that no rows will display before a supplier has been
              selected
            </Typography>
          </div>
          <hr />
          <Paper style={{ padding: 10 }}>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
              Unmatched Orders
            </Typography>

            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                pageSize={50}
                loading={loading}
                rows={unmatchedOrders}
                columns={unmatchedColumns()}
                components={{
                  Toolbar: QuickSearchToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    value: searchUnmatchedOrdersText,
                    onChange: event =>
                      requestUnmatchedDealersSearch(event.target.value),
                    clearSearch: () => requestUnmatchedDealersSearch(""),
                  },
                }}
              />
            </div>
          </Paper>
          <Paper style={{ padding: 10, marginTop: 20 }}>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
              Find Orders
            </Typography>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                pageSize={10}
                loading={loading}
                rows={allOrders}
                columns={reAssignColumns()}
                components={{
                  Toolbar: QuickSearchToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    value: searchAllOrdersText,
                    onChange: event =>
                      requestAllDealersSearch(event.target.value),
                    clearSearch: () => requestAllDealersSearch(""),
                  },
                }}
              />
            </div>
          </Paper>
        </Grid>
      </UpdateFreezeWrapper>
    </Container>
  );
}

// Export the OrderOverride component as default
export default OrderOverride;

// Additional styles
const styles = {
  inputContainer: {
    marginTop: 30,
    marginBottom: 30,
  },
};

import React from "react";
import { AuthProvider } from "../contexts/AuthContext";
import Wrapper from "./Wrapper";
import Loading from "./Loading";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Loading>
          <Wrapper />
        </Loading>
      </AuthProvider>
    </div>
  );
}

export default App;

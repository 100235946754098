import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useCompany } from "../../../contexts/CompanyContext";

export default function ProductsList({ supplier: supplierId, loading }) {
  const [supplier, setSupplier] = useState({});
  const { products: allProducts, getSupplier } = useCompany();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen size

  const products = allProducts.filter(
    product => product.supplier_id === supplierId
  );

  useEffect(() => {
    const fetchSupplier = async () => {
      setSupplier(await getSupplier(supplierId));
    };
    fetchSupplier();
  }, [supplierId, getSupplier]);

  return (
    <div>
      {loading && !supplier && !products ? (
        <p>Loading...</p>
      ) : (
        <>
          <div>
            {products.length === 0 && <p>No products added to this supplier</p>}
          </div>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            xs={12}
            spacing={1}
          >
            {products.map(product => (
              <Grid item xs={12} md={6} key={product.id}>
                <Link to={`/EditProduct/${product.id}`}>
                  <Card>
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 120,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={supplier.logo}
                            alt={supplier.logo}
                            width={supplier.logo_size}
                          />
                        </Grid>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: "bold",

                                overflow: isMobile ? "hidden" : "visible",
                                textOverflow: isMobile ? "ellipsis" : "unset",
                                whiteSpace: isMobile ? "nowrap" : "normal",
                                width: isMobile ? "180px" : "auto",
                              }}
                            >
                              {product.product}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color={
                                product.status === "Active"
                                  ? "primary"
                                  : "error"
                              }
                            >
                              {product.status}
                            </Typography>
                            <Typography>{product.customer_price}</Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
}

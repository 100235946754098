import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import nanoId from "nano-id";

export function AddSupplierLinkModal({
  restVar = {},
  addNewLink = () => {},
  onClose,
  supLink = {
    link_name: "",
    link_address: "",
    is_active: true,
    rest_var: [],
  },
  edit = false,
}) {
  // State defaults
  const defZimsVar = { q: "", var: "", is_static: false };

  // States
  const [formInput, setFormInput] = useState(supLink);
  const [zimsVar, setZimsVar] = useState(defZimsVar); // zimsVar refers to the array containing a query and zims variable

  // State set handlers
  const handleFormInput = e => {
    setFormInput(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleZimsVarSelect = event =>
    setZimsVar(prev => ({ ...prev, var: event.target.value }));

  const handleZimsVar = event =>
    setZimsVar(prev => ({ ...prev, q: event.target.value }));

  const handleZimsStatic = event =>
    setZimsVar(prev => ({ ...prev, var: event.target.value }));

  const toggleZimsVarStatic = () => {
    setZimsVar(prev => ({
      ...prev,
      var: defZimsVar.var,
      is_static: !prev.is_static,
    }));
  };

  // Add zimsVar to the formInput object
  const submitZimsVar = () => {
    setFormInput(prev => ({
      ...prev,
      rest_var: [...prev.rest_var, { ...zimsVar, id: nanoId() }],
    }));
    setZimsVar(defZimsVar);
  };

  // remove zimsVar from the formInput object
  const removeZimsVar = zimsVarId => {
    const newRestVar = formInput.rest_var.filter(item => item.id !== zimsVarId);
    setFormInput(prev => ({
      ...prev,
      rest_var: newRestVar,
    }));
  };

  // Mapping over formInput.rest_var to display and remove items from the list
  const formInputRestVarMap = formInput.rest_var.map(item => {
    const handleDelete = () => {
      removeZimsVar(item.id);
    };

    return (
      <Grid item xs={12} key={item.id}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography>
              <code>{`${item.q}=${item.var}`}</code>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button color="secondary" onClick={handleDelete}>
              <DeleteForeverIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  // Convert restVar to Array for MUI options
  const restVarToArr = () => {
    const retArr = [];
    for (const key in restVar) {
      if (key) {
        retArr.push({ value: key, label: key });
      }
    }
    return retArr;
  };

  const restVarArr = restVarToArr();

  // Submit to Supabase
  const addNewLinkHandler = () => {
    addNewLink(formInput);
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          {`${edit ? "Edit" : "Add"} Supplier Link`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="outlined-required"
          label="Name"
          variant="outlined"
          fullWidth
          margin="dense"
          name="link_name"
          onChange={handleFormInput}
          value={formInput.link_name}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="outlined-required"
          label="Link Address"
          variant="outlined"
          fullWidth
          helperText="e.g. https://app.zawadi.africa"
          margin="dense"
          name="link_address"
          onChange={handleFormInput}
          value={formInput.link_address}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {formInputRestVarMap}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={zimsVar.is_static}
                onChange={toggleZimsVarStatic}
              />
            }
            label="Static"
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              required
              id="outlined-required"
              label="Query"
              value={zimsVar.q}
              variant="outlined"
              fullWidth
              helperText="e.g. z-code"
              margin="dense"
              onChange={handleZimsVar}
            />
          </Grid>
          {zimsVar.is_static && (
            <Grid item md={6} xs={12}>
              <TextField
                required
                id="outlined-required"
                label="Static Value"
                value={zimsVar.var}
                variant="outlined"
                fullWidth
                helperText="e.g. zawadi"
                margin="dense"
                onChange={handleZimsStatic}
              />
            </Grid>
          )}
          {!zimsVar.is_static && (
            <Grid item md={6} xs={12}>
              <TextField
                required
                select
                value={zimsVar.var}
                onChange={handleZimsVarSelect}
                id="outlined-required"
                label="ZIMS Variable"
                variant="outlined"
                helperText="Select your ZIMS variable"
                fullWidth
                margin="dense"
              >
                {restVarArr.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              required
              color="primary"
              variant="contained"
              fullWidth
              onClick={submitZimsVar}
              disabled={zimsVar.q === "" || zimsVar.var === ""}
            >
              Add REST Query
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              required
              color="primary"
              variant="contained"
              fullWidth
              onClick={addNewLinkHandler}
            >
              {edit ? "Edit Link" : "Add Link"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              required
              color="secondary"
              variant="contained"
              fullWidth
              onClick={onClose}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddSupplierLinkModal;

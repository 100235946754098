import React from "react";
import { Box, Typography, Link } from "@material-ui/core";

function Copyright() {
  return (
    <Box mt={8} mb={3}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link
          className="sim-text-link"
          color="inherit"
          target="_blank"
          rel="noopener"
          href="https://wemanageyoursite.com/"
        >
          We Manage Your Site (Pty) Ltd.
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

export default Copyright;

/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  faArrowAltCircleRight,
  faEnvelope,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card, CardHeader } from "@mui/material";
import React from "react";
import moment from "moment";
import { truncateString } from "../../shared/constants";

export default function Notification({ notif, viewAlert }) {
  const Msg = `${notif.title ? notif.title : notif.description}`;

  const styles = {
    read: {
      fontWeight: "normal",
      cursor: "pointer",
    },
    unread: {
      fontWeight: "bold",
      cursor: "pointer",
    },
    readIcon: {
      cursor: "pointer",
    },
    unreadIcon: {
      color: "#fff",
      backgroundColor: "#007ad2",
      cursor: "pointer",
    },
    globalIcon: {
      color: "#fff",
      backgroundColor: "#ff0000",
      cursor: "pointer",
    },
    unreadCard: {
      width: 350,
      borderBottom: "3px solid #007ad2",
    },
    readCard: {
      width: 350,
    },
  };

  const truncTitle = truncateString(Msg, 40);

  return (
    <Card
      style={{ marginBottom: 20 }}
      className={`iso-notif-card iso-notif-card-${
        notif.read ? "read" : "unread"
      }`}
    >
      <CardHeader
        avatar={
          <Avatar
            style={
              notif.active !== undefined
                ? styles.globalIcon
                : notif.read
                ? styles.readIcon
                : styles.unreadIcon
            }
          >
            <FontAwesomeIcon
              icon={
                notif.active !== undefined
                  ? faEnvelopeOpen
                  : notif.read
                  ? faEnvelopeOpen
                  : faEnvelope
              }
            />
          </Avatar>
        }
        title={
          <span style={styles.read}>
            {truncTitle[0] === "<" ? (
              <div dangerouslySetInnerHTML={{ __html: truncTitle }} />
            ) : (
              truncTitle
            )}
          </span>
        }
        subheader={moment(notif.timestamp).fromNow()}
        action={
          <div>
            <div
              style={{ cursor: "pointer", textAlign: "right" }}
              onClick={() => {
                viewAlert();
              }}
              className="iso-right"
            >
              <span style={{ color: "#96b4d4" }}>
                View &nbsp;
                <FontAwesomeIcon color="#96b4d4" icon={faArrowAltCircleRight} />
              </span>
            </div>

            <br />
            <br />
            <span
              className="iso-small iso-right"
              style={{ color: "#007ad2", cursor: "pointer" }}
            >
              {notif.active !== undefined
                ? ""
                : notif.read
                ? "Mark as unread"
                : "Mark as read"}
            </span>
          </div>
        }
      />
    </Card>
  );
}

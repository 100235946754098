import { supabase } from "../../../supabase";

export default function checkIdNumberIsUsed(idNumber) {
  return new Promise(resolve => {
    const trimmedId = idNumber.trim();

    const idNumberOne = trimmedId;
    const idNumberTwo = trimmedId.toLowerCase();
    const idNumberThree = `${trimmedId[0]}${trimmedId.slice(1).toLowerCase()}`;
    const idNumberFour = trimmedId.toUpperCase();

    supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealers")
      .select()
      .in("contact_id_no", [
        idNumberOne,
        idNumberTwo,
        idNumberThree,
        idNumberFour,
      ])
      .then(result => {
        resolve(result.data.length > 0);
      })
      .catch(err => {
        console.error(err);
        // Return true if error for safety.
        resolve(true);
      });
  });
}

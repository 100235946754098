/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";

const PendingAccount = () => {
  const { currentUser } = useAuth();
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Determine what to show.
    if (currentUser.status === 1) {
      // Message for when the user.status is 'pending'
      setMessage("Your account is awaiting approval.");
    } else if (currentUser.status === 2) {
      setMessage(
        "Your account is active but you have outstanding actions to be taken."
      );
    } else if (currentUser.status === 3) {
      // Message for when the status is 'leaver'
      setMessage(
        "Your account has been deactivated. Please contact support for further assistance."
      );
    } else if (currentUser.status === 4) {
      // Default message for deleted status
      setMessage("Your account has been deleted. Please contact support.");
    } else if (currentUser.status === 5) {
      // Default message for rejected status
      setMessage(
        "Your account application has been rejected. Please contact support."
      );
    } else {
      // Default message for unknown status
      setMessage("Your account is currently inactive. Please contact support.");
    }
  }, [currentUser]);

  // User status 1 or 2 can show the profile.

  // Initialize a variable to hold the message to be displayed

  // Do profile page here...

  return (
    <div>
      <div
        style={{
          ...styles.container,
          backgroundColor: currentUser.status === 2 ? "#4caf50" : "#f8d7da",
          border: currentUser.status === 2 ? null : "1px solid #f5c6cb",
        }}
      >
        <p
          style={{
            ...styles.message,
            color: currentUser.status === 2 ? "#FFFFFF" : null,
          }}
        >
          {message}
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    textAlign: "center",
    color: "#721c24",
    borderRadius: "5px",
    marginTop: "10px",
  },
  buttonContainer: {
    marginTop: "20px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  message: {
    fontSize: "16px",
    fontWeight: "bold",
  },
};

export default PendingAccount;

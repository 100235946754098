import moment from "moment";
import Papa from "papaparse";
import useSupabaseFileUpload from "../../../../hooks/useSupabaseFileUpload";
import { handleCommissionsImport } from "../../../../api";

/**
 * Handles the import of agent data.
 *
 * @param {Array} array - The array of data to be imported.
 * @returns {Promise} - A promise that resolves to the result of the import.
 */
async function handleAgentImport(array) {
  const unparsed = Papa.unparse(array);
  const blob = new Blob([unparsed], { type: "text/csv" });

  const string = `agent_commissions_${moment().format("YYYYMMDDHHmmss")}`; // filename

  const upload = await useSupabaseFileUpload(
    string,
    blob,
    "CommissionsDatestamps" // bucket name
  ).then(async res => {
    if (!res.error) {
      // trigger import functionality on the server (worker)
      const response = await handleCommissionsImport({
        type: "Agent",
        filePath: string,
      });
      if (response.status !== 200) {
        // eslint-disable-next-line
        alert("Error Uploading File");
        throw new Error("Error Uploading File");
      }
      // eslint-disable-next-line no-alert
      alert("File uploaded successfully");
    } else {
      // eslint-disable-next-line no-alert
      alert("Error uploading file");
    }
  });
  const result = upload;
  return result;
}

/**
 * Handles the import of leader commissions data.
 *
 * @param {Array} array - The array of data to be imported.
 * @returns {Promise} - A promise that resolves to the result of the import.
 */
async function handleLeaderImport(array) {
  const unparsed = Papa.unparse(array);
  const blob = new Blob([unparsed], { type: "text/csv" });

  const string = `leader_commissions_${moment().format("YYYYMMDDHHmmss")}`; // filename

  const upload = await useSupabaseFileUpload(
    string,
    blob,
    "CommissionsDatestamps" // bucket name
  ).then(async res => {
    if (!res.error) {
      // trigger the import functionality on the server (worker)
      const response = await handleCommissionsImport({
        type: "Leader",
        filePath: string,
      });
      if (response.status !== 200) {
        // eslint-disable-next-line
        alert("Error Uploading File");
        throw new Error("Error Uploading File");
      }
      // eslint-disable-next-line no-alert
      alert("File uploaded successfully");
    } else {
      // eslint-disable-next-line no-alert
      alert("Error uploading file");
    }
  });
  const result = upload;
  return result;
}

export { handleAgentImport, handleLeaderImport };

function formatPhoneNumber(number) {
  let formattedPhoneNumber = number;

  formattedPhoneNumber = formattedPhoneNumber.replace(/ /g, "");

  if (formattedPhoneNumber.startsWith("0")) {
    formattedPhoneNumber = `+27${formattedPhoneNumber.slice(1, 10)}`;
  }

  if (formattedPhoneNumber.startsWith("+270")) {
    formattedPhoneNumber = `+27${formattedPhoneNumber.slice(4, 13)}`;
  }

  if (formattedPhoneNumber.length === 9) {
    formattedPhoneNumber = `+27${formattedPhoneNumber}`;
  }

  return formattedPhoneNumber;
}

module.exports = formatPhoneNumber;

import { supabase } from "../../../supabase";

/**
 * Given an authId, returns the user document from the dealers table.
 *
 * @param {string} authId - the authId to search for
 * @returns {Promise<{user: any, error: any}>} - a promise resolving with an object containing the user document and any error that occurred
 */
export default async function userFromAuth(authId) {
  const { data, error } = await supabase
    .schema(process.env.REACT_APP_SB_SCHEMA)
    .from("dealers")
    .select("*")
    .eq("auth_id", authId);

  if (error) {
    console.error(error);
  }

  return {
    user: data ? data[0] : {},
    error,
  };
}

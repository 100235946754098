import React, { useState, useEffect } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Alert } from "@material-ui/lab";
import moment from "moment/moment";
import { supabase } from "../../../supabase";
import { useCompany } from "../../../contexts/CompanyContext";
import { useAuth } from "../../../contexts/AuthContext";
import { pageTitleStyles } from "../../../mui/styles";
import {
  agentAssistCategories,
  agentAssistStatuses,
  agentAssistInProgressStatuses,
  agentAssistClosedStatuses,
} from "../../shared/constants";

import ConfirmationModal from "../../shared/ConfirmationModal";

import sendAssistMessage from "../../shared/functions/sendAssistMessage.function";
import sendPersonalAlert from "../../shared/functions/sendPersonalAlert.function";
import userFromAuth from "../../shared/functions/userFromAuth.function";
import formatTicketNumber from "../../shared/functions/formatTicketNumber";

/**
 * This component is used to edit a sales lead. It fetches the sales lead data
 * from the database and displays it in a form. The form can be edited and then
 * submitted to update the sales lead. It also includes a button to delete the
 * sales lead.
 *
 * @param {Object} props - The props object should contain the following:
 *  - leadId: The id of the sales lead to be edited
 *  - onClose: A function to be called when the modal is closed
 * @returns {JSX.Element} - The JSX element to be rendered
 */
function AgentAssistEditTicketModal({ leadId, onClose }) {
  // Default form values
  const defFormValues = {
    category: "",
    admin_note: "",
    status: "",
    supplier: "",
    last_updated_at: "",
    last_updated_by_pretty: "",
    last_updated_by_dealers_id: "",
    ticket_in_progress_status: "",
    ticket_closed_status: "",
    supplier_ticket_number: "",
    supplier_ticket_date: "",
    history: [],
  };

  // Custom hooks
  const { activeSuppliers } = useCompany();
  const { currentUser } = useAuth();

  // Hooks for managing state
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const [formValues, setFormValues] = useState(defFormValues);
  const [confirmModal, setConfirmModal] = useState(false);
  const [ticketUser, setTicketUser] = useState({});

  // Event handlers
  const handleChangeSuppliers = event => {
    setDisable(false);
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      supplier: event.target.value,
    }));
  };

  const handleError = message => {
    setErrorMessage(message);
    setIsSnackbarOpen(true);
  };

  // Other event handlers (handleCategorySelect, handleStatusSelect, etc.)
  const handleCategorySelect = event =>
    setFormValues(prev => ({ ...prev, category: event.target.value }));

  const handleStatusSelect = event =>
    setFormValues(prev => ({ ...prev, status: event.target.value }));

  const handleInProgressStatusSelect = event =>
    setFormValues(prev => ({
      ...prev,
      ticket_in_progress_status: event.target.value,
    }));

  const handleClosedStatusSelect = event =>
    setFormValues(prev => ({
      ...prev,
      ticket_closed_status: event.target.value,
    }));

  const handleSupplierDateSelect = selectedDate => {
    setFormValues(prev => ({
      ...prev,
      supplier_ticket_date: selectedDate.toISOString().slice(0, 10),
    }));
  };

  const handleChange = event => {
    setDisable(false);
    const { name, value } = event.target;

    if (name === "agreedToTerms") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.agreedToTerms,
      }));
    } else {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  /**
   * Handle the edit lead event.
   *
   * This function is called when the user clicks on the "Edit Lead"
   * button. It will update the sales assist table with the new values
   * from the form. It will also add a new entry to the history array
   * with the current timestamp and the updated values.
   *
   * If the supplier is "all", it will throw an error.
   *
   * @function
   * @returns {void}
   */
  const handleEditTicket = async () => {
    if (formValues.supplier !== "all") {
      try {
        setDisable(true);
        const updatedFormValues = {
          ...formValues,

          // Adding the history for the sales lead
          history: [
            ...formValues.history,
            {
              updated_at: moment().format("YYYY/MM/DD HH:mm:ss"),
              updated_by_dealers_id: currentUser.id,
              updated_by_pretty: `${currentUser.dealer_code}: ${currentUser.contact_name}`,
              update_status: formValues.status,
              update_in_progress_status: formValues.ticket_in_progress_status,
              update_closed_status: formValues.ticket_closed_status,
              update_feedback: formValues.admin_note,
            },
          ],
        };

        const { error } = await supabase
          .schema(process.env.REACT_APP_SB_SCHEMA)
          .from("agent_assist")
          .update(updatedFormValues)
          .eq("id", formValues.id)
          .select();

        // Update message composition
        let updateMessage = `
          Updated By: ${currentUser.dealer_code}: ${currentUser.contact_name}
          Status: ${formValues.status}
          `;

        if (formValues.ticket_in_progress_status) {
          updateMessage += `In Progress Status: ${formValues.ticket_in_progress_status}\n`;
        }

        if (formValues.ticket_closed_status) {
          updateMessage += `Closed Status: ${formValues.ticket_closed_status}\n`;
        }

        // send message on sales assist activity
        sendAssistMessage(
          formValues.id,
          updateMessage,
          "update",
          "agent_assist_activity"
        );

        // send an alert to the user to check sales assist
        if (ticketUser.id) {
          sendPersonalAlert(
            await ticketUser.id,
            `Ticket Update - ${formatTicketNumber(formValues.id, "ZAA")}`,
            "<p>Your ticket received an update. Please view in Agent Assist</p>"
          );
        }

        if (error) console.error(error.message);
        onClose();

        setFormValues(defFormValues);
      } catch (err) {
        handleError(err);
      } finally {
        setDisable(false);
      }
    } else {
      handleError("All is not a Supplier please select one!");
      setDisable(true);
    }
  };

  /**
   * Delete a sales assist lead.
   * @async
   * @function
   * @param {object} event - The event object.
   * @returns {Promise<void>}
   */
  const handleDeleteLead = async () => {
    try {
      setDisable(true);
      const { error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("agent_assist")
        .update({ is_active: false })
        .eq("id", formValues.id)
        .select();

      if (error) console.error(error.message);
      setFormValues(defFormValues);
      onClose();
    } catch (err) {
      handleError(err);
    } finally {
      setDisable(false);
    }
  };

  // Fetch call to get data
  const getData = async () => {
    setLoading(true);

    const { data: salesAssistData, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("agent_assist")
      .select("*")
      .eq("id", leadId);

    if (error) console.error(error);

    const result = await salesAssistData[0];

    const updatedFormValues = {
      ...formValues,
      ...(await result),
      last_updated_by_dealers_id: currentUser.id,
      last_updated_by_pretty: `${currentUser.dealer_code}: ${currentUser.contact_name} ${currentUser.contact_surname}`,
      last_updated_at: moment().format("YYYY-MM-DD"),
      history: result.history || [], // Initialize history as an empty array if it's undefined
    };
    setFormValues(updatedFormValues);

    setLoading(false);
  };

  // use global function
  const getUser = async () => {
    const userFromId = await userFromAuth(formValues.auth_id);
    setTicketUser(await userFromId.user);
  };

  // useEffect to fetch data
  useEffect(() => {
    getData(leadId);
  }, []);

  useEffect(() => {
    if (formValues.auth_id) getUser();
  }, [formValues.auth_id]);

  // Return JSX elements
  return (
    <div>
      <Typography variant="h5" style={pageTitleStyles}>
        Edit Lead
      </Typography>
      <Grid container xs={12} spacing={2}>
        {loading ? (
          <Grid item>
            <Typography>Loading...</Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ height: 60 }}
                required
              >
                <InputLabel
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Select a Supplier
                </InputLabel>
                <Select
                  label="Select a Supplier"
                  value={formValues.supplier}
                  onChange={handleChangeSuppliers}
                  style={{
                    height: 60,
                    marginTop: -2,
                    fontSize: "12px",
                  }}
                >
                  {activeSuppliers.map(supplier => {
                    return (
                      <MenuItem value={supplier.id} key={supplier.id}>
                        {supplier.supplier}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                value={formValues.category}
                onChange={handleCategorySelect}
                id="outlined-required"
                label="Category"
                variant="outlined"
                fullWidth
              >
                {agentAssistCategories[
                  agentAssistCategories[formValues.supplier]
                    ? formValues.supplier
                    : "def"
                ].map(category => (
                  <MenuItem key={category.key} value={category.value}>
                    {category.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                value={formValues.status}
                onChange={handleStatusSelect}
                id="outlined-required"
                label="Status"
                variant="outlined"
                fullWidth
              >
                {agentAssistStatuses.map(status => (
                  <MenuItem key={status.key} value={status.value}>
                    {status.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {formValues.status === "In Progress" && (
              <Grid item xs={12}>
                <TextField
                  select
                  value={formValues.ticket_in_progress_status}
                  onChange={handleInProgressStatusSelect}
                  id="outlined-required"
                  label="In Progress Status"
                  variant="outlined"
                  fullWidth
                >
                  {agentAssistInProgressStatuses[
                    agentAssistInProgressStatuses[formValues.supplier]
                      ? formValues.supplier
                      : "def"
                  ].map(status => (
                    <MenuItem key={status.key} value={status.value}>
                      {status.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {formValues.status === "Closed" && (
              <Grid item xs={12}>
                <TextField
                  select
                  value={formValues.ticket_closed_status}
                  onChange={handleClosedStatusSelect}
                  id="outlined-required"
                  label="Closed Status"
                  variant="outlined"
                  fullWidth
                >
                  {agentAssistClosedStatuses[
                    agentAssistClosedStatuses[formValues.supplier]
                      ? formValues.supplier
                      : "def"
                  ].map(status => (
                    <MenuItem key={status.key} value={status.value}>
                      {status.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  fullWidth
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date when the ticket was logged with supplier"
                  value={formValues.supplier_ticket_date}
                  onChange={handleSupplierDateSelect}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Supplier Ticket Number"
                variant="outlined"
                required
                name="supplier_ticket_number"
                value={formValues.supplier_ticket_number}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Helpdesk Feedback"
                variant="outlined"
                required
                name="admin_note"
                value={formValues.admin_note}
                onChange={handleChange}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleEditTicket}
                disabled={disable || loading}
              >
                Submit
              </Button>{" "}
            </Grid>{" "}
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                style={{ backgroundColor: "#c70000", color: "ButtonFace" }}
                onClick={() => setConfirmModal(true)}
                disabled={disable || loading}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={4000}
                onClose={() => setIsSnackbarOpen(false)}
              >
                <Alert
                  severity="error"
                  onClose={() => setIsSnackbarOpen(false)}
                >
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </>
        )}
      </Grid>

      <ConfirmationModal
        onConfirm={handleDeleteLead}
        onClose={() => setConfirmModal(false)}
        open={confirmModal}
      />
    </div>
  );
}

export default AgentAssistEditTicketModal;

const pageTitleStyles = {
  userSelect: "none",
  borderBottom: "1px solid #eee",
  paddingBottom: "1rem",
  marginBottom: "1.75rem",
};

const subtitleStyles = {
  borderBottom: "1px solid #eee",
  paddingBottom: "1rem",
  marginBottom: "1.75rem",
};

export { pageTitleStyles, subtitleStyles };

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Button,
  IconButton,
  TextField,
} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import { supabase } from "../../supabase";
import sqlToStandardDate from "../shared/functions/sqlToStandardDate";

// Styles

const useStyles = makeStyles(theme => ({
  grid: {
    border: 0,
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  textField: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: theme.spacing(1, 0.5, 1.5),
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(0.5),
    },
    "& .MuiInput-underline:before": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  overlay: {
    flexDirection: "column",
    "& .ant-empty-img-1": {
      fill: theme.palette.type === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.type === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.type === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.type === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.type === "light" ? "0.8" : "0.08",
      fill: theme.palette.type === "light" ? "#f5f5f5" : "#fff",
    },
  },
  label: {
    marginTop: theme.spacing(1),
  },
}));

function QuickSearchToolbar({ value, onChange, clearSearch }) {
  const classes = useStyles();
  const toolbarButtonStyle = {
    fontSize: ".875rem",
    padding: ".5rem .65rem",
    textTransform: "none",
  };

  return (
    <div className={classes.root}>
      <TextField
        variant="standard"
        value={value}
        style={{
          marginLeft: "1rem",
        }}
        onChange={onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={toolbarButtonStyle} />
        <GridToolbarFilterButton style={toolbarButtonStyle} />
        <GridToolbarDensitySelector style={toolbarButtonStyle} />
        <GridToolbarExport style={toolbarButtonStyle} />
      </GridToolbarContainer>
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

function EditSalesNote() {
  const [loading, setLoading] = useState(true);
  const [allOrders, setAllOrders] = useState([]);
  const [allOrdersStatic, setAllOrdersStatic] = useState([]);
  const [searchAllOrdersText, setSearchAllOrdersText] = useState("");
  const [textValue, setTextValue] = useState("");
  const [rowValue, setRowValue] = useState("");
  const [showEditNote, setShowEditNote] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [column, setColumn] = useState("");

  const showEditNoteDialog = (textValueLocal, row, columnLocal) => {
    setColumn(columnLocal);
    setTextValue(textValueLocal);
    setRowValue(row);
    setShowEditNote(true);
  };

  const handleSave = async () => {
    setIsLoading(true);

    const { error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("agent_order_claim")
      .update({ [column]: textValue })
      .eq("id", rowValue.id)
      .select();

    if (error) console.error(error);

    setColumn("");
    setTextValue("");
    setRowValue(null);
    setShowEditNote(false);
    setIsLoading(false);
    getAllLogs();
  };

  const salesColumns = () => [
    {
      field: "dealer_code",
      headerName: "Agent Code",
      width: 200,
    },
    {
      field: "ref_number",
      headerName: "Reference Number",
      width: 200,
      renderCell: params => (
        <div
          onClick={() => {
            showEditNoteDialog(params.row.ref_number, params.row, "ref_number");
          }}
        >
          <p style={{ cursor: "pointer", textDecoration: "underline" }}>
            {params.row.ref_number}
          </p>
        </div>
      ),
    },
    {
      field: "supplier",
      headerName: "Supplier",
      width: 200,
    },
    {
      field: "agent_note",
      headerName: "Agent Note",
      minWidth: 200,
      flex: 1,
      renderCell: params => (
        <div
          onClick={() => {
            showEditNoteDialog(params.row.agent_note, params.row, "agent_note");
          }}
        >
          <p style={{ cursor: "pointer", textDecoration: "underline" }}>
            {params.row.agent_note || "Add Note"}
          </p>
        </div>
      ),
    },
    {
      field: "claim_timestamp",
      headerName: "Log Timestamp",
      minWidth: 200,
      flex: 1,
      renderCell: params => (
        <p>{sqlToStandardDate(params.row.claim_timestamp, true)}</p>
      ),
    },
  ];

  const getAllLogs = async () => {
    setLoading(true);

    const { data: agentOrderClaimData, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("agent_order_claim")
      .select(
        `
        *,
           dealers(dealer_code), suppliers(supplier)
          `
      )
      .order("claim_timestamp", { ascending: false });

    if (error) console.error(error);

    if (agentOrderClaimData[0]) {
      const flattenedOrders = agentOrderClaimData.map(item => ({
        id: item.id,
        agent_code: item.agent_code,
        ref_number: item.ref_number,
        supplier: item.suppliers?.supplier,
        claim_timestamp: item.claim_timestamp,
        agent_note: item.agent_note,
        deleted: item.deleted,
        auth_id: item.auth_id,
        agent_id: item.agent_id,
        supplier_id: item.supplier_id,
        dealer_code: item.dealers?.dealer_code,
      }));
      setAllOrders(flattenedOrders);
      setAllOrdersStatic(flattenedOrders);
      setLoading(false);
    }

    return agentOrderClaimData;
  };

  useEffect(() => {
    getAllLogs();
  }, []);

  function escapeRegExp(string) {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  const requestAllOrdersSearch = searchValue => {
    setSearchAllOrdersText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = allOrdersStatic.filter(row => {
      return Object.keys(row).some(field => {
        return searchRegex.test(row[field] && row[field].toString());
      });
    });
    setAllOrders(filteredRows);
  };

  return (
    <Container>
      <Grid item xs={12} md={6}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Edit Sales Note:
        </Typography>
        <hr />
        <Paper style={{ padding: 10 }}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Logged Sales
          </Typography>
          {/* Search */}
          {/* Table */}
          <div style={{ height: 600, width: "100%" }}>
            <DataGrid
              getRowId={row => row.ref_number}
              pageSize={50}
              loading={loading}
              rows={allOrders}
              columns={salesColumns()}
              components={{
                Toolbar: QuickSearchToolbar,
              }}
              componentsProps={{
                toolbar: {
                  value: searchAllOrdersText,
                  onChange: event => requestAllOrdersSearch(event.target.value),
                  clearSearch: () => requestAllOrdersSearch(""),
                },
              }}
            />
          </div>
        </Paper>
      </Grid>
      <Dialog
        open={showEditNote}
        width="md"
        fullWidth
        onClose={() => {
          setShowEditNote(false);
        }}
      >
        <DialogContent>
          <TextField
            label="Enter Text"
            variant="outlined"
            fullWidth
            value={textValue}
            onChange={e => {
              setTextValue(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <div>
              <Button
                onClick={() => {
                  setShowEditNote(false);
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary">
                Save
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default EditSalesNote;

import React, { useContext, useState, useEffect, useMemo } from "react";
import { supabase } from "../supabase";

// Create a context to hold company-related data
const CompanyContext = React.createContext();

// Custom hook to access the CompanyContext easily from any component
export function useCompany() {
  return useContext(CompanyContext);
}

// Provider component that wraps around any component tree that needs access to company data
export function CompanyProvider({ children }) {
  // State variables to hold various data sets for the company
  const [productTypes, setProductTypes] = useState([]);
  const [activeProductTypes, setActiveProductTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [activeSuppliers, setActiveSuppliers] = useState([]);
  const [products, setProducts] = useState([]);
  const [dealerLevels, setDealerLevels] = useState([]);
  const [lifecycleStatuses, setLifecycleStatuses] = useState([]);
  const [updateFreeze, setUpdateFreeze] = useState(true);

  // Business logic for calculating product-related values
  const productBusinessLogic = (product, productType, supplier) => {
    const roundTo = (number, round) => {
      return Math.round(number / round) * round;
    };

    // Various calculations based on productType and supplier details
    const baseline = 1 - productType.weight_impact;
    const weightCalc = 3 / productType.weight_impact;
    const agentCut = 1 - productType.dealer_cut;
    const ogrWeight = product.ogr / productType.max_ogr;

    // Weighting and revenue calculations
    const weight =
      Math.round(
        ((ogrWeight +
          supplier.weighting_opportunity +
          supplier.weighting_customer_service) /
          weightCalc +
          baseline) *
          100
      ) / 100;

    const ogrExVat =
      Math.round((product.customer_price / 1.15) * product.ogr * 100) / 100;
    const onceOffWeighted = Math.round(product.commission * weight * 100) / 100;
    const revenuePoints = Math.round(onceOffWeighted + 3 * ogrExVat);

    // Returning the product with additional calculated values
    return {
      ...product,
      ogr_ex_vat: ogrExVat,
      once_off_weighted: onceOffWeighted,
      weight,
      revenue_points: revenuePoints,
      agent_t1_com_rate: roundTo(
        onceOffWeighted * productType.agent_split_t1 * agentCut,
        25
      ),
      agent_t2_com_rate: roundTo(
        onceOffWeighted * productType.agent_split_t2 * agentCut,
        25
      ),
      agent_t3_com_rate: roundTo(
        onceOffWeighted * productType.agent_split_t3 * agentCut,
        25
      ),
      agent_t4_com_rate: roundTo(
        onceOffWeighted * productType.agent_split_t4 * agentCut,
        25
      ),
      dealer_tl_t1_com_rate: roundTo(
        onceOffWeighted *
          productType.dealer_cut *
          productType.dealer_split_tl_t1,
        5
      ),
      dealer_tl_t2_com_rate: roundTo(
        onceOffWeighted *
          productType.dealer_cut *
          productType.dealer_split_tl_t2,
        5
      ),
      dealer_sm_com_rate: roundTo(
        onceOffWeighted * productType.dealer_cut * productType.dealer_split_sm,
        5
      ),
      dealer_d_com_rate: roundTo(
        onceOffWeighted * productType.dealer_cut * productType.dealer_split_d,
        5
      ),
      dealer_sd_com_rate: roundTo(
        onceOffWeighted * productType.dealer_cut * productType.dealer_split_sd,
        5
      ),
    };
  };

  // Function to fetch product types from the database and set state
  const fetchProductTypes = async () => {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("product_types")
      .select("*")
      .order("name");

    if (error) {
      throw error;
    } else {
      setProductTypes(data);
      setActiveProductTypes(data.filter(type => type.is_active === true));
    }
  };

  // Function to upsert (insert or update) a product type and re-calculate products
  const upsertProductType = async productType => {
    await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("product_types")
      .upsert(productType);

    // Filter products to update based on product type and status
    const productsToUpdate = products.filter(
      product =>
        product.product_type === productType.id && product.status !== "new"
    );

    const uploadProducts = [];

    // Recalculate product details based on updated product type
    productsToUpdate.forEach(product => {
      const supplier = suppliers.filter(
        filteredSupplier => filteredSupplier.id === product.supplier_id
      )[0];
      uploadProducts.push(productBusinessLogic(product, productType, supplier));
    });

    // Upsert updated products to the database
    await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("products")
      .upsert(uploadProducts);

    // Re-fetch products and product types to refresh data
    fetchProducts();
    fetchProductTypes();
  };

  // Function to fetch products from the database and set state
  const fetchProducts = async () => {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("products")
      .select("*")
      .order("status", { ascending: false })
      .order("product");

    if (error) {
      throw error;
    } else setProducts(data);
  };

  // Fetch a specific product by its ID
  const getProduct = async id => {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("products")
      .select("*")
      .eq("id", id);
    if (error) {
      throw error;
    } else return data[0];
  };

  // Upsert a product and re-calculate its business logic
  const upsertProduct = async product => {
    const supplier = suppliers.filter(
      filteredSupplier => filteredSupplier.id === product.supplier_id
    )[0];

    const productType = productTypes.filter(
      filteredProductType => filteredProductType.id === product.product_type
    )[0];

    await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("products")
      .upsert(productBusinessLogic(product, productType, supplier));
    fetchProducts();
  };

  // Function to fetch suppliers and set state
  const fetchSuppliers = async () => {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("suppliers")
      .select("*")
      .order("supplier_status")
      .order("supplier");
    if (error) {
      throw error;
    } else {
      setSuppliers(data);
      setActiveSuppliers(
        data.filter(
          supplier =>
            supplier.supplier_status === "Active" ||
            supplier.supplier_status === "Testing"
        )
      );
    }
  };

  // Fetch a specific supplier by ID
  const getSupplier = async id => {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("suppliers")
      .select("*")
      .eq("id", id);
    if (error) {
      throw error;
    } else return data[0];
  };

  // Upsert a supplier and re-calculate associated products
  const upsertSupplier = async supplier => {
    await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("suppliers")
      .upsert(supplier);

    const productsToUpdate = products.filter(
      product => product.supplier_id === supplier.id && product.status !== "new"
    );

    const uploadProducts = [];

    // Recalculate product details based on updated supplier
    productsToUpdate.forEach(product => {
      const productType = productTypes.filter(
        filteredProductType => filteredProductType.id === product.product_type
      )[0];
      uploadProducts.push(productBusinessLogic(product, productType, supplier));
    });

    // Upsert updated products to the database
    await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("products")
      .upsert(uploadProducts);

    fetchProducts();
    fetchSuppliers();
  };

  // Function to fetch dealer levels and set state
  const fetchDealerLevels = async () => {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealer_levels")
      .select("*")
      .order("level");

    if (error) {
      throw error;
    } else {
      setDealerLevels(data);
    }
  };

  // Upsert a dealer level and refresh dealer levels data
  const upsertDealerLevel = async dealerLevel => {
    await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealer_levels")
      .upsert(dealerLevel);

    fetchDealerLevels();
  };

  // Function to fetch lifecycle statuses of dealers
  const fetchLifecycleStatuses = async () => {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealer_lifecycle_statuses")
      .select("*");

    if (error) {
      throw error;
    } else {
      setLifecycleStatuses(data);
    }
  };

  // Fetch settings from the database and set state accordingly
  async function fetchSettings() {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("settings")
      .select("*");

    if (error) {
      console.error(error.message);
    } else if (data[0]) {
      // Extract updateFreeze setting and update state
      const updateFreezeSettings = data.filter(
        set => set.name === "updateFreeze"
      )[0].settings;

      const { updateFreeze: updateFreezeSB } = updateFreezeSettings;

      setUpdateFreeze(updateFreezeSB);
    }
  }

  // Toggle the updateFreeze state and update it in the database
  async function toggleFreeze() {
    const { error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("settings")
      .update({ settings: { updateFreeze: !updateFreeze } })
      .eq("id", 1);

    if (error) console.error(error.message);
  }

  // Effect hook to fetch data when the component is mounted and subscribe to database changes
  useEffect(() => {
    const unsubscribe = () => {
      fetchProductTypes();
      fetchProducts();
      fetchSuppliers();
      fetchDealerLevels();
      fetchLifecycleStatuses();
      fetchSettings();

      // Subscribe to changes in the "products" table and re-fetch products
      supabase
        .channel("products")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "products" },
          () => {
            fetchProducts();
          }
        )
        .subscribe();

      // Subscribe to changes in the "suppliers" table and re-fetch suppliers
      supabase
        .channel("suppliers")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "suppliers" },
          () => {
            fetchSuppliers();
          }
        )
        .subscribe();

      // Subscribe to changes in the "product_types" table and re-fetch product types
      supabase
        .channel("product_types")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "product_types" },
          () => {
            fetchProductTypes();
          }
        )
        .subscribe();

      // Subscribe to changes in the "settings" table and re-fetch settings
      supabase
        .channel("settings")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: process.env.REACT_APP_SB_SCHEMA,
            table: "settings",
          },
          () => {
            fetchSettings();
          }
        )
        .subscribe();
    };

    return unsubscribe();
  }, []);

  // Memoize the value to avoid unnecessary re-renders
  const value = {
    productTypes,
    activeProductTypes,
    suppliers,
    activeSuppliers,
    products,
    dealerLevels,
    upsertProductType,
    getSupplier,
    upsertSupplier,
    getProduct,
    upsertProduct,
    upsertDealerLevel,
    lifecycleStatuses,
    updateFreeze,
    toggleFreeze,
  };

  const memoizedValue = useMemo(() => value, [value]);

  // Provide the company data to the children components
  return (
    <CompanyContext.Provider value={memoizedValue}>
      {children}
    </CompanyContext.Provider>
  );
}

import React, { useState, useEffect } from "react";
import { Grid, Typography, AppBar, Tabs, Tab, Box } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";

import { useTheme } from "@material-ui/core/styles";

import nanoId from "nano-id";

import { supabase } from "../../../supabase";
import { useCompany } from "../../../contexts/CompanyContext";

import SBFileUploader from "../../shared/SBFileUploader";
import ModalCard from "../../shared/ModalCard";

import AgentAssistDetails from "./agent-assist-view-tickets/AgentAssistDetails.component";
import AssistDocuments from "../../shared/assist-ticket-components/AssistDocuments.component";

import AgentAssistActivity from "./agent-assist-view-tickets/AgentAssistActivity.component";

/**
 * Component for rendering the content inside a tab.
 * @param {Object} props - The properties for the TabPanel.
 * @param {React.ReactNode} props.children - The content to display inside the tab.
 * @param {number} props.value - The current active tab index.
 * @param {number} props.index - The index of this tab.
 * @returns {JSX.Element} The TabPanel component.
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

/**
 * Main component for viewing a lead's information including activity, documents, and details.
 * @param {Object} props - The properties for the ViewLead component.
 * @param {string} props.ticketId - The ID of the lead to be viewed.
 * @returns {JSX.Element} The ViewLead component.
 */
export default function AgentAssistViewTicket({ ticketId }) {
  const { suppliers } = useCompany(); // Get supplier information from company context

  const [ticket, setTicket] = useState({}); // Lead data
  const [supDocs, setSupDocs] = useState([]); // Supporting documents
  const [loading, setLoading] = useState(false); // Loading state
  const [uploadId, setUploadId] = useState(nanoId()); // ID for file uploads

  const [uploadFilesModal, setUploadFilesModal] = useState(false); // Modal visibility state for file uploads

  const theme = useTheme(); // Get current theme
  const [value, setValue] = useState(0); // Tab state

  /**
   * Handle tab change when clicked.
   * @param {number} newValue - The new value of the tab index.
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * Handle tab change when swiped.
   * @param {number} index - The new index of the tab.
   */
  const handleChangeIndex = index => {
    setValue(index);
  };

  /**
   * Toggle the file upload modal. If the lead doesn't have a supporting document ref, fetch the lead data again.
   */
  const toggleUploadFilesModal = async () => {
    if (!ticket.supportingDocumentRef && uploadFilesModal) {
      await fetchTicket();
    }
    setUploadFilesModal(old => !old);
  };

  const uploadBucket = "agent_assist"; // The bucket where files are stored

  // Get the supplier for the lead from the list of suppliers
  const supplier = suppliers?.filter(sup => sup.id === ticket.supplier)[0];

  /**
   * Fetch the lead data from the Supabase database.
   */
  const fetchTicket = async () => {
    setLoading(true);

    const { data: agentAssistData, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("agent_assist")
      .select("*")
      .eq("id", ticketId);

    if (error) {
      console.error(error.message);
    }

    setTicket(await agentAssistData[0]);

    setLoading(false);
  };

  /**
   * Fetch the files that have been uploaded for the lead.
   */
  const fetchUploadedFiles = async () => {
    if (ticket.supporting_doc_ref) {
      const { data: files, error } = await supabase.storage
        .from(uploadBucket)
        .list(ticket.supporting_doc_ref);

      if (error) {
        throw error;
      }

      if (!files || !files.length) {
        setSupDocs([]);
      } else {
        setSupDocs(files);
      }
    }
  };

  /**
   * Handle file upload submission. If the lead doesn't have a document reference, update the lead with the upload ID.
   */
  const withUploadSubmit = async () => {
    if (!ticket.supporting_doc_ref) {
      const { error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("agent_assist")
        .update({ supporting_doc_ref: uploadId })
        .eq("id", ticketId)
        .select();

      if (error) {
        console.error(error.message);
      }
    }
    fetchUploadedFiles();
  };

  // Display the uploaded files for the lead
  const viewFiles = supDocs?.map(file => {
    return (
      <Grid item xs={12} key={file.id}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography>
              <code>{file.name}</code>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  // Fetch lead data on component mount
  useEffect(() => {
    fetchTicket();
  }, []);

  // Fetch uploaded files when the lead's supporting document reference changes
  useEffect(() => {
    if (ticket.supporting_doc_ref) {
      setUploadId(ticket.supporting_doc_ref);
      fetchUploadedFiles();
    }
  }, [ticket]);

  return (
    <Grid container spacing={4}>
      {loading ? (
        <Grid item>
          <Typography>Loading...</Typography>
        </Grid>
      ) : (
        <>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Activity" />
              <Tab label="Documents" />
              <Tab label="Details" />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            style={{ minWidth: "66%", margin: "auto" }}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <AgentAssistActivity ticket={ticket} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <AssistDocuments
                lead={ticket}
                viewFiles={viewFiles}
                toggleUploadFilesModal={toggleUploadFilesModal}
              />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <AgentAssistDetails lead={ticket} supplier={supplier} />
            </TabPanel>
            <ModalCard open={uploadFilesModal} onClose={toggleUploadFilesModal}>
              <SBFileUploader
                bucketName={uploadBucket}
                folderName={uploadId}
                onClose={toggleUploadFilesModal}
                withSubmit={withUploadSubmit}
              />
            </ModalCard>
          </SwipeableViews>
        </>
      )}
    </Grid>
  );
}

import React from "react";
import { Button, Dialog, Grid } from "@mui/material";
import nanoId from "nano-id";

import SBFileUploader from "../shared/SBFileUploader";

// The AddAlertImages function is defined to handle the addition of images to an alert.
export default function AddAlertImages({ open, close, alert, setAlert }) {
  // Handles the submission of the uploaded file and updates the alert with the new image.
  const handleSubmit = (data, fileurl) => {
    const file = { data, url: fileurl };

    // console.log(file, "file");
    setAlert({
      ...alert,
      images: [
        ...alert.images,
        {
          name: file.data.name,
          url: fileurl,
          ...file.data,
        },
      ],
    });
    // handleClose();
  };

  // Closes the image dialog.
  const handleClose = () => {
    // setFile(null);
    // setFileName(null);
    close();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <Grid container padding={2} spacing={1}>
          <Grid item xs={12}>
            <SBFileUploader
              bucketName="alert_images"
              folderName={nanoId()}
              info={handleSubmit}
            />{" "}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={handleClose}
            >
              {alert.images.length > 0 ? "Done" : "Cancel"}
            </Button>{" "}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

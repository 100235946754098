import { supabase } from "../../../../../supabase";

const CHUNK_SIZE = 500; // Adjust based on performance needs

/**
 * Retrieves orders for a given structure, supplier, and date range from the "fibre_order_master" table,
 * chunking the structure array to avoid timeouts and sorting the result by the order creation date.
 *
 * @param {Array<string>} structure - Array of Zawadi agent codes representing the structure to query.
 * @param {string} supplier - The supplier identifier to filter orders by.
 * @param {string} startDate - The start date for filtering orders (in YYYY-MM-DD format).
 * @param {string} endDate - The end date for filtering orders (in YYYY-MM-DD format).
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of order objects, sorted by `order_created_date_1` in ascending order.
 *
 * @throws {Error} If there is an issue with the Supabase query, an error will be logged and the function will return undefined.
 */
async function getStructureOrders(structure, supplier, startDate, endDate) {
  const downstreamCodeMap = structure?.map(agent => agent.dealer_code);

  // reduce structure to be an object of pretty names - makes it easy to map into report
  const downstreamPrettyNames = structure.reduce((acc, user) => {
    acc[
      user.dealer_code
    ] = `${user.dealer_code}: ${user.contact_name} ${user.contact_surname}`;
    return acc;
  }, {});

  /**
   * Splits an array into chunks of a specified size.
   * @param {Array} arr - The array to split.
   * @param {number} size - The size of each chunk.
   * @returns {Array<Array>} - An array of chunks.
   */
  const chunkArray = (arr, size) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  };

  const structureChunks = chunkArray(downstreamCodeMap, CHUNK_SIZE);
  let allData = [];

  // Process each chunk of the structure array
  for (const chunk of structureChunks) {
    // eslint-disable-next-line no-await-in-loop
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("fibre_order_master")
      .select(
        // eslint-disable-next-line max-len
        "order_number_1, zawadi_agent_code_2, order_created_date_1, order_paid_date_1, order_installation_date_1, order_activation_date_2, customer_onboarding_status_0, customer_current_status_0, customer_details_name_0, customer_details_account_number_1, customer_details_phone_0, customer_details_email_0, customer_details_address_0"
      )
      .in("zawadi_agent_code_2", chunk)
      .eq("supplier_1", supplier)
      .or(
        // eslint-disable-next-line max-len
        `and(order_created_date_1.gte.${startDate},order_created_date_1.lte.${endDate}), and(order_paid_date_1.gte.${startDate},order_paid_date_1.lte.${endDate}), and(order_installation_date_1.gte.${startDate},order_installation_date_1.lte.${endDate}), and(order_activation_date_2.gte.${startDate},order_activation_date_2.lte.${endDate})`
      )
      .order("order_created_date_1", { ascending: true }); // Keep sorting within each chunk

    if (error) {
      console.error(error);
      return;
    }

    // Append data from the chunk
    allData = allData.concat(data);
  }

  const prettyNameMaped = allData.map(order => ({
    ...order,
    agent_code_name: downstreamPrettyNames[order.zawadi_agent_code_2],
  }));

  // Sort the accumulated data by order_created_date_1 in ascending order
  prettyNameMaped.sort(
    (a, b) =>
      new Date(a.order_created_date_1) - new Date(b.order_created_date_1)
  );

  return prettyNameMaped;
}

export default getStructureOrders;

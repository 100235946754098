import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Event, Wifi } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { textFieldProps } from "../../../shared/constants";

export default function ActiveOrders() {
  const history = useHistory();
  const [activeOrders, setActiveOrders] = useState([]);

  useEffect(async () => {
    // This will have to change to Supabase
    const response = axios({
      url: "https://us-central1-zawadi-dev.cloudfunctions.net/getActiveOrdersData",
      method: "post",
    });
    const data = await (await response).data;
    setActiveOrders(data);
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
      <h3>Active Orders:</h3>
      <Card>
        <CardContent>
          <TextField {...textFieldProps} placeholder="Search" />
          <div>
            {activeOrders.map(order => {
              return (
                <Card
                  id={order.id}
                  style={{
                    backgroundColor: "#267353",
                    color: "#ffffff",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Grid
                    container
                    spacing={0}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={10} sm={10}>
                      <Typography display="block">
                        Name: {order.orderName}
                      </Typography>
                      <Typography display="block">
                        Address: {order.orderAddress}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <IconButton>
                        <Event style={{ color: "#ffffff" }} />
                      </IconButton>
                      <br />
                      <IconButton>
                        <Wifi style={{ color: "#ffffff" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              );
            })}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";
import { Grid, Button, Divider } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

function PeriodSelector(props) {
  const startOfCurrentPeriod = moment().startOf("month").format("YYYY-MM-DD");
  const endOfCurrentPeriod = moment().endOf("month").format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(startOfCurrentPeriod);
  const [endDate, setEndDate] = useState(endOfCurrentPeriod);

  const handleStartDateChange = date => {
    setStartDate(date);
    props.changeStartDate(moment(date).format("YYYY-MM-DD"));
  };

  const handleEndDateChange = date => {
    setEndDate(date);
    props.changeEndDate(moment(date).format("YYYY-MM-DD"));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid item md={3} xs={12}>
          <KeyboardDatePicker
            autoOk
            fullWidth
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            id="start-date"
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              "aria-label": "change start date",
            }}
            inputVariant="outlined"
            minDate={moment().subtract(3, "months").format("YYYY-MM-DD")}
            maxDate={moment().add(3, "months").format("YYYY-MM-DD")}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <KeyboardDatePicker
            autoOk
            fullWidth
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            id="end-date"
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              "aria-label": "change end date",
            }}
            inputVariant="outlined"
            minDate={moment().subtract(3, "months").format("YYYY-MM-DD")}
            maxDate={moment().add(3, "months").format("YYYY-MM-DD")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} md={12} xs={12} style={{ marginBottom: 20 }}>
        <Grid item xs={6} md={2}>
          <Button
            style={{ minWidth: 110 }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              const yesterday = moment()
                .subtract(1, "day")
                .format("YYYY-MM-DD");

              handleStartDateChange(yesterday);
              handleEndDateChange(yesterday);
            }}
          >
            Yesterday
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            style={{ minWidth: 110 }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              const startOfLastWeek = moment()
                .subtract(1, "week")
                .startOf("week")
                .format("YYYY-MM-DD");
              const endOfLastWeek = moment()
                .subtract(1, "week")
                .endOf("week")
                .format("YYYY-MM-DD");

              handleStartDateChange(startOfLastWeek);
              handleEndDateChange(endOfLastWeek);
            }}
          >
            Last Week
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            style={{ minWidth: 110 }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              const startOfLastMonth = moment()
                .subtract(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD");
              const endOfLastMonth = moment()
                .subtract(1, "month")
                .endOf("month")
                .format("YYYY-MM-DD");

              handleStartDateChange(startOfLastMonth);
              handleEndDateChange(endOfLastMonth);
            }}
          >
            Last Month
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            style={{ minWidth: 110 }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              const startOfThisWeek = moment()
                .startOf("week")
                .format("YYYY-MM-DD");
              const endOfThisWeek = moment().endOf("week").format("YYYY-MM-DD");

              handleStartDateChange(startOfThisWeek);
              handleEndDateChange(endOfThisWeek);
            }}
          >
            This Week
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            style={{ minWidth: 110 }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              handleStartDateChange(startOfCurrentPeriod);
              handleEndDateChange(endOfCurrentPeriod);
            }}
          >
            This Month
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <div style={{ marginTop: 20 }} />
    </MuiPickersUtilsProvider>
  );
}

export default PeriodSelector;

import {
  faUsers,
  faClone,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Grid } from "@material-ui/core";
import React from "react";
import { ArrowBack } from "@material-ui/icons";
import { useHistory, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { pageTitleStyles } from "../../mui/styles";

export default function Settings() {
  const listItemStyles = {
    icon: {
      backgroundColor: "#ddd",
      height: 50,
      width: 50,
      borderRadius: 25,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: 20,
    },
  };
  const history = useHistory();

  return (
    <div>
      <Button
        startIcon={<ArrowBack />}
        variant="text"
        color="primary"
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <h1 style={pageTitleStyles}>Settings</h1>
      <Grid container xs={12} md={6} spacing={3}>
        <Grid item xs={12}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Dealers
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <List>
              <Link to="/settings/levels">
                <ListItem style={{ cursor: "pointer" }}>
                  <ListItemIcon style={listItemStyles.icon}>
                    <FontAwesomeIcon icon={faUsers} color="#666666" />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontSize: "1.25rem",
                        fontWeight: "400",
                        color: "#000",
                      },
                    }}
                    secondaryTypographyProps={{
                      style: { fontSize: "1rem", color: "#666666" },
                    }}
                    primary="Levels"
                    secondary=""
                  />
                </ListItem>
              </Link>
            </List>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Suppliers & Services
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <List>
              <Link to="/settings/product-types">
                <ListItem style={{ cursor: "pointer" }}>
                  <ListItemIcon style={listItemStyles.icon}>
                    <FontAwesomeIcon icon={faUsers} color="#666666" />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontSize: "1.25rem",
                        fontWeight: "400",
                        color: "#000",
                      },
                    }}
                    secondaryTypographyProps={{
                      style: { fontSize: "1rem", color: "#666666" },
                    }}
                    primary="Product Types"
                    secondary=""
                  />
                </ListItem>
              </Link>
            </List>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <List>
              <Link to="/settings/suppliers">
                <ListItem style={{ cursor: "pointer" }}>
                  <ListItemIcon style={listItemStyles.icon}>
                    <FontAwesomeIcon icon={faClone} color="#666666" />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontSize: "1.25rem",
                        fontWeight: "400",
                        color: "#000",
                      },
                    }}
                    secondaryTypographyProps={{
                      style: { fontSize: "1rem", color: "#666666" },
                    }}
                    primary="Suppliers"
                    secondary=""
                  />
                </ListItem>
              </Link>
            </List>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Dashboard
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <List>
              <Link to="/settings/banner">
                <ListItem style={{ cursor: "pointer" }}>
                  <ListItemIcon style={listItemStyles.icon}>
                    <FontAwesomeIcon icon={faNewspaper} color="#666666" />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontSize: "1.25rem",
                        fontWeight: "400",
                        color: "#000",
                      },
                    }}
                    secondaryTypographyProps={{
                      style: { fontSize: "1rem", color: "#666666" },
                    }}
                    primary="Banner"
                    secondary=""
                  />
                </ListItem>
              </Link>
            </List>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import * as yup from "yup";
import checkPhoneNumberIsUsed from "../shared/functions/checkPhoneNumberIsUsed";

const phoneSchema = yup
  .string()
  .matches(
    /^\+27\d{9}$/,
    "Phone number must start with +27 and be followed by 9 digits"
  )
  .required("Phone number is required");

export default function ChangePhoneNumber({ currentPhone, setNewPhone }) {
  const [phoneNumber, setPhoneNumber] = useState(currentPhone);
  const [error, setError] = useState(null);

  const handleBlur = async () => {
    setError(null);
    try {
      if (phoneNumber !== currentPhone) {
        await phoneSchema.validate(phoneNumber);

        const phoneIsUsed = await checkPhoneNumberIsUsed(phoneNumber);
        if (phoneIsUsed) {
          setError("Phone number is already in use");
        } else if (phoneNumber !== currentPhone) {
          setNewPhone(phoneNumber);
        } else {
          // Don't change the phone.
        }
      }
    } catch (validationError) {
      setError(validationError.message);
    }
  };

  return (
    <div style={{ maxWidth: 400, margin: "20px 20px 20px 0px" }}>
      <TextField
        fullWidth
        id="phoneNumber"
        name="phoneNumber"
        label="Phone Number"
        variant="outlined"
        value={phoneNumber}
        onBlur={handleBlur}
        onChange={e => {
          setPhoneNumber(e.target.value);
        }}
        error={error || null}
        margin="normal"
        helperText={
          error ||
          "You will need to validate your phone number with a One Time Pin"
        }
      />
    </div>
  );
}

/**
 * Checks if a module is relevant to the current user based on selected levels, product types, and suppliers.
 * @param {Object} moduleAttributes - The attributes of the module to check.
 * @param {number} rank - The current user's rank.
 * @param {Array<string>} selectedProductTypes - The product types relevant to the user.
 * @param {Array<string>} selectedSuppliers - The suppliers relevant to the user.
 * @returns {boolean} - Whether the module is relevant to the user.
 */
export default function isModuleRelevant(
  { selected_levels, product_types, suppliers },
  rank,
  selected_product_types,
  selected_suppliers
) {
  // Check if the module's level is relevant to the user's rank.
  const isLevelRelevant =
    !selected_levels ||
    selected_levels.length === 0 ||
    selected_levels.includes(rank);

  // Check if the module's product type is relevant to the user.
  const isProductTypeRelevant =
    !product_types ||
    product_types.length === 0 ||
    selected_product_types.some(type => product_types.includes(type));

  // Check if the module's suppliers are relevant to the user.
  const isSupplierRelevant =
    !suppliers ||
    suppliers.length === 0 ||
    selected_suppliers.some(supplier => suppliers.includes(supplier));

  // The module is relevant if it passes all three conditions (level, product type, and supplier relevance).
  return isLevelRelevant && isProductTypeRelevant && isSupplierRelevant;
}

// This is the main commissions component that is used to export and import data for our commission runs.
import { React, useState } from "react";
import { Button } from "@material-ui/core";
import moment from "moment";
import {
  exportFibreCommsReport,
  exportDealers,
  exportAgentTiers,
  exportAdvances,
  exportStructure,
  runPostImportScript,
  exportDealerContracts,
} from "./functions"; // Importing functions from the functions.js file

import { AgentImporter } from "./imports/Agent"; // Csv Uploader for Agents
import { LeaderImporter } from "./imports/Leader"; // Csv Uploader for Leaders
import { useCompany } from "../../../contexts/CompanyContext";

export default function commissions() {
  const [postImportRunning, setPostImportRunning] = useState(false);
  const [cutoffDate, setCutoffDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const { updateFreeze, toggleFreeze } = useCompany();

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100vw", // Set max width to screen
      }}
    >
      <h1>Commissions Page</h1>
      <p style={{ marginRight: "5px" }}>Cutoff Date: </p>
      <input
        type="date"
        value={cutoffDate}
        onChange={e => setCutoffDate(e.target.value)}
        style={{
          marginBottom: "10px",
          padding: "5px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      />

      <br />
      <div style={{ width: "65%", justifyContent: "center" }}>
        {" "}
        <br />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => exportFibreCommsReport(cutoffDate)}
          style={{ marginBottom: "10px", padding: "10px", width: "100%" }}
        >
          Export Fibre Agent Commissions Report
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{ marginBottom: "10px", padding: "10px", width: "100%" }}
          onClick={() => exportDealers()}
        >
          Export dealers
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{ marginBottom: "10px", padding: "10px", width: "100%" }}
          onClick={() => exportAgentTiers(cutoffDate)}
        >
          Export Agent Tiers
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{ marginBottom: "10px", padding: "10px", width: "100%" }}
          onClick={() => exportAdvances(cutoffDate)}
        >
          Export Advances
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{ marginBottom: "10px", padding: "10px", width: "100%" }}
          onClick={() => exportStructure()}
        >
          Export Dealers Structure
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{ marginBottom: "10px", padding: "10px", width: "100%" }}
          onClick={() => {
            exportDealerContracts();
          }}
        >
          Download Dealer Contract Information
        </Button>{" "}
        <br />
        <h1>Click to toggle the 'Commission Freeze' state.</h1>
        <Button
          variant="contained"
          color={!updateFreeze ? "primary" : "secondary"}
          size="medium"
          onClick={toggleFreeze}
          style={{ marginBottom: "10px", padding: "10px", width: "100%" }}
        >
          {updateFreeze
            ? "Deactivate Commission Freeze" // Text when freeze is active.
            : "Activate Commission Freeze"}
        </Button>
        <br />
        <h1>Scripts </h1>
        {!postImportRunning && (
          <Button
            variant="contained"
            color="primary"
            size="medium"
            style={{ marginBottom: "10px", padding: "10px", width: "100%" }}
            onClick={async () => {
              setPostImportRunning(true);
              runPostImportScript().then(e => {
                if (e) {
                  setPostImportRunning(false);
                  // eslint-disable-next-line no-alert
                  alert("Post import script completed Successfully.");
                } else {
                  // eslint-disable-next-line no-alert
                  alert(
                    "Error running post import script, a Sentry error has been logged and a Developer will investigate."
                  );
                }
              });
            }}
          >
            Run Post Import Script
          </Button>
        )}
        {postImportRunning && (
          <Button
            variant="contained"
            color="primary"
            disabled
            size="medium"
            style={{ marginBottom: "10px", padding: "10px", width: "100%" }}
          >
            Running Post Import
          </Button>
        )}
        <h1>Imports</h1>
        <AgentImporter />
        <LeaderImporter />
      </div>
    </div>
  );
}

const { provinces } = require("../constants");

const NULL_PROVINCE = {
  label: null,
  value: null,
};

function getProvinceDetails(key) {
  if (!key || typeof key !== "string") {
    return NULL_PROVINCE;
  }

  const keyString = String(key).toLowerCase();

  let province = provinces.find(
    b =>
      b.label.toLowerCase() === keyString || b.value.toLowerCase() === keyString
  );

  if (!province || !province.value || !province.label) {
    province = provinces.find(
      b =>
        b.label.toLowerCase().includes(keyString) ||
        b.value.toLowerCase().includes(keyString)
    );
  }

  if (!province || !province.value || !province.label) {
    return NULL_PROVINCE;
  }

  return province;
}

module.exports = getProvinceDetails;

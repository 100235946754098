import { Collapse, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@mui/material";
import React from "react";

// The SuccessAlert function renders a success alert with a close button.
export default function SuccessAlert({ open, setOpen, text }) {
  return (
    <Collapse in={open}>
      {/* Alert component with a close button */}
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        }
      >
        {text}
      </Alert>
    </Collapse>
  );
}

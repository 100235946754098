import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { useHistory } from "react-router-dom";
import { useDb } from "../../contexts/DatabaseContext";

export default function SadvAgentCsvImport() {
  const [impArray, setImpArray] = useState([]);
  const history = useHistory();
  const tempArray = [];
  const { uploadSadvAgentsCsv } = useDb();

  const handleImport = async () => {
    await uploadSadvAgentsCsv(impArray);
    // eslint-disable-next-line no-alert
    window.alert("Imported Successfully");
  };

  return (
    <div>
      <h1>Import Sadv Agent File</h1>
      <Button
        startIcon={<ArrowBack />}
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <br />
      <br />
      <Importer
        processChunk={async rows => {
          rows.forEach(row => {
            tempArray.push(row);
          });
          // setState with temp array
          setImpArray(tempArray);
        }}
        onComplete={async () => {
          // eslint-disable-next-line no-console
          console.log(impArray);
          handleImport(impArray);
        }}
      >
        <ImporterField name="First_Name" label="First_Name" />
        <ImporterField name="Surname" label="Surname" />
        <ImporterField name="Mobile_Number" label="Mobile_Number" />
        <ImporterField name="Email" label="Email" />
        <ImporterField name="ID_Number" label="ID_Number" />
        <ImporterField
          name="Last_Successful_Login"
          label="Last_Successful_Login"
        />
        <ImporterField name="User_Audit_Date" label="User_Audit_Date" />
      </Importer>
    </div>
  );
}

import {
  Avatar,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  styled,
  Typography,
  withStyles,
} from "@material-ui/core";
import { linearProgressClasses } from "@mui/material";
import React from "react";

const BigAvatar = withStyles({
  root: {
    width: 60,
    height: 60,
  },
})(Avatar);

export default function Top3UserCard({
  name,
  fiber,
  percentage,
  rank,
  borderColor,
}) {
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 15,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#D3D3D3",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#3D7155",
    },
  }));
  return (
    <Card
      variant="outlined"
      style={{
        height: "100%",
        borderRadius: "16px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        border: `4px solid ${borderColor}`, // Adjust the border thickness and color
      }}
    >
      <CardContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container spacing={2}>
          <Grid container justify="center" alignItems="center" xs={2}>
            <BigAvatar src={rank} />
          </Grid>
          <Grid item xs={10}>
            <Grid>
              <Typography variant="h5" align="left" style={{ fontWeight: 500 }}>
                {name}
              </Typography>

              <Grid container direction="row">
                <Typography color="textPrimary" variant="h6">
                  Fibre Sales:&nbsp;
                </Typography>
                <Typography color="primary" variant="h6">
                  {fiber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={10}>
            <BorderLinearProgress variant="determinate" value={percentage} />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </CardContent>
    </Card>
  );
}

import * as Sentry from "@sentry/react";
import { supabase } from "../../../supabase";
import exportToCsv from "../../shared/functions/generateCsv";

/**
 * Exports the Fibre Comms Report, it is essentially the fibre_order master table, but with orders removed where orders match on the order_status table.
 *
 * @param {string} cutoff_date - The cutoff date for the report.
 * @returns {Promise<void>} - A promise that resolves when the report is exported successfully.
 */
async function exportFibreCommsReport(cutoff_date) {
  const { data, error } = await supabase.rpc("fibre_comms_report", {
    cutoff_date,
  });
  if (error) Sentry.captureException(error);
  else {
    exportToCsv(data, "fibre_comms_report");
  }
}

// if dealers reaches 10 000, this function will need to be updated to handle pagination, maybe export only dealers that are on the comms report?
/**
 * Export dealers data to a CSV file.
 *
 * @async
 * @function exportDealers
 * @returns {Promise<void>} A promise that resolves when the export is completed.
 */
async function exportDealers() {
  const { data, error } = await supabase
    .from("dealers")
    .select("*")
    .eq("status", 2) // only export active dealers
    .csv();
  if (error) Sentry.captureException(error);
  else {
    const csvBlob = new Blob([data], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement("a");
    link.href = csvUrl;
    link.download = "dealers";
    link.click();
  }
}

/**
 * Exports agent tiers report to a CSV file.
 * This function has been changed on supabase so that it will be dynamic (based on the cutoff date).
 *
 * @param {Date} cutoff_date - The cutoff date for the report.
 * @returns {Promise<void>} - A promise that resolves when the export is complete.
 */
async function exportAgentTiers(cutoff_date) {
  const { error: generationError } = await supabase.rpc(
    "generate_new_agent_tiers",
    {
      cut_off_date: cutoff_date,
    }
  );

  if (generationError) Sentry.captureException(generationError);
  const { data, error } = await supabase
    .schema("bi")
    .from("fibre_agent_commission_tiers_updated")
    .select("*");

  if (error) Sentry.captureException(error);
  else {
    exportToCsv(data, "agent_tiers_report");
  }
}

/**
 * Export advances data to a CSV file.
 * @async
 * @function exportAdvances
 * @returns {Promise<void>} - A promise that resolves when the data is exported successfully.
 */
async function exportAdvances() {
  const { data, error } = await supabase

    // eslint-disable-next-line quotes
    .schema("finance")
    .from("credit_applications")
    .select("*")
    .eq("credit_type", "Advance")
    .eq("settled", false);
  if (error) Sentry.captureException(error);
  else {
    exportToCsv(data, "advances");
  }
}

/**
 * Exports the commission organization structure to a CSV file.
 *
 * @async
 * @function exportStructure
 * @returns {Promise<void>} A promise that resolves when the export is complete.
 */
async function exportStructure() {
  const { data, error } = await supabase
    .from("commission_org_structure")
    .select("*");
  if (error) Sentry.captureException(error);
  else {
    exportToCsv(data, "structure");
  }
}

/**
 * Runs the Post Import Script by making an asynchronous request. It takes two to three minutes to run.
 * @returns {Promise<boolean>} A promise that resolves to true if the request is successful, otherwise false.
 */
async function runPostImportScript() {
  try {
    const response = await fetch(
      "https://zawadi-staging-a60377845df5.herokuapp.com/PI"
    );
    if (response.status === 200) {
      let completeResult;
      do {
        // eslint-disable-next-line no-await-in-loop
        completeResult = await fetch(
          "https://zawadi-staging-a60377845df5.herokuapp.com/postImportComplete"
        ); // This checks if the post import script is complete.
        if (completeResult.status === 200) {
          return true;
        }
        // eslint-disable-next-line no-await-in-loop
        await new Promise(resolve => {
          setTimeout(resolve, 5000); // wait for 5 seconds before retrying
        });
      } while (completeResult.status !== 200);
    }
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
}

/**
 * Exports dealer contracts from the "new_dealer_contracts" table in the "finance" schema to a CSV file.
 *
 * This function fetches all records from the "new_dealer_contracts" table using Supabase, and if successful,
 * it exports the data to a CSV file named "dealer_contracts". If an error occurs during the fetch, it logs the error to the console.
 *
 * @async
 * @function exportDealerContracts
 * @returns {Promise<void>} A promise that resolves when the export is complete.
 */
async function exportDealerContracts() {
  const { data, error } = await supabase
    .schema("finance")
    .from("new_dealer_contracts").select(`
    *,
      z_code_connector (dealer_code)`);
  if (error) console.error(error);
  else {
    const arrData = [];
    data.forEach(item => {
      arrData.push({
        a_sadv_multiple_teams: item.a_sadv_multiple_teams,
        a_sadv_resellers: item.a_sadv_resellers,
        a_sadv_standard_leader_comm: item.a_sadv_standard_leader_comm,
        a_sadv_super_dealer: item.a_sadv_super_dealer,
        agency_agreement: item.agency_agreement,
        b_herotel_multiple_teams: item.b_herotel_multiple_teams,
        b_herotel_resellers: item.b_herotel_resellers,
        b_herotel_standard_leader_comm: item.b_herotel_standard_leader_comm,
        b_herotel_super_dealer: item.b_herotel_super_dealer,
        z_code: item.z_code_connector.dealer_code,
      });
    });
    exportToCsv(arrData, "dealer_contracts");
  }
}

export {
  exportFibreCommsReport,
  exportDealers,
  exportAgentTiers,
  exportAdvances,
  exportStructure,
  runPostImportScript,
  exportDealerContracts,
};

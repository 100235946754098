import React, { useEffect, useState } from "react";
import { Card, CardContent, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { People, Search } from "@material-ui/icons";
import SimDataGrid from "../../shared/data-grid/SimDataGrid";

export default function DealerDownstreamTable({ dealers }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      dealers &&
        dealers.map(d => ({
          id: d.id,
          name: `${d.contact_name} ${d.contact_surname}`,
          phone: `0${d.phone.slice(3)}`,
          level: (d.selectedLevel && d.selectedLevel.levelName) || d.rank,
        }))
    );
  }, [dealers]);

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "phone", headerName: "Phone", width: 120 },
    { field: "level", headerName: "Level", width: 160 },
    {
      field: "action",
      headerName: "Detail / Downstream",
      width: 200,
      renderCell: params => (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <Link to={`/dealer-detail/${params.row.id}`}>
            <IconButton>
              <Search />
            </IconButton>
          </Link>
          <Link to={`/child-dealer-downstream/${params.row.id}`}>
            <IconButton>
              <People />
            </IconButton>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        <SimDataGrid data={rows} columns={columns} />
      </CardContent>
    </Card>
  );
}
